import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CustomPage.css';
import Home from "./Components/Home"
import Login from "./Components/Login";
//import ErrorPage from "./Components/ErrorPage";
import { useState, useEffect } from "react";
import { authenticate } from './services/user-services';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
//import { useNavigate } from 'react-router-dom';

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [validUser, setValidUser] = useState();
  const [idToken, setIdToken] = useState(null);


  //const navigate = useNavigate();
  //For Local
  //useEffect(() => {
   // getUserData();
  //}
  //);



  useEffect(() => {
    //debugger;
    if (isAuthenticated) {
      RequestProfileData();
    }
    if (idToken && idToken != "") {
      getUserData();
    }
  }, [isAuthenticated, idToken]);

  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      setIdToken(response.idToken);
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        setIdToken(response.idToken);
      });
    });
  }
  //Code for Live

   async function getUserData() {

     debugger;
     let email = accounts[0].username;

    let result = await authenticate(email,idToken).catch(err =>
       console.log("Error in fetching details",err));

    if (result.UserData != "") {
      console.log("UserData" , result.UserData);
      localStorage.setItem('UserName', result.UserData.userName);
      localStorage.setItem('UserId', result.UserData.emailId);
     localStorage.setItem('UserId1', result.UserData.emailId);     
      localStorage.setItem('Role', result.UserData.accessRole);
      localStorage.setItem('Token', result.Token);
       localStorage.setItem('Team', result.UserData.team);
      setValidUser(true);
      //navigate("/approvalrequest");
     }else{
       setValidUser(false);
     }
   }

  // End Code for Live
  //  Code for Local
  // async function getUserData() {

  //  localStorage.setItem('UserName', "Vikas Katkar");
   // localStorage.setItem('UserId', "vikastr@godrej.com");
 // }

  // async function getUserData() {
  //   localStorage.setItem('UserName', "Aneev Ansari");
  //   localStorage.setItem('UserId', "aneev@godrej.com");
  // }

  // async function getUserData() {   
  //   localStorage.setItem('UserName',"Govind Rathore");
  //   localStorage.setItem('UserId', "gsr@godrej.com");      
  // }

  // async function getUserData() {
  //   localStorage.setItem('UserName', "Gauresh Zarbade");
  //   localStorage.setItem('UserId', "gauresh@godrej.com");
  // }

  //  End Code for Local
//   return (
//     <Home />
//   );


   return (<>
     {/* <Home /> */}
       <AuthenticatedTemplate>
         {validUser == true && <Home />}
         {/* {validUser == false && <ErrorPage validUser={validUser} />} */}
       </AuthenticatedTemplate>
       <UnauthenticatedTemplate>
         <Login />
       </UnauthenticatedTemplate>
     </>
     );

}
export default App;
