/* 
Program Name : ProjectEnrollmect.jsx
Written By: Snehal Kurhade
Written on: 19 APR 2022 
System Name: Change Management App
Purpose:  Screen to Add details of Project
Type of Program: React Frontend
*/
//import React,{ useState, useEffect, useRef } from "react";
import React,{ useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import { Link } from 'react-router-dom'
// import { Pencil} from 'react-bootstrap-icons';

import {
  EnrollNewProject,
  getAll
  //getProjectByCode,
} from "../services/project-service";
//import { setError, setSuccess } from "../services/myFunctions";
/*import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";*/

export default function ProjEnrollment ()  {

  let [myproject, setMyProject] = useState(null);
    useEffect(() => {
      fetchMyProject();
    }, []);
    let fetchMyProject = async () => {
        let result = await getAll();
        console.log("result");
        console.log(result);
        setMyProject(result);
      };
      console.log("Projects11");
      console.log(myproject);

  const navigate = useNavigate();
  const [Team, setTeam] = useState("");
  const [ProjectId, setProjectId] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [ProjectOwner, setProjectOwner] = useState("");
  const [ProjectOwnerEmail, setProjectOwnerEmail] = useState("");
  const [ProjectDMCName, setProjectDMCName] = useState("");
  const [ProjectDMCEmail, setProjectDMCEmail] = useState("");
  const [Stakeholder, setStakeholder] = useState("");
  const [EmailIDs, setEmailIDs] = useState("");
  const [WorkType, setWorkType] = useState("");
  const [IsActive, setIsActive] = useState("");
  
  

  const [isPending, setIsPending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    const Project = {
      Team,
      ProjectId,
      ProjectName,
      ProjectOwner,
      ProjectOwnerEmail,
      ProjectDMCName,
      ProjectDMCEmail,
      Stakeholder,
      EmailIDs,
      WorkType,
      IsActive
    
    };
    console.log(Project);

    EnrollNewProject(Project)
      .then((resp) => {
        //console.log(resp.data);
        if (resp.data.status) {
          console.log(resp.data.Message);
          setIsPending(false);
          alert(resp.data.Message);
          navigate("/projectmaster");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*const checkProjectCode = (PNDAMember) => {
   getProjectByCode(Number(PNDAMember.value.trim()))
      .then((resp) => {
        if (resp.data.length > 0) {
          setError(PNDAMember, "Project Already Exists");
        } else {
          setSuccess(PNDAMember);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };*/

  return (
    <div className="create">
      <h2>Add New Data in Project Table</h2>
      <form className="form" onSubmit={handleSubmit}>
        <div className="control">
          <label>Team : </label>
          <input
            type="text"
            required
            value={Team}
            onChange={(e) => setTeam(e.target.value)}
          />
          </div>
         {/* <div className="control">
<label>proj				 : </label>
              <select value={ProjectName} onChange={(e) => setProjectName(e.target.value)}> 
              {myproject.map((item, index) => {
                                        return <option key={index} value={item.ProjectName}>{item.ProjectName}</option>
                                    })}
                  
              </select>
            </div>
         */}
        <div className="control">
          <label>Project Id : </label>
          <input
            type="text"
            required
            value={ProjectId}
            onChange={(e) => setProjectId(e.target.value)}
          />
        </div>

        <div className="control">
          <label>Project Name : </label>
          {/* <input
            type="text"
            required
            value={ProjectName}
            onChange={(e) => setProjectName(e.target.value)}
          /> */}
           <select value={ProjectName} onChange={(e) => setProjectName(e.target.value)}> 
              {myproject.map((item, index) => {
                                        return <option key={index} value={item.ProjectName}>{item.ProjectName}</option>
                                    })}
                  
              </select>
        </div>


        <div className="control">
          <label>Project Owner : </label>
          <input
            type="text"
            required
            value={ProjectOwner}
            onChange={(e) => setProjectOwner(e.target.value)}
          />
        </div>

        <div className="control">
          <label>Project Owner Email : </label>
          <input
            type="text"
            required
            value={ProjectOwnerEmail}
            onChange={(e) => setProjectOwnerEmail(e.target.value)}
          />
        </div>

        <div className="control">
          <label>Project DMC Name : </label>
          <input
            type="text"
            required
            value={ProjectDMCName}
            onChange={(e) => setProjectDMCName(e.target.value)}
          />
        </div>

        <div className="control">
          <label>Project DMC Email : </label>
          <input
            type="text"
            required
            value={ProjectDMCEmail}
            onChange={(e) => setProjectDMCEmail(e.target.value)}
          />
        </div>

        <div className="control">
          <label>Stakeholder : </label>
          <input
            type="text"
            required
            value={Stakeholder}
            onChange={(e) => setStakeholder(e.target.value)}
          />
        </div>

        <div className="control">
          <label>EmailIDs : </label>
          <input
            type="text"
            required
            value={EmailIDs}
            onChange={(e) => setEmailIDs(e.target.value)}
          />
        </div>

        <div className="control">
          <label>WorkType : </label>
          <input
            type="text"
            required
            value={WorkType}
            onChange={(e) => setWorkType(e.target.value)}
          />
        </div>

        <div className="control">
          <label>Is Active : </label>
          <input
            type="text"
            required
            value={IsActive}
            onChange={(e) => setIsActive(e.target.value)}
          />
        </div>        
        <Button
          variant="outline-info"
          disabled={isPending}
         // onClick={!isPending ? handleSubmit : null}
         type="submit">
          {isPending ? "Loading…" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

//export default ProjEnrollment;
