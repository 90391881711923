/* 
Program Name : PdaInitiation.jsx
Written By: snehal Kurhade
Written on: 19 APR 2022 
System Name: Change Management App
Purpose:  Screen to add chaneg Note for PDA members
Type of Program: React Frontend
*/

import React, { useState, useEffect, useRef, Component } from "react";
import { Table, Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { BrowserRouter as Router, Link, Route, Routes, useLinkClickHandler } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import ComboBox from 'react-responsive-combo-box'
import 'react-responsive-combo-box/dist/index.css'
import $ from 'jquery';
import "../App_Arun.css";
import BootstrapTable from "react-bootstrap-table-next";

import {
    EnrollNewChangeInitiation,
    getSelectedProjectRevisionNo,
    getSelectedProjectTimeandCost,
    getdetails,
    getOtherDivisions,
    getOtherDivisionEmail,
} from "../services/ChangeInitiation-service";

import {
    getProject,
    getProjectDetails,

} from "../services/project-service";

import {
    getTeams,
} from "../services/Teams-service";

import {
    getWorktype,
} from "../services/WorkType-service";

import {
    getApprovalHeads,
} from "../services/ApprovalHeads-service";

import {
    getEmpdetails,
} from "../services/DivisionEmail-service";

export default function PdaInitiation() {

    useEffect(() => {
        fetchTeam(); fetchworktype(); fetchProjectdetails();  fetchProjectData();
         FetchUsetDetails(); FetchApprovalHeads();
    }, []);

    const navigate = useNavigate();
    const [WorkType, setWorkType] = useState("");
    const [WorkTypeOptions, setWorkTypeoptions] = useState([]);
    const [TeamName, setTeamName] = useState("");
    const [TeamNameOptions, setTeamOptions] = useState([]);
    const [ProjectName, setProjectName] = useState("");
    const [ProjectNameOptions1, setProjectNameOptions1] = useState([]);
    const [ProjectNumber, setProjectNumber] = useState("");
    const [ProjectDetails, setProjectdetails] = useState([]);
    const [ProjectOwner, setProjectOwner] = useState("");
    const [DMCMemberOwner, setDMCMemberOwner] = useState("");
    const [MaxRevisionNo, setMaxRevisionNo] = useState(0);
    const [ChangeInitiationOwner, setChangeInitiationOwner] = useState("");
    const [ChangeInitiationOwnerEmail, setChangeInitiationOwnerEmail] = useState("");
    const [DMCMember, setDMCMember] = useState("");
    const [ChangeRequestNumber, setChangeRequestNumber] = useState("");
    const [RevisionNo, setRevisionNo] = useState(0);
    const [ChangeRequestDate, setChangeRequestDate] = useState("");
    const [ProjDMCAcceptanceDate, setProjDMCAcceptanceDate] = useState("");
    const [ApprovedByPdaOn, setApprovedByPdaOn] = useState("");
    const [GCHeadApprovalDate, setGCHeadApprovalDate] = useState("");    
    const [PDAStopWorkApprover, setPDAStopWorkApprover] = useState("");
    const [StopWorkApprovedByPdaOn, setStopWorkApprovedByPdaOn] = useState("");
    const [GCStopWorkApprover, setGCStopWorkApprover] = useState("");
    const [GCStopWorkApproveDate, setGCStopWorkApproveDate] = useState("");
    const [ApprovedByDmc, setApprovedByDmc] = useState("");
    const [ApprovedByPDATeam, setApprovedByPDATeam] = useState("");
    const [ApprovedByFinance, setApprovedByFinance] = useState("");
    const [ChangeAcceptedbyDMC, setChangeAcceptedbyDMC] = useState("");
    const [ChangeValidatedByPDA, setChangeValidatedByPDA] = useState("");
    const [ChangeApprovedbyGC, setChangeApprovedbyGC] = useState("");
    const [ApprovedByDmcDate, setApprovedByDmcDate] = useState("");
    const [PDAApprover, setPDAApprover] = useState("");
    const [GCApprover, setGCApprover] = useState("");
    const [PDAInitiation, setPDAInitiation] = useState("Yes");
    const [PDAInitiator, setPDAInitiator] = useState("");
    const [InitiatedByPDAOn, setInitiatedByPDAOn] = useState("");
    const [ChangeInitiationBy, setChangeInitiationBy] = useState("");
    const [ChangeInitiationOn, setChangeInitiationOn] = useState("");
    const [DMCMemberEmail, setDMCMemberEmail] = useState("");
    const [OriginalCommitment_Time, setOriginalCommitment_Time] = useState("");
    const [OriginalCommitmentCost, setOriginalCommitmentCost] = useState("");
    const [LastApproved_Time, setLastApproved_Time] = useState("");
    const [LastApprovedCost, setLastApprovedCost] = useState("");
    const [NewProposed_time, setNewProposed_time] = useState(""); 
    const [NewProposed_Cost, setNewProposed_Cost] = useState("");
    const [Impact_EndDate, setImpact_EndDate] = useState("No");
    const [Impact_Budget, setImpact_Budget] = useState("No");
    const [RevisedBudget, setRevisedBudget] = useState("");
    const [RevisedEndDate, setRevisedEndDate] = useState("");   
    const [ChangeFesibility_releventStakeholders, setChangeFesibility_releventStakeholders] = useState("");
    let username =localStorage.getItem('UserName');
    let useremail = localStorage.getItem('UserId');
    let PendingWithInitiator = "";
    let PDAInitiated = "YES";  
    let  tmpPendingWithInitiator="YES"; 
    let MaxRevNo = 0;    
    const fetchworktype = async () => {       
        const result = await getWorktype();
        setWorkTypeoptions(result);
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const todaysdate = dd + '/' + mm + '/' + yyyy;
        setChangeRequestDate(todaysdate);
        setPDAInitiation("Yes");
        setPDAInitiator(username);
        setInitiatedByPDAOn(todaysdate);
        setWorkType(document.getElementById("WorkType").value);
        setChangeInitiationOn(todaysdate);
        fetchProjectdetails();
        
        // setStopWorkApprovedByDmcOn(todaysdate);
        // setStopWorkApprovedByPdaOn(todaysdate);
        // setGCStopWorkApproveDate(todaysdate);
        // setApprovedByDmcDate(todaysdate);
        // setApprovedByPDATeamOn(todaysdate);
        // setApprovedByFinanceOn(todaysdate);
        // setProjDMCAcceptanceDate(todaysdate);
        // setApprovedByPdaOn(todaysdate);
        // setGCHeadApprovalDate(todaysdate);
        document.getElementById("sectiontable").style.display = "none";
        setWorkType(document.getElementById("WorkType").value);
    };
    const FetchApprovalHeads = async () => {
        const result = await getApprovalHeads();
        
        const PDAHead = result.filter(obj => {
            return obj.Title === "ChangeTimeLinePDA"
        })
        setPDAStopWorkApprover((PDAHead.map(i => i.ApproverEmailID)).toString());
        setPDAApprover((PDAHead.map(i => i.ApproverName)).toString());
        const BHHead = result.filter(obj => {
            return obj.Title === "GCBusinessHead"
        })
        setGCStopWorkApprover((BHHead.map(i => i.ApproverEmailID)).toString());
        setGCApprover((BHHead.map(i => i.ApproverName)).toString());
    };

    const fetchProjectdetails = async () => {
        const WorkType = document.getElementById("WorkType").value;
        const TeamName = document.getElementById("TeamName").value;
        const result = await getProject(TeamName, WorkType);
        setProjectNameOptions1(result);
        setProjectdetails(result);
        setProjectNumber(document.getElementById("ProjectNumber").value);
        setProjectName(document.getElementById("ProjectName").value);
        setProjectOwner(document.getElementById("ProjectOwner").value);
        setDMCMemberOwner(document.getElementById("DMCMemberOwner").value);
        fetchSelectedProjectData();
    };
    const fetchProjectData = async () => {
        const WorkType = document.getElementById("WorkType").value;
        const TeamName = document.getElementById("TeamName").value;
        const ProjName = document.getElementById("ProjectName").value;
        const result = await getProjectDetails(TeamName, WorkType, ProjName);
        setProjectdetails(result);
        setProjectNumber(document.getElementById("ProjectNumber").value);
        setProjectName(document.getElementById("ProjectName").value);
        setProjectOwner(document.getElementById("ProjectOwner").value);
        setDMCMemberOwner(document.getElementById("DMCMemberOwner").value);
        fetchSelectedProjectData();
    };
    const fetchSelectedProjectData = async () => {
        const projnum = document.getElementById("ProjectNumber").value;
        const revisionNoresult = await getSelectedProjectRevisionNo(projnum);

        let data = revisionNoresult.map(t => t.RevisionNo);

        MaxRevNo = Math.max.apply(null, data);
       
        if (isFinite(MaxRevNo) && MaxRevNo != 0) {

            setChangeRequestNumber(projnum + "-" + (Math.floor(MaxRevNo) + 1));
            setRevisionNo (Math.floor(MaxRevNo) + 1);
            setMaxRevisionNo(MaxRevNo);

            const TimeandCostResult = await getSelectedProjectTimeandCost(projnum, MaxRevNo);
            
        }
        else {
            setChangeRequestNumber((projnum + "-" + 1).toString());
            setMaxRevisionNo(0);
            setRevisionNo(1);
           
        }
    }

    const fetchTeam = async () => {
        const result = await getTeams();
        setTeamOptions(result);
        setTeamName(document.getElementById("TeamName").value);
    };

    const FetchUsetDetails = async () => {
        const result = await getEmpdetails(useremail);
        
        setChangeInitiationOwner((result.map(t => t.NameOfEmployee)).toString());
        setChangeInitiationOwnerEmail(useremail.toString());
        setChangeInitiationBy((result.map(t => t.NameOfEmployee)).toString());
        setDMCMember((result.map(t => t.L_Plus_1_Name)).toString());
        setDMCMemberEmail((result.map(t => t.L_Plus_1)).toString())

    };
    const getProjectOptions = () => {
        fetchProjectdetails();
    };
    const getProjectData = () => {
        fetchProjectData();
    };
    
    const btnCancelClick = async (e) => {
        var NavPath= sessionStorage.getItem("NavPath");       
        navigate(NavPath);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        PendingWithInitiator = "Yes";
        if(document.getElementById("ProjectName").value.toString()==""){
            alert("please select Project Name");
            return;
        }
//         if(ChangeRequestNumber.split("-").pop()>4) 
//         { 
//             alert( "You can not add more than 4 Revisions");
//             return;
//  }   
        setProjectName(document.getElementById("ProjectName").value);
        setProjectNumber(document.getElementById("ProjectNumber").value);
        setDMCMemberOwner(document.getElementById("DMCMemberOwner").value);
        setTeamName(document.getElementById("TeamName").value);
            
        setChangeInitiationOwner(document.getElementById("ProjectOwner").value);
        const Created = new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear();
        const CreatedBy = ChangeInitiationOwner.toString();
        const PrevChangeReqNoData = await getdetails(ChangeRequestNumber);
        
        const ChangeInitiation = {
            WorkType, TeamName, ProjectName, ProjectNumber, ProjectOwner, DMCMemberOwner
            , ChangeInitiationOwner, ChangeInitiationOwnerEmail, ChangeRequestDate, ChangeRequestNumber
            , DMCMember,DMCMemberEmail ,PendingWithInitiator
            , ChangeFesibility_releventStakeholders
            , ChangeInitiationBy
            , ChangeInitiationOn         
            , PDAStopWorkApprover
            , PDAApprover
            , PDAInitiated 
            ,tmpPendingWithInitiator 
            , PDAInitiator 
            , InitiatedByPDAOn         
            , GCApprover
            , GCStopWorkApprover            
            , RevisionNo
            , Created
            , CreatedBy  
            ,ApprovedByDmc  
            ,ApprovedByPDATeam
            ,ApprovedByFinance  
            ,ChangeAcceptedbyDMC
            ,ChangeValidatedByPDA 
            ,ChangeApprovedbyGC          
            ,StopWorkApprovedByPdaOn
            ,GCStopWorkApproveDate
,ApprovedByDmcDate
,ProjDMCAcceptanceDate
,ApprovedByPdaOn
,GCHeadApprovalDate  
,OriginalCommitment_Time, OriginalCommitmentCost, LastApproved_Time, LastApprovedCost, NewProposed_time,
NewProposed_Cost, Impact_EndDate, Impact_Budget, RevisedBudget, RevisedEndDate        
        };


        

        if (PrevChangeReqNoData.length === 0) {
            // EnrollNewChangeInitiation(ChangeInitiation)
            //     .then((resp) => {
            //         console.log("resp");
            //         console.log(resp);
            //         if (resp.data.status) {
            //             console.log(resp.data.Message);
            //             alert(resp.data.Message);
            //             var NavPath= sessionStorage.getItem("NavPath");       
            //             navigate(NavPath);
            //         }
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     });
            EnrollNewChangeInitiation(ChangeInitiation)
            .then((resp) => {

                // if (resp.data.status) {
                    var NavPath= sessionStorage.getItem("NavPath");       
                                navigate(NavPath);
                    alert("Change Initiation Request Number " + ChangeRequestNumber + " Inserted Successfully");
              

                // }
            })
            .catch((error) => {
                console.log(error);
            });
        }
        else {
            alert("Change Request for Change Request Number " + ChangeRequestNumber + " is already submitted ");
            var NavPath= sessionStorage.getItem("NavPath");       
        navigate(NavPath);
            
        }
    };

    return (
        <Container className="LogbookForm">
             <div class="float-start">
        <Button class="btn-primary"
        style={{ float: "right" } }
       size="sm"
       onClick={btnCancelClick} 
     >
      Back
     </Button>
     </div>
         
            <br />
            <br />

            <h5> <span class="text-primary">Change Initiation </span></h5>
           

            <Form className="form" onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Work Type</Form.Label>
                            <Form.Select aria-label="WorkType" name="WorkType" id="WorkType" value={WorkType} required onChange={(e) => { setWorkType(e.target.value); getProjectOptions() }}  >
                                {WorkTypeOptions.map((item, index) => {
                                    return <option key={index} value={item.WorkType}>{item.WorkType}</option>
                                })}
                            </Form.Select>
                        </Form.Group></Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Teams</Form.Label>
                            <Form.Select aria-label="Teams" name="TeamName" id="TeamName" value={TeamName} required onChange={(e) => { setTeamName(e.target.value); getProjectOptions() }} >
                                {TeamNameOptions.map((item, index) => {
                                    return <option key={index} value={item.TeamName}>{item.TeamName}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>

                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Initiative / Project Name</Form.Label>
                            <Form.Select aria-label="ProjectName" id="ProjectName" name="ProjectName" value={ProjectName} required onChange={(e) => { setProjectName(e.target.value); getProjectData() }} >
                                {ProjectNameOptions1.map((item, index) => {
                                    return <option key={index} value={item.ProjectName}>{item.ProjectName}</option>
                                })}
                            </Form.Select>
                        </Form.Group></Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Initiative / Project Number</Form.Label>
                            <Form.Select aria-label="ProjectNumber" id="ProjectNumber" disabled name="ProjectNumber" required value={ProjectNumber} onChange={(e) => setProjectNumber(e.target.value)} >
                                {ProjectDetails.map((item) => {
                                    return <option value={item.ProjectId}>{item.ProjectId}</option>
                                })}
                            </Form.Select>
                        </Form.Group></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Initiative / Project owner</Form.Label>
                            <Form.Select aria-label="ProjectOwner" id="ProjectOwner" disabled name="ProjectOwner" required value={ProjectOwner} onBeforeInput={(e) => setProjectOwner(e.target.value)} onChange={(e) => setProjectOwner(e.target.value)} >
                                {ProjectDetails.map((item, index) => {
                                    return <option key={index} value={item.ProjectOwnerEmail}>{item.ProjectOwnerEmail}</option>
                                })}
                            </Form.Select>
                        </Form.Group></Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Project DMC Member</Form.Label>
                            <Form.Select aria-label="DMCMemberOwner" id="DMCMemberOwner" name="DMCMemberOwner" required value={DMCMemberOwner} onChange={(e) => setDMCMemberOwner(e.target.value)} >
                                {ProjectDetails.map((item, index) => {
                                    return <option key={index} value={item.ProjectDMCEmail}>{item.ProjectDMCEmail}</option>
                                })}
                            </Form.Select>
                        </Form.Group></Col>
                </Row>               
                <Row>
                <Col>
                        <Form.Group className="mb-3" >
                            <Form.Label>Change Request Number</Form.Label>
                            <Form.Control type="text" value={ChangeRequestNumber} disabled required name="ChangeRequestNumber" />

                        </Form.Group>
                    </Col>
                    {/* <Col>
                        <Form.Group className="mb-3" >
                            <Form.Label>Request Date</Form.Label>
                            <Form.Control type="text" id="ChangeRequestDate" disabled value={ChangeRequestDate} name="ChangeRequestDate" />

                        </Form.Group>
                    </Col>
                    */}
                </Row> 
                <Container style={{ display: "flex", justifyContent: "center" }}>
                    <Stack direction="horizontal" gap={4}>
                        <Button variant="success" size="lg" type="submit">Submit</Button>
                        <Button variant="danger" onClick={btnCancelClick}  size="lg" type="reset">Cancel</Button>
                    </Stack>
                </Container>
            </Form>
        </Container>

    )

};



