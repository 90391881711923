import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "PDASpoc"; // process.env.REACT_APP_PDASPOC_API_URL
const API_URL1 = process.env.REACT_APP_CHANGEINITIATION_API_URL + "changeanalysis"; //process.env.REACT_APP_ANALYSIS_API_URL
//const axios = require("axios");

export async function getPdaSpocName() {
  console.log("getPdaSpocName");  
  return fetch(API_URL).then((response) => response.json());
}

export async function getActivePdaSpocName(ActiveInd) {
  console.log("getActivePdaSpocName -service");
  let url = `${API_URL}/ActiveInd/${ActiveInd}`;
  console.log(url);
  return fetch(url).then((response) => response.json());
}

export async function getEmailId(pdaspocname) {
  let url = `${API_URL}/pdaspocname/${pdaspocname}`;
  console.log(url);
  return fetch(url).then((response) => response.json());
}

