/* 
Program Name : FinanceDetails.jsx
Written By: Snehal Kurhade
Written on: 18 MAR 2022 
System Name: Change Management App
Purpose:  Screen to Dispaly details of Finance Members
Type of Program: React Frontend
*/
import {
    deleteFinance,
    getFinanceTeam,
    
  } from "../services/finance-service";
  import React, { useState, useEffect } from "react";
  import BootstrapTable from "react-bootstrap-table-next";
  import { Button } from "react-bootstrap";
  
  function FinanceDetails() {
    const [Finance, setFinance] = useState(null);
    useEffect(() => {
      fetchFinance();
    }, []);
  
    const fetchFinance = async () => {
      const result = await getFinanceTeam();
      //console.log(result);
      setFinance(result);
    };
  
    const handleDelete = (row) => {
      
      deleteFinance(row.FTeam, row.FEmail)
        .then((resp) => {
          if (resp.data.status) {
            console.log(resp.data.Message);
            fetchFinance();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    const deleteButton = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          size="sm"
          variant="outline-danger"
          onClick={() => {
            handleDelete(row);
          }}
        >
          Delete
        </Button>
      );
    };
  
    const columns = [
      {
        dataField: "FTeam",
        text: "FTeam",
        sort: true,
      },
      
      {
        dataField: "FEmail",
        text: "FEmail",
        sort: true,
      },
      {
        dataField: "FName",
        text: "FName",
        sort: true,
      },
     
      
      
      {
        dataField: "",
        text: "",
        formatter: deleteButton,
      },
    ];
  
    const defaultSorted = [
      {
        dataField: "",
        order: "asc",
      },
    ];
  
    return (
      Finance && (
        <div >
          <BootstrapTable
            keyField="FEmail"
            data={Finance}
            columns={columns}
            // loading={true}
            defaultSorted={defaultSorted}
          />
        </div>
      )
    );
  }
  
  export default FinanceDetails;
  