import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "edmcmaster"; //"http://localhost:5009/edmcmaster"; //process.env.REACT_APP_EDMC_URL;
//const axios = require("axios");

export async function getAll() {
 
    console.log("get function");
  return fetch(API_URL).then((response) => response.json());
}

export async function getEdmcDetails(EDMCemail) {
  let url = `${API_URL}/EDMCemail/${EDMCemail}`;
  console.log("Service")
  //console.log(url)
  return fetch(url).then((response) => response.json());
 
}

