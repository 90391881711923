/* 
Program Name : ChangeAnalysisTable.jsx
Written By: Mayur Solanki
Written on: 12 Mar 2022 
System Name: Change Management App
Purpose:  Screen to display details of change analysis
Type of Program: React Frontend
*/
import {
  deleteEmployee,
  getEmployee,
  getAll,
  getInitation1,
  
  
} from "../services/ca-service";
import { getInitation2 } from "../services/ca-service";

import React, { useState, useEffect, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Table ,Form, InputGroup, Col, Container, Row, Stack, FormControl  } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

function ChangeAnalysisTable() {
  const navigate = useNavigate();
  const [employees, setEmployee] = useState(null);    
  const searchInput = useRef('');
  let filteredResult = [];


  var username =localStorage.getItem('UserName');
  var userEmail = localStorage.getItem('UserId');

  var tmppath= sessionStorage.getItem("FromFeedback");
  
  
  var tmpChangeAnalysisNumber= sessionStorage.getItem("tmpChangeAnalysisNumber");
  
  
  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    const result = await getAll();
    
    setEmployee(result);
    if(result.length==0){
      alert("No Records Found");
    }
    if(tmpChangeAnalysisNumber != "" && tmpChangeAnalysisNumber != null ){
    const result1 = await getInitation2(tmpChangeAnalysisNumber);
    }
  };

  
  const handleView = (row) => {
      
      
      var  ChangeInitiationNumber= row.ChangeInitiationNumber;
      var ChangeAnalysisNumber = row.ChangeAnalysisNumber;
      var ChangeAnalysisDate = row.ChangeAnalysisDate;
        sessionStorage.setItem('key',ChangeInitiationNumber );
        sessionStorage.setItem('keycanum',ChangeAnalysisNumber );
        sessionStorage.setItem('keycadate', ChangeAnalysisDate);
        navigate("/ChangeAnalysisForm");      
      
    };

    const handleSearch = async (searchText) => {        
      const result = await getAll();
      if (searchText && searchText.length > 0) {
     
        searchText = searchText.toUpperCase();
        filteredResult = result.filter((item) => (item.ChangeAnalysisNumber.toUpperCase().indexOf(searchText) >= 0
          || item.ChangeInitiationNumber.toUpperCase().indexOf(searchText) >= 0) )
          
          // setEmployee("");                    
          setEmployee([...filteredResult]);
      } else {
        setEmployee(result);
      }
  
    }

   


    const handleDelete = (row) => {
      
      let ans = window.confirm("Do you want to Delete Data?")
      if(ans)
      {
      deleteEmployee(row.ProjectNumber, row.ChangeAnalysisNumber)
        .then((resp) => {
          if (resp.data.status) {
            console.log(resp.data.Message);
            fetchEmployees();
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    };

  
    // disabled={item.AnalysisProvidedBy.toUpperCase() == username.toUpperCase() ? false : true}
    const deleteButton = (cell, row, rowIndex, formatExtraData) => {
    
      return (
        <Button
          size="sm"
          variant="outline-danger"
          disabled={row.AnalysisProvidedBy.toUpperCase() == username.toUpperCase() ? false : true}
          onClick={() => {
            handleDelete(row);
          }}
        >
          Delete
        </Button>
      );
       
       };


  const viewButton = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          size="sm"
          variant="outline-success"
          onClick={() => {
            handleView(row);
          }}
        >
          Edit
        </Button>
      );
    };

  const columns = [
      
      {
        dataField: "ChangeAnalysisNumber",
        text: "Change Analysis Number",
        sort: true,
      },
      {
          dataField: "ChangeAnalysisDate",
          text: "Change Analysis Date",
          sort: true,
      },
      {
          dataField: "ChangeInitiationNumber",
          text: "Change Initiation Number",
          sort: true,
      },
      {
          dataField: "RejectionCount",
          text: "Rejection Count",
          sort: true,
      },
        
      {
          dataField: "",
          text: "",
          formatter: viewButton,
      },

      {
          dataField: "",
          text: "",
          formatter: deleteButton,
          
      },
      
    ];
    const defaultSorted = [
      
      {
        dataField: "ChangeAnalysisNumber",
        order: "asc",
      },
    ];
   
  
    return (     
  
      employees && (

       <div>
              <div class = "row" >
          <div class="col-6 mb-2">
          <h2>Change Analysis</h2>
          </div>
          <div class="col-6 mb-2">
          <Button class="m-1 ml-5 btn-primary" style={{ float: "right" }} size="sm"         
         onClick={() => { sessionStorage.clear();  navigate("/ChangeAnalysisNewForm") }}> Add New
       </Button>        
       </div>         
       </div>
       <br></br>
          <InputGroup className="mb-3">
      <InputGroup.Text id="search">Search</InputGroup.Text>
      <FormControl ref={searchInput}
        onChange={() => handleSearch(searchInput.current.value)}
        placeholder="Search by Change Analysis Number or Change Initiation Number "
        aria-label="Search"
        aria-describedby="search"
      />
    </InputGroup>
       
        {/* <div class="the-table">
    
       <div id ="tableview" class={employees.length == 0 ? "invisible" : "visible"}>
          <BootstrapTable 
          striped
            keyField="ProjectNumber"
            data={employees}
            columns={columns}              
             defaultSorted={defaultSorted }
          />
          </div>
          </div> */}
           
      <Form className="form"  >
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <Row>
        <table class="table table-small">
                          <thead>
                              <tr>
                                
                                  <th>Change Analysis Number</th>
                                  <th>Change Analysis Date</th>
                                  <th>Change Initiation Number</th>
                                  <th>Rejection Count</th>
                                  <th></th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                          {employees.map((Cidata, index) =>
                              <tr>
                                  <td> {Cidata.ChangeAnalysisNumber} </td>
                                  <td> {Cidata.ChangeAnalysisDate} </td>
                                  <td> {Cidata.ChangeInitiationNumber} </td>
                                  <td> {Cidata.RejectionCount} </td>
                                  <td>   <Button  size="sm" variant="outline-success"
          onClick={() => {  handleView(Cidata);   }}   >  Edit  </Button></td>
                                  <td>  <Button
          size="sm"
          variant="outline-danger"
          disabled={Cidata.AnalysisProvidedBy.toUpperCase() == username.toUpperCase() ? false : true}
          onClick={() => {
            handleDelete(Cidata);
          }}
        >
          Delete
        </Button></td>
                              </tr>                            
                             )}

                          </tbody>
                      </table>

        </Row>
      </Form>
    
          </div>
        
      )
    );
  }
  
  export default ChangeAnalysisTable;
  