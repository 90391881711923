/* 
Program Name : FeasibilityRaisedDetails.jsx
Written By: Snehal Kurhade
Written on: 6 May 2022 
System Name: Change Management App
Purpose:  Screen to Display details of selected Change Feasibility 
Type of Program: React Frontend
*/
import React, { useState, useEffect, useRef, Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button} from "react-bootstrap";
import {
    
  getAll1,
  getFeasibilityRaised,
  deleteFeasibility,
} from "../services/cf-service";
import { useNavigate } from "react-router-dom";

function FeasibilityRaisedDetails() {
  const navigate = useNavigate();
  let username =localStorage.getItem('UserName');
  let useremail = localStorage.getItem('UserId');
    var data1 = sessionStorage.getItem('ChangeReqNo')
var data2 = sessionStorage.getItem('CFRNo')
var data4 = sessionStorage.getItem('projectnamekey')
var data5 = sessionStorage.getItem('dmckey')
var data6 = sessionStorage.getItem('Createdkey')
var data7 = sessionStorage.getItem('divisionkey')
var data8 = sessionStorage.getItem('worktypekey')
var RaisedOn = sessionStorage.getItem('RaisedOn')
var ProjectNo = sessionStorage.getItem('ProjectNo')


    useEffect(() => {
        fetchChangeFeasibility1(); 
      }, []);


      const handleDelete = () => {
        
        let ans = window.confirm("Do you want to Delete Data?") ;
        if(ans){
        deleteFeasibility(data1, data2)
          .then((resp) => {
            if (resp.data.status) {
              console.log(resp.data.Message);
              navigate("/FeasibilityRaised");
            }
          })
          .catch((error) => {
            console.log(error);
          });
        }
      };
    
  const onBackClick = () =>{
        var NavPath= sessionStorage.getItem("NavPath");       
        navigate(NavPath);
      }
      const fetchChangeFeasibility1 = async () => {  
        sessionStorage.setItem('CFRNo', data2); 
        sessionStorage.setItem('ChangeRequestNo', data1); 
         
      }
  return (
      <div>
        <div class="row justify-content-between">

<div class="col-5 mb-1">
          <h2>Details</h2>
       
          </div> 
        
          <div>
          <div class="float-end m-1">
    <Button class=" ml-5 btn-primary"  style={{ float: "right" } }  size="sm"
    onClick={()     => { navigate("/FeasibilityUpdate") }} > Edit </Button>
</div>      

 <div class="float-end m-1">
    <Button class=" ml-5 btn-primary"  style={{ float: "right" } }  size="sm"
    onClick={() => { handleDelete(); }} > Delete </Button>
 </div>
 <div class="float-end m-1">
    <Button class=" ml-5 btn-primary"  style={{ float: "right" } }  size="sm"
    onClick={onBackClick} > Back </Button>
 </div>
     
 </div>
          
          </div>
          
          <hr/>
          
          {/* <form className="form"> */}

<div class="row justify-content-between">



  <div class="col-5 mb-1">

    <div className="control">

      <label id="crnumber">Form No : </label>

      <input type="text" value={data2} disabled></input>

    </div>

  </div>
  <div class="col-5 mb-1">

  <div className="control">

    <label id="crnumber">WorkType : </label>

    <input type="text" value={data8} disabled></input>

  </div>

</div>
  </div>

    
<div class="row justify-content-between">

<div class="col-5 mb-1">

  <div className="control">

    <label id="crnumber">Project Name : </label>

    <input type="text" value={data4} disabled></input>

  </div>

</div>

<div class="col-5 mb-1">

<div className="control">

  <label id="crnumber">Created By : </label>

  <input type="text" value={data6} disabled></input>

</div>

</div>




</div>


<div class="row justify-content-between">

  <div class="col-5 mb-1">

    <div className="control">

      <label id="crnumber">Request Date : </label>

      <input type="text" value = {RaisedOn} disabled></input>

    </div>

  </div>

  <div class="col-5 mb-1">

<div className="control">

  <label id="crnumber">Project Number : </label>

  <input type="text" value = {ProjectNo} disabled></input>

</div>

</div>

 
  </div>

  <div class="row justify-content-between">

<div class="col-5 mb-1">

<div className="control">

  <label id="crnumber">Change Request Number : </label>

  <input type="text" value = {data1} disabled></input>

</div>

</div>


</div>




          
          </div>
      
    
    
    
  );
}

export default FeasibilityRaisedDetails;