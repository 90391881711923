/* 
Program Name : ChangeInitiationDetailsApproval.jsx
Written By: Snehal Kurhade
Written on: 6 MAY 2022 
System Name: Change Management App
Purpose:  Screen to Display details of change initiation pending with user for approval
Type of Program: React Frontend
*/

/* 
Program Name : ChangeInitiationDetailsApproval.jsx
Defect No : Defect - 8183
Amended By: Vikas Katkar
Amended on: 12 June 2023 
System Name: Change Management App
Purpose: Added check for stop work approved by PDA to display the change request to PDA Head for approval.
Type of Program: React Frontend
*/

import {
  deleteChangeInitiation,
  getAll,
  getFeasibilityData,
  getReport,
} from "../services/ChangeInitiation-service";
import {  
  getAllSectionMaster,
} from "../services/SectionMaster-service";
import {
  getPdaSpocName
} from "../services/PDASpoc-service";
import React, { useState, useEffect, useRef, Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Table, Button, Col, Container, Form, Row, InputGroup, FormControl, Stack } from "react-bootstrap";
import { BrowserRouter as Router, Link, Route, Routes, useLinkClickHandler } from 'react-router-dom'
import { useNavigate } from "react-router-dom";


function ChangeInitiationDetailsApproval() {
  var username =localStorage.getItem('UserName');
  var useremail = localStorage.getItem('UserId');
  
  let query = window.location.href
  let indication = "";
  indication = query.split("/").pop();
  const navigate = useNavigate();
  const searchInput = useRef('');
  const [ChangeInitiation, setChangeInitiation] = useState([]);
  const [tempSort, settempSort] = useState(false);
  let filteredResult = [];
  useEffect(() => {
    fetchChangeInitiation();
  }, []);

  const handleSearch = async (searchText) => {
    const result = await getAll();
    if (searchText && searchText.length > 0) {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      const todaysdate = dd + '/' + mm + '/' + yyyy;
      searchText = searchText.toUpperCase();
      const resultPdaSpoc = await getPdaSpocName();
    
      let dataEmail = resultPdaSpoc.map(t => t.TeamMemberEmail);      
let uniquedataEmail = [...new Set(dataEmail)];
let pdamember=false
for (let property in uniquedataEmail) {  
  
if(useremail=== uniquedataEmail[property]){
  pdamember=true;     

}  
}
if(pdamember){
  filteredResult = result.filter((item) => (item.ChangeRequestNumber.toUpperCase().indexOf(searchText) >= 0
  || item.ChangeRequestNumber.toUpperCase() == searchText.toUpperCase()) && (item.ChangeApprovedbyGC != "Yes"));
 
}
else{
  debugger;
      filteredResult = result.filter((item) => (item.ChangeRequestNumber.toUpperCase().indexOf(searchText) >= 0
        || item.ChangeRequestNumber.toUpperCase() == searchText.toUpperCase()) &&
        (((((item.DMCMember == username || item.DMCMemberEmail==useremail) && item.ApprovedByDmc != "Yes")
          || ((item.PDASpocName == username || item.PDASpocEmail == useremail) && item.ApprovedByPDATeam != "Yes" && item.ApprovedByDmc == "Yes")
          || (item.DMCMemberOwner == useremail && item.ChangeAcceptedbyDMC != "Yes" && item.ApprovedByPDATeam == "Yes")
          || (item.PDAApprover == username && item.ChangeValidatedByPDA != "Yes" && item.ChangeAcceptedbyDMC == "Yes")
          || (item.GCApprover == username && item.ChangeApprovedbyGC != "Yes" && item.ChangeValidatedByPDA == "Yes")
          // || (item.PDAStopWorkApprover == useremail && item.StopWorkApprovedByPda != "Yes" && item.StopWorkApprovedByDMC == "Yes")
          //BOC for Defect 8183 by Vikas
          || (item.PDAStopWorkApprover == useremail && item.StopWorkApprovedByPda == "" && item.StopWorkApprovedByDMC == "Yes")
          //EOC for Defect 8183 by Vikas
          || (item.GCStopWorkApprover == useremail && item.StopWorkApprovedByGC != "Yes" && item.StopWorkApprovedByPda == "Yes")
        )) && item.ChangeApprovedbyGC != "Yes"))
    
}
for (let i = 0; i < filteredResult.length; i++) {
  let DmcAging = "";
  let FeasibilityAging = "";
  let PDASPOCAging = "";
  let FinanceAging = "";
  let ProjectDMCAging = "";
  let PDAHeadAging = "";
  let BHAging = "";
  let maxDate = new Date();
  let feasibilitySubmitted = ""
  if (filteredResult[i].PendingWithInitiator != "Yes") {
    if (filteredResult[i].ApprovedByDmc.toString().toUpperCase() == "YES") {
      DmcAging = getAging(filteredResult[i].ChangeInitiationOn.toString(), filteredResult[i].ApprovedByDmcDate.toString())
    }
    else {
      DmcAging = getAging(filteredResult[i].ChangeInitiationOn.toString(), todaysdate);
    }
  }
  if (filteredResult[i].ChangeFesibility_releventStakeholders.toString().toUpperCase() == "YES" && filteredResult[i].ApprovedByDmc.toString().toUpperCase() == "YES" && filteredResult[i].CountOfStakeHolders > 0 ) {
    const result1 = await getFeasibilityData(filteredResult[i].ChangeRequestNumber.toString());
    let FeasibilityDates = [];
    let count = 0;
    if (result1.length > 0) {
      for (let i = 0; i < result1.length; i++) {
        if (result1[i].DMCInputAcceptance == "Yes") {
          count = count + 1;
          const dateObjtmp = result1[i].DMCInputAcceptanceOn.toString().split(" ")[0].split("/");
          const dateObj = new Date(dateObjtmp[2] + "-" + dateObjtmp[1] + "-" + dateObjtmp[0]);
          FeasibilityDates.push(dateObj);
        }
      };
      if (count == result1.length && result1.length==filteredResult[i].CountOfStakeHolders) {
        feasibilitySubmitted = "YES";
        maxDate = new Date(Math.max.apply(null, FeasibilityDates));
      }
      else {

        maxDate = new Date(todaysdate.split("/")[2] + "-" + todaysdate.split("/")[1] + "-" + todaysdate.split("/")[0]);;
      }
    }
    else {

      maxDate = new Date(todaysdate.split("/")[2] + "-" + todaysdate.split("/")[1] + "-" + todaysdate.split("/")[0]);;
    }
      maxDate = maxDate.toLocaleDateString("en-AU");
      FeasibilityAging = getAging(filteredResult[i].ApprovedByDmcDate.toString(), maxDate);
   
  }
  let FeasibilitySubmissionDate = "";
  let PDASpoc_FinanceDate = "";
  let PDASpoc_FinanceSumbitted = "";


  if (filteredResult[i].ChangeFesibility_releventStakeholders.toString().toUpperCase() == "YES" && feasibilitySubmitted == "YES") {
    FeasibilitySubmissionDate = maxDate;
  }
  
  if (filteredResult[i].ChangeFesibility_releventStakeholders.toString().toUpperCase() == "YES" && filteredResult[i].CountOfStakeHolders > 0  ) {
  if(feasibilitySubmitted.toUpperCase() == "YES"){
    let financedate = "";
    let pdaspocdate = "";
    if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES") {
    
      PDASPOCAging = getAging(FeasibilitySubmissionDate, filteredResult[i].ApprovedByPDATeamOn.toString());
      pdaspocdate = filteredResult[i].ApprovedByPDATeamOn.toString();
    }
    else {
      
      PDASPOCAging = getAging(FeasibilitySubmissionDate, todaysdate);
      pdaspocdate = todaysdate;
    }

    if (filteredResult[i].IsFinance.toString().toUpperCase() == "YES") {

      if (filteredResult[i].ApprovedByFinance.toString().toUpperCase() == "YES") {
        FinanceAging = getAging(FeasibilitySubmissionDate, filteredResult[i].ApprovedByFinanceOn.toString());
        financedate = filteredResult[i].ApprovedByFinanceOn.toString();
      }
      else {
        FinanceAging = getAging(FeasibilitySubmissionDate, todaysdate);
        financedate = todaysdate;
      }
    }
    if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES" && filteredResult[i].ApprovedByFinance.toString().toUpperCase() == "YES") {
      PDASpoc_FinanceSumbitted = "YES";
    }
    pdaspocdate = new Date(pdaspocdate.toString().split("/")[2] + "-" + pdaspocdate.toString().split("/")[1] + "-" + pdaspocdate.toString().split("/")[0]);
    financedate = new Date(financedate.toString().split("/")[2] + "-" + financedate.toString().split("/")[1] + "-" + financedate.toString().split("/")[0]);
    PDASpoc_FinanceDate = ((pdaspocdate.getTime() > financedate.getTime()) ? pdaspocdate : financedate);
  }
  }
  else{
    if (filteredResult[i].ApprovedByDmc.toString().toUpperCase() == "YES")  {
    let financedate = "";
    let pdaspocdate = "";
    if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES") {
    
      PDASPOCAging = getAging(filteredResult[i].ApprovedByDmcDate.toString().toUpperCase(), filteredResult[i].ApprovedByPDATeamOn.toString());
      pdaspocdate = filteredResult[i].ApprovedByPDATeamOn.toString();
    }
    else {
     
      PDASPOCAging = getAging(filteredResult[i].ApprovedByDmcDate.toString().toUpperCase(), todaysdate);
      pdaspocdate = todaysdate;
    }
  
    if (filteredResult[i].IsFinance.toString().toUpperCase() == "YES") {
  
      if (filteredResult[i].ApprovedByFinance.toString().toUpperCase() == "YES") {
        FinanceAging = getAging(filteredResult[i].ApprovedByDmcDate.toString().toUpperCase(), filteredResult[i].ApprovedByFinanceOn.toString());
        financedate = filteredResult[i].ApprovedByFinanceOn.toString();
      }
      else {
        FinanceAging = getAging(filteredResult[i].ApprovedByDmcDate.toString().toUpperCase(), todaysdate);
        financedate = todaysdate;
      }
    }
    if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES" && filteredResult[i].ApprovedByFinance.toString().toUpperCase() == "YES") {
      PDASpoc_FinanceSumbitted = "YES";
    }
    pdaspocdate = new Date(pdaspocdate.toString().split("/")[2] + "-" + pdaspocdate.toString().split("/")[1] + "-" + pdaspocdate.toString().split("/")[0]);
    financedate = new Date(financedate.toString().split("/")[2] + "-" + financedate.toString().split("/")[1] + "-" + financedate.toString().split("/")[0]);
    PDASpoc_FinanceDate = ((pdaspocdate.getTime() > financedate.getTime()) ? pdaspocdate : financedate);
  }
}
  
  if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES") {
    if (filteredResult[i].ChangeAcceptedbyDMC.toString().toUpperCase() == "YES") {
      ProjectDMCAging = getAging(filteredResult[i].ApprovedByPDATeamOn.toString(), filteredResult[i].ProjDMCAcceptanceDate.toString());
    }
    else {
      ProjectDMCAging = getAging(filteredResult[i].ApprovedByPDATeamOn.toString(), todaysdate);
    }
  }

  if (filteredResult[i].ChangeAcceptedbyDMC.toString().toUpperCase() == "YES") {
    if (filteredResult[i].ChangeValidatedByPDA.toString().toUpperCase() == "YES") {
      PDAHeadAging = getAging(filteredResult[i].ProjDMCAcceptanceDate.toString(), filteredResult[i].ApprovedByPdaOn.toString());
    }
    else {
      PDAHeadAging = getAging(filteredResult[i].ProjDMCAcceptanceDate.toString(), todaysdate);
    }
  }
  if (filteredResult[i].ChangeValidatedByPDA.toString().toUpperCase() == "YES") {
    if (filteredResult[i].ChangeApprovedbyGC.toString().toUpperCase() == "YES") {
      BHAging = getAging(filteredResult[i].ApprovedByPdaOn.toString(), filteredResult[i].GCHeadApprovalDate.toString());
    }
    else {
      BHAging = getAging(filteredResult[i].ApprovedByPdaOn.toString(), todaysdate);
    }
  }
  filteredResult[i].DmcAging = Math.floor(DmcAging);
  filteredResult[i].FeasibilityAging = Math.floor(FeasibilityAging);
  filteredResult[i].PDASPOCAging = Math.floor(PDASPOCAging);
  filteredResult[i].FinanceAging = Math.floor(FinanceAging);
  filteredResult[i].ProjectDMCAging = Math.floor(ProjectDMCAging);
  filteredResult[i].PDAHeadAging = Math.floor(PDAHeadAging);
  filteredResult[i].BHAging = Math.floor(BHAging);
  filteredResult[i].FeasibilitySubmitted = feasibilitySubmitted;

};
if (tempSort == false) {

  filteredResult.sort((a, b) => new Date(b.ChangeRequestDate.split(" ")[0].split("/")[2] + "-" + b.ChangeRequestDate.split(" ")[0].split("/")[1] + "-" + b.ChangeRequestDate.split(" ")[0].split("/")[0]) - new Date(a.ChangeRequestDate.split(" ")[0].split("/")[2] + "-" + a.ChangeRequestDate.split(" ")[0].split("/")[1] + "-" + a.ChangeRequestDate.split(" ")[0].split("/")[0]));
}
else {
  filteredResult.sort((a, b) => new Date(a.ChangeRequestDate.split(" ")[0].split("/")[2] + "-" + a.ChangeRequestDate.split(" ")[0].split("/")[1] + "-" + a.ChangeRequestDate.split(" ")[0].split("/")[0]) - new Date(b.ChangeRequestDate.split(" ")[0].split("/")[2] + "-" + b.ChangeRequestDate.split(" ")[0].split("/")[1] + "-" + b.ChangeRequestDate.split(" ")[0].split("/")[0]));
}

setChangeInitiation(filteredResult)
    } else {
      fetchChangeInitiation();
    }

  };

  const routeChange = () => {
    settempSort((tempSort) => !tempSort);
    fetchChangeInitiation();
  }

  const getChangeInitiationReport = async () => {
    getReport(useremail)
      .then((resp) => {
        if (resp == "Success") {
          alert("Report is sent via email");
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  }

  const getAging = (d1, d2) => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const todaysdate = dd + '/' + mm + '/' + yyyy;
    if (d1 == "" || d1 == undefined) {
      d1 = todaysdate;
    }
    if (d2 == "" || d2 == undefined) {
      d2 = todaysdate;
    }
    const dateObjtmp1 = d1.split(" ")[0].split("/");
    const dateObjtmp2 = d2.split(" ")[0].split("/");
    const dateObj1 = new Date(dateObjtmp1[2] + "-" + dateObjtmp1[1] + "-" + dateObjtmp1[0]);
    const dateObj2 = new Date(dateObjtmp2[2] + "-" + dateObjtmp2[1] + "-" + dateObjtmp2[0]);

    const diff = dateObj2.getTime() - dateObj1.getTime();
    return diff / (1000 * 60 * 60 * 24);
  };

  const fetchChangeInitiation = async () => {
    document.getElementById("btnRpt").style.display = "none";
    const sectionResult= await getAllSectionMaster()
    let data = sectionResult.map(t => t.SMEmailID);
    let uniquedata = [...new Set(data)];
    for (let property in uniquedata) {  
      if(useremail== uniquedata[property]){           
        document.getElementById("btnRpt").style.display = "block";
      }  
    }    
    const result = await getAll();
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const todaysdate = dd + '/' + mm + '/' + yyyy;
    switch (indication) {
      case 'approvalrequest':
        const resultPdaSpoc = await getPdaSpocName();
    
      let dataEmail = resultPdaSpoc.map(t => t.TeamMemberEmail);      
let uniquedataEmail = [...new Set(dataEmail)];
let pdamember=false
for (let property in uniquedataEmail) {  

if(useremail=== uniquedataEmail[property]){
  pdamember=true;     

}  
}
if(pdamember){
  filteredResult = result.filter((item) =>  (item.ChangeApprovedbyGC != "Yes"));
}
else{
  debugger;
  filteredResult = result.filter((item) => ((((item.DMCMember == username || item.DMCMemberEmail==useremail)&& item.ApprovedByDmc != "Yes")
  || ((item.PDASpocName == username ||item.PDASpocEmail == useremail) && item.ApprovedByPDATeam != "Yes" && item.ApprovedByDmc == "Yes")
  || (item.DMCMemberOwner == useremail && item.ChangeAcceptedbyDMC != "Yes" && item.ApprovedByPDATeam == "Yes")
  || (item.PDAApprover == username && item.ChangeValidatedByPDA != "Yes" && item.ChangeAcceptedbyDMC == "Yes")
  || (item.GCApprover == username && item.ChangeApprovedbyGC != "Yes" && item.ChangeValidatedByPDA == "Yes")
  // || (item.PDAStopWorkApprover == useremail && item.StopWorkApprovedByPda != "Yes" && item.StopWorkApprovedByDMC == "Yes")
  //BOC for Defect 8183 by Vikas
  || (item.PDAStopWorkApprover == useremail && item.StopWorkApprovedByPda == "" && item.StopWorkApprovedByDMC == "Yes")
  //EOC for Defect 8183 by Vikas
  || (item.GCStopWorkApprover == useremail && item.StopWorkApprovedByGC != "Yes" && item.StopWorkApprovedByPda == "Yes")
)) && (item.ChangeApprovedbyGC != "Yes"));
}
for (let i = 0; i < filteredResult.length; i++) {
  let DmcAging = "";
  let FeasibilityAging = "";
  let PDASPOCAging = "";
  let FinanceAging = "";
  let ProjectDMCAging = "";
  let PDAHeadAging = "";
  let BHAging = "";
  let maxDate = new Date();
  let feasibilitySubmitted = ""
  if (filteredResult[i].PendingWithInitiator != "Yes") {
    if (filteredResult[i].ApprovedByDmc.toString().toUpperCase() == "YES") {
      DmcAging = getAging(filteredResult[i].ChangeInitiationOn.toString(), filteredResult[i].ApprovedByDmcDate.toString())
    }
    else {
      DmcAging = getAging(filteredResult[i].ChangeInitiationOn.toString(), todaysdate);
    }
  }
  if (filteredResult[i].ChangeFesibility_releventStakeholders.toString().toUpperCase() == "YES" && filteredResult[i].ApprovedByDmc.toString().toUpperCase() == "YES" && filteredResult[i].CountOfStakeHolders > 0 ) {
    const result1 = await getFeasibilityData(filteredResult[i].ChangeRequestNumber.toString());
    let FeasibilityDates = [];
    let count = 0;
    if (result1.length > 0) {
      for (let i = 0; i < result1.length; i++) {
        if (result1[i].DMCInputAcceptance == "Yes") {
          count = count + 1;
          const dateObjtmp = result1[i].DMCInputAcceptanceOn.toString().split(" ")[0].split("/");
          const dateObj = new Date(dateObjtmp[2] + "-" + dateObjtmp[1] + "-" + dateObjtmp[0]);
          FeasibilityDates.push(dateObj);
        }
      };
      if (count == result1.length && result1.length==filteredResult[i].CountOfStakeHolders) {
        feasibilitySubmitted = "YES";
        maxDate = new Date(Math.max.apply(null, FeasibilityDates));
      }
      else {

        maxDate = new Date(todaysdate.split("/")[2] + "-" + todaysdate.split("/")[1] + "-" + todaysdate.split("/")[0]);;
      }
    }
    else {

      maxDate = new Date(todaysdate.split("/")[2] + "-" + todaysdate.split("/")[1] + "-" + todaysdate.split("/")[0]);;
    }
      maxDate = maxDate.toLocaleDateString("en-AU");
      FeasibilityAging = getAging(filteredResult[i].ApprovedByDmcDate.toString(), maxDate);
   
  }
  let FeasibilitySubmissionDate = "";
  let PDASpoc_FinanceDate = "";
  let PDASpoc_FinanceSumbitted = "";


  if (filteredResult[i].ChangeFesibility_releventStakeholders.toString().toUpperCase() == "YES" && feasibilitySubmitted == "YES") {
    FeasibilitySubmissionDate = maxDate;
  }
  
  if (filteredResult[i].ChangeFesibility_releventStakeholders.toString().toUpperCase() == "YES" && filteredResult[i].CountOfStakeHolders > 0  ) {
  if(feasibilitySubmitted.toUpperCase() == "YES"){
    let financedate = "";
    let pdaspocdate = "";
    if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES") {
    
      PDASPOCAging = getAging(FeasibilitySubmissionDate, filteredResult[i].ApprovedByPDATeamOn.toString());
      pdaspocdate = filteredResult[i].ApprovedByPDATeamOn.toString();
    }
    else {
      
      PDASPOCAging = getAging(FeasibilitySubmissionDate, todaysdate);
      pdaspocdate = todaysdate;
    }

    if (filteredResult[i].IsFinance.toString().toUpperCase() == "YES") {

      if (filteredResult[i].ApprovedByFinance.toString().toUpperCase() == "YES") {
        FinanceAging = getAging(FeasibilitySubmissionDate, filteredResult[i].ApprovedByFinanceOn.toString());
        financedate = filteredResult[i].ApprovedByFinanceOn.toString();
      }
      else {
        FinanceAging = getAging(FeasibilitySubmissionDate, todaysdate);
        financedate = todaysdate;
      }
    }
    if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES" && filteredResult[i].ApprovedByFinance.toString().toUpperCase() == "YES") {
      PDASpoc_FinanceSumbitted = "YES";
    }
    pdaspocdate = new Date(pdaspocdate.toString().split("/")[2] + "-" + pdaspocdate.toString().split("/")[1] + "-" + pdaspocdate.toString().split("/")[0]);
    financedate = new Date(financedate.toString().split("/")[2] + "-" + financedate.toString().split("/")[1] + "-" + financedate.toString().split("/")[0]);
    PDASpoc_FinanceDate = ((pdaspocdate.getTime() > financedate.getTime()) ? pdaspocdate : financedate);
  }
  }
  else{
    if (filteredResult[i].ApprovedByDmc.toString().toUpperCase() == "YES")  {
    let financedate = "";
    let pdaspocdate = "";
    if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES") {
    
      PDASPOCAging = getAging(filteredResult[i].ApprovedByDmcDate.toString().toUpperCase(), filteredResult[i].ApprovedByPDATeamOn.toString());
      pdaspocdate = filteredResult[i].ApprovedByPDATeamOn.toString();
    }
    else {
     
      PDASPOCAging = getAging(filteredResult[i].ApprovedByDmcDate.toString().toUpperCase(), todaysdate);
      pdaspocdate = todaysdate;
    }
  
    if (filteredResult[i].IsFinance.toString().toUpperCase() == "YES") {
  
      if (filteredResult[i].ApprovedByFinance.toString().toUpperCase() == "YES") {
        FinanceAging = getAging(filteredResult[i].ApprovedByDmcDate.toString().toUpperCase(), filteredResult[i].ApprovedByFinanceOn.toString());
        financedate = filteredResult[i].ApprovedByFinanceOn.toString();
      }
      else {
        FinanceAging = getAging(filteredResult[i].ApprovedByDmcDate.toString().toUpperCase(), todaysdate);
        financedate = todaysdate;
      }
    }
    if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES" && filteredResult[i].ApprovedByFinance.toString().toUpperCase() == "YES") {
      PDASpoc_FinanceSumbitted = "YES";
    }
    pdaspocdate = new Date(pdaspocdate.toString().split("/")[2] + "-" + pdaspocdate.toString().split("/")[1] + "-" + pdaspocdate.toString().split("/")[0]);
    financedate = new Date(financedate.toString().split("/")[2] + "-" + financedate.toString().split("/")[1] + "-" + financedate.toString().split("/")[0]);
    PDASpoc_FinanceDate = ((pdaspocdate.getTime() > financedate.getTime()) ? pdaspocdate : financedate);
  }
}
  
  if (filteredResult[i].ApprovedByPDATeam.toString().toUpperCase() == "YES") {
    if (filteredResult[i].ChangeAcceptedbyDMC.toString().toUpperCase() == "YES") {
      ProjectDMCAging = getAging(filteredResult[i].ApprovedByPDATeamOn.toString(), filteredResult[i].ProjDMCAcceptanceDate.toString());
    }
    else {
      ProjectDMCAging = getAging(filteredResult[i].ApprovedByPDATeamOn.toString(), todaysdate);
    }
  }

  if (filteredResult[i].ChangeAcceptedbyDMC.toString().toUpperCase() == "YES") {
    if (filteredResult[i].ChangeValidatedByPDA.toString().toUpperCase() == "YES") {
      PDAHeadAging = getAging(filteredResult[i].ProjDMCAcceptanceDate.toString(), filteredResult[i].ApprovedByPdaOn.toString());
    }
    else {
      PDAHeadAging = getAging(filteredResult[i].ProjDMCAcceptanceDate.toString(), todaysdate);
    }
  }
  if (filteredResult[i].ChangeValidatedByPDA.toString().toUpperCase() == "YES") {
    if (filteredResult[i].ChangeApprovedbyGC.toString().toUpperCase() == "YES") {
      BHAging = getAging(filteredResult[i].ApprovedByPdaOn.toString(), filteredResult[i].GCHeadApprovalDate.toString());
    }
    else {
      BHAging = getAging(filteredResult[i].ApprovedByPdaOn.toString(), todaysdate);
    }
  }
  filteredResult[i].DmcAging = Math.floor(DmcAging);
  filteredResult[i].FeasibilityAging = Math.floor(FeasibilityAging);
  filteredResult[i].PDASPOCAging = Math.floor(PDASPOCAging);
  filteredResult[i].FinanceAging = Math.floor(FinanceAging);
  filteredResult[i].ProjectDMCAging = Math.floor(ProjectDMCAging);
  filteredResult[i].PDAHeadAging = Math.floor(PDAHeadAging);
  filteredResult[i].BHAging = Math.floor(BHAging);
  filteredResult[i].FeasibilitySubmitted = feasibilitySubmitted;

};
        if (tempSort == false) {

          filteredResult.sort((a, b) => new Date(b.ChangeRequestDate.split(" ")[0].split("/")[2] + "-" + b.ChangeRequestDate.split(" ")[0].split("/")[1] + "-" + b.ChangeRequestDate.split(" ")[0].split("/")[0]) - new Date(a.ChangeRequestDate.split(" ")[0].split("/")[2] + "-" + a.ChangeRequestDate.split(" ")[0].split("/")[1] + "-" + a.ChangeRequestDate.split(" ")[0].split("/")[0]));
        }
        else {
          filteredResult.sort((a, b) => new Date(a.ChangeRequestDate.split(" ")[0].split("/")[2] + "-" + a.ChangeRequestDate.split(" ")[0].split("/")[1] + "-" + a.ChangeRequestDate.split(" ")[0].split("/")[0]) - new Date(b.ChangeRequestDate.split(" ")[0].split("/")[2] + "-" + b.ChangeRequestDate.split(" ")[0].split("/")[1] + "-" + b.ChangeRequestDate.split(" ")[0].split("/")[0]));
        }
        
        setChangeInitiation(filteredResult);
        if(filteredResult.length==0){
          alert("No Records Found");
          return;
        }

        break;
      default:
        setChangeInitiation(result);
    }

  
  };
  var bgColors = {
    "Orange": "#FFA500",
    "Gray": "#A0A0A0",
    "Green": "#006400",
    "Red": "#FF0000",
  };
  const handleView = (row) => {
    var ChangeReqNo = row.ChangeRequestNumber;
    var ProjectNumber = row.ProjectNumber;

    sessionStorage.setItem('ChangeReqNo', ChangeReqNo);
    sessionStorage.setItem('ProjNo', ProjectNumber);
    sessionStorage.setItem('NavPath', "/approvalrequest");
    navigate("/changeinitiationdetailscreen");
  };
  const approvalrequest = () => {
    sessionStorage.setItem('NavPath', "/approvalrequest");
    navigate("/changeinitiation");
  }
  const handleDelete = (row) => {
    let ans = window.confirm("Do you want to Delete this record?");
    if (ans) {

      deleteChangeInitiation(row.ProjectNumber, row.ChangeRequestNumber)
        .then((resp) => {
          if (resp.data.status) {

            fetchChangeInitiation();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };



  const deleteButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        size="sm"
        variant="outline-danger"
        onClick={() => {
          handleDelete(row);
        }}
      >

        Delete
      </Button>
    );
  };

  const viewButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        size="sm"
        variant="outline-success"
        onClick={() => {
          handleView(row);
        }}
      >
        View
      </Button>
    );
  };


  return (


    <Container className="LogbookForm">
      <div>
        <div class="float-end m-1"><Button class=" ml-5 btn-primary" style={{ float: "right" }} size="sm"
          onClick={approvalrequest}> Add New </Button></div>

        <div class="float-end m-1">
          <Button class=" ml-5 btn-primary" style={{ float: "right" }} size="sm"
            onClick={routeChange} > &#8645;</Button>
        </div>
        <div class="float-end m-1">
          <Button id="btnRpt" class=" ml-5 btn-primary" style={{ float: "right" }} size="sm"
            onClick={getChangeInitiationReport} > Report </Button>
        </div>
      </div>


      <br></br>
      <br></br>
      <div class="col-6 mb-2">
        <h2>Change Initiation Dashboard</h2>
      </div>
      <InputGroup className="mb-3">
        <InputGroup.Text id="search">Search</InputGroup.Text>
        <FormControl ref={searchInput}
          onChange={() => handleSearch(searchInput.current.value)}
          placeholder="Search by Change Request Number"
          aria-label="Search"
          aria-describedby="search"
        />
      </InputGroup>
      <hr />
      {ChangeInitiation.map((Cidata, index) =>
        <Form className="form" key={index} >
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
          <Row>
            <Col >
              <Form.Group className="mb-3">
                <Form.Label>Change Request Number : {Cidata.ChangeRequestNumber}  </Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Row>
                <Col width="70%">
                  <Form.Group className="mb-3">
                    <Form.Label> Change Request Date : {Cidata.ChangeRequestDate}  </Form.Label>
                    <Button size="sm" style={{ float: "right" }} variant="outline-success" onClick={() => { handleView(Cidata); }} > View </Button>
                  </Form.Group>
                </Col>

              </Row>

            </Col>

          </Row>


          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label> DMC Name : {Cidata.DMCMember}  </Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Work Type : {Cidata.WorkType} </Form.Label>
              </Form.Group></Col>

          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Project Name : {Cidata.ProjectName} </Form.Label>
              </Form.Group></Col>
          </Row>
          <Row>
            <Col class="col-md-2">
              <span class="fa fa-circle" style={{ color: (Cidata.PendingWithInitiator === "Yes" || Cidata.IsStopWorkPendingWithInitiator === "Yes") ? bgColors.Red : bgColors.Green }} >   </span>
              <Form.Label >&nbsp;Initiated----{'>'} &nbsp;</Form.Label>
              <span class="fa fa-circle" style={{ color: (Cidata.StopWork === "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes" && Cidata.StopWorkApprovedByDMC === "Yes") ? bgColors.Green : (Cidata.StopWork !== "Yes" && Cidata.ApprovedByDmc === "Yes") ? bgColors.Green : bgColors.Red }} >   </span>

              <Form.Label >&nbsp;DMC({Cidata.DmcAging})----{'>'}&nbsp; </Form.Label>
              <span class="fa fa-circle" style={{ color: (Cidata.StopWork === "Yes") ? bgColors.Orange : ((Cidata.ChangeFesibility_releventStakeholders === "No" || Cidata.ChangeFesibility_releventStakeholders === "" || Cidata.ChangeFesibility_releventStakeholders === undefined)) ? bgColors.Gray : (Cidata.FeasibilitySubmitted === "YES" || Cidata.CountOfStakeHolders === 0) ? bgColors.Green : bgColors.Red }} >   </span>

              <Form.Label >&nbsp;Feasibility ({Cidata.FeasibilityAging})----{'>'}&nbsp;</Form.Label>

              <span class="fa fa-circle" style={{ color: (Cidata.StopWork === "Yes") ? bgColors.Orange : Cidata.ApprovedByPDATeam !== "Yes" ? bgColors.Red : bgColors.Green }} >   </span>
              <Form.Label >&nbsp;PDASPOC ({Cidata.PDASPOCAging})----{'>'}&nbsp;</Form.Label>


              <span class="fa fa-circle" style={{ color: (Cidata.StopWork === "Yes") ? bgColors.Orange : Cidata.ChangeAcceptedbyDMC !== "Yes" ? bgColors.Red : bgColors.Green }} >   </span>
              <Form.Label >&nbsp;ProjDMC ({Cidata.ProjectDMCAging})----{'>'}&nbsp;</Form.Label>

              <span class="fa fa-circle" style={{ color: (Cidata.StopWork === "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes" && Cidata.StopWorkApprovedByPda === "Yes") ? bgColors.Green : (Cidata.StopWork !== "Yes" && Cidata.ChangeValidatedByPDA === "Yes") ? bgColors.Green : bgColors.Red }} >   </span>
              <Form.Label >&nbsp;PDAHead ({Cidata.PDAHeadAging})----{'>'}&nbsp;</Form.Label>

              <span class="fa fa-circle" style={{ color: (Cidata.StopWork === "Yes" && Cidata.IsStopWorkPendingWithInitiator !== "Yes" && Cidata.StopWorkApprovedByGC === "Yes") ? bgColors.Green : (Cidata.StopWork !== "Yes" && Cidata.ChangeApprovedbyGC === "Yes") ? bgColors.Green : bgColors.Red }} >   </span>
              <Form.Label >&nbsp;BH ({Cidata.BHAging})</Form.Label>
            </Col>

          </Row>
          <Row>
            <Col class="col-md-2">
              <span>   </span>
              <Form.Label ></Form.Label>
              <span>   </span>
              <Form.Label ></Form.Label>
              <span>   </span>
              <Form.Label ></Form.Label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span class="fa fa-circle" style={{ color: (Cidata.StopWork === "Yes") ? bgColors.Orange : Cidata.IsFinance !== "Yes" ? bgColors.Gray : Cidata.ApprovedByFinance === "Yes" ? bgColors.Green : bgColors.Red }} >   </span>
              <Form.Label >&nbsp;Finance ({Cidata.FinanceAging})</Form.Label>

              <span>   </span>
              <Form.Label ></Form.Label>
              <span>   </span>
              <Form.Label ></Form.Label>

              <span>   </span>
              <Form.Label ></Form.Label>

              <span>   </span>
              <Form.Label ></Form.Label>
            </Col>

          </Row>
          <Row>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>______________________________________________________________________________________________________________________________________________________________</Form.Label>
              </Form.Group>
            </Col>


          </Row>
        </Form>
      )}
    </Container>


  );
}

export default ChangeInitiationDetailsApproval;
