/* 
Program Name : ProjectDetails.jsx
Written By: Snehal Kurhade
Written on: 19 APR 2022 
System Name: Change Management App
Purpose:  Screen to display details of Project
Type of Program: React Frontend
*/
import { Table ,Form, InputGroup, FormControl ,Button} from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Container, Navbar, Nav, Collapse, Stack ,Row,Col} from 'react-bootstrap';
import { Link } from 'react-router-dom'

import ProjEnrollment from "../Components/ProjEnrollment";
import ProjectDetails from "../Components/ProjectDetails";

import {
    deleteProject,
    getAll,
    
  } from "../services/project-service";
  import React, { useState, useEffect, useRef } from "react";
  import BootstrapTable from "react-bootstrap-table-next";
  
  
  function ProjDetails() {
    const [project, setProject] = useState(null);
    useEffect(() => {
      fetchProject();
    }, []);
  
    const fetchProject = async () => {
      const result = await getAll();
      
      setProject(result);
    };
    

    const handleDelete = (row) => {
      
      let ans = window.confirm("Do you want to Delete Data?") ;
      if(ans){
      deleteProject(row.Team, row.ProjectId)
        .then((resp) => {
          if (resp.data.status) {
            console.log(resp.data.Message);
            fetchProject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    };
  
    const deleteButton = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          size="sm"
          variant="outline-danger"
          onClick={() => {
            handleDelete(row);
          }}
        >
          Delete
        </Button>
      );
    };
    
    const columns = [
      {
        dataField: "Team",
        text: "Team",
        sort: true,
      },
      {
        dataField: "ProjectId",
        text: "ProjectId",
        sort: true,
      },
      {
        dataField: "ProjectName",
        text: "ProjectName",
        sort: true,
      },
      {
        dataField: "ProjectOwner",
        text: "ProjectOwner",
        sort: true,
      },
      {
        dataField: "ProjectOwnerEmail",
        text: "ProjectOwnerEmail",
        sort: true,
      },
      {
        dataField: "ProjectDMCName",
        text: "ProjectDMCName",
        sort: true,
      },
      {
        dataField: "ProjectDMCEmail",
        text: "ProjectDMCEmail",
        sort: true,
      },
      {
        dataField: "Stakeholder",
        text: "Stakeholder",
        sort: true,
      },
      {
        dataField: "EmailIDs",
        text: "EmailIDs",
        sort: true,
      },
      {
        dataField: "Team",
        text: "Team",
        sort: true,
      },
      
      {
        dataField: "WorkType",
        text: "WorkType",
        sort: true,
      },

      {
        dataField: "IsActive",
        text: "IsActive",
        sort: true,
      },

      {
        dataField: "",
        text: "",
        formatter: deleteButton,
      },
      {
        dataField: "",
        text: "",
        button: "submit"
      },
    ];
  
    const defaultSorted = [
      {
        dataField: "",
        order: "asc",
      },
    ];
  
    return (
      project && (
        <div >
            
           
          
      
 

            <Link to={"/projectenrollment"}>Add New Projects</Link>
          <BootstrapTable
            keyField="Team"
            data={project}
            columns={columns}
            // loading={true}
            defaultSorted={defaultSorted}
          />
        </div>
      )
    );
  }
  
  export default ProjDetails;
  