/* 
Program Name : ChangeInitiationDetailScreen.jsx
Written By: Snehal Kurhade
Written on: 20 APR 2022 
System Name: Change Management App
Purpose:  Screen to Display details of selected change initiation 
Type of Program: React Frontend
*/

import React, { useState, useEffect, useRef, Component } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import "../App_Arun.css";
import Progress from 'react-progressbar';
import {
    getdetails,
    getFeasibilityData,
    getChangeAnalysisData,
    getRejectionData

} from "../services/ChangeInitiation-service";

const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "ChangeInitiation"; //process.env.REACT_APP_CHANGEINITIATION_API_URL;

function ChangeInitiationDetailScreen() {
    var ChangeReqNo = sessionStorage.getItem("ChangeReqNo");
    var ProjNo = sessionStorage.getItem("ProjNo");
    var username = localStorage.getItem('UserName');
    var useremail = localStorage.getItem('UserId');

    useEffect(() => {
        fetchchangeInitiationData();
    }, []);

    let Worktype = [];
    const navigate = useNavigate();
    const [changeInitiationData, setchangeInitiationData] = useState([]);
    const [FeasibilityData, setFeasibilityData] = useState([]);
    const [ChangeAnalysisData, setChangeAnalysisData] = useState([]);
    const [SubmittedFeasinilityCount, setSubmittedFeasinilityCount] = useState(0);
    const [RejectionCount, setRejectionCount] = useState(0);
    let count = 0;

    const fetchchangeInitiationData = async () => {

        const result1 = await getFeasibilityData(ChangeReqNo);
        setFeasibilityData(result1);
        for (let i = 0; i < result1.length; i++) {
            if (result1[i].DMCInputAcceptance == "Yes") {
                count = count + 1;
            }
        }
        setSubmittedFeasinilityCount(count);

        const result2 = await getChangeAnalysisData(ChangeReqNo);
        setChangeAnalysisData(result2);
        const result = await getdetails(ChangeReqNo);
        setchangeInitiationData(result);
        const RejectionResult = await getRejectionData(ChangeReqNo);

        setRejectionCount(RejectionResult.length);
        let tmpfeasibilitysection = result.map(i => i.GodrejSectionDivisions).toString();
        let feasibilitysection = tmpfeasibilitysection.split(",");
        console.log("feasibilitysection" , feasibilitysection);
        for (let i = 0; i < feasibilitysection.length; i++) {
            document.getElementById(feasibilitysection[i]).setAttribute('checked', 'checked');
            document.getElementById(feasibilitysection[i]).style.backgroundColor = "red";
        };

        for (let i = 0; i < result1.length; i++) {
            if (result1[i].DMCInputAcceptance == "Yes") {
                document.getElementById(result1[i].Division).style.backgroundColor = "green";
            }
        };

    };
    const routeChange = () => {
        sessionStorage.setItem('ChangeReqNo', ChangeReqNo);
        sessionStorage.setItem('FeasibilityCount', SubmittedFeasinilityCount);
        navigate("/editChangeInitiation");
    }
    const onBackClick = () => {
        var NavPath = sessionStorage.getItem("NavPath");
        navigate(NavPath);
    }

    const FeasibilityRouteChange = () => {
        sessionStorage.setItem('reqChangeReqNo', ChangeReqNo);
        sessionStorage.setItem('FromInitiationDetailScreen', "Yes");
        navigate("/feasibilitydashboard");
    }
    return (
        <Container className="LogbookForm">
            <div>
                <div class="float-end m-1">
                    <Button class="ml-5 btn-primary"
                        style={{ float: "right" }}
                        size="sm"
                        variant="outline-success" onClick={routeChange}
                    >
                        Edit
                    </Button>

                </div>
                <div class="float-end m-1">
                    <Button class=" ml-5 btn-primary"
                        style={{ float: "right" }}
                        size="sm"
                        onClick={onBackClick}
                    >
                        Back
                    </Button>
                </div>
                <div class="float-end m-1">
                    <Button class=" ml-5 btn-primary"
                        style={{ float: "right" }}
                        size="sm"
                        onClick={FeasibilityRouteChange}
                    >
                        Feasibility Submitted
                    </Button>
                </div>
                {/* <Button size="sm" class="d-block mr-0 ml-auto"  variant="outline-success" onClick={routeChange}  >  Edit </Button> */}
            </div>
            <br></br>
            <br></br>
            <h5> <span class="text-primary">Change Initiation </span></h5>
            {/* <h2 >Create New LogBook Request</h2> */}
            <hr />
            {changeInitiationData.map((Cidata, index) =>
                <Form className="form" key={index} >

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Change Request Number : {Cidata.ChangeRequestNumber}  </Form.Label>

                            </Form.Group></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Is Rejected : {RejectionCount == 0 ? "No" : "Yes"} </Form.Label>

                            </Form.Group></Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Rejection Count : {RejectionCount} </Form.Label>

                            </Form.Group></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Change Initiation Owner : {Cidata.ChangeInitiationOwner}  </Form.Label>

                            </Form.Group></Col>
                        <Col>
                            <Form.Group className="mb-3">

                            </Form.Group></Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Work Type : {Cidata.WorkType}  </Form.Label>

                            </Form.Group></Col>
                        <Col>
                            <Form.Group className="mb-3">


                            </Form.Group></Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Project Name : {Cidata.ProjectName}  </Form.Label>

                            </Form.Group></Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Project Number : {Cidata.ProjectNumber}  </Form.Label>

                            </Form.Group></Col>
                        <Col>
                            <Form.Group className="mb-3">


                            </Form.Group></Col>

                    </Row>

                    <Row>
                        <span>
                            {/* <Form.Label>Count Of feasibility Report :  {(Cidata.CountOfStakeHolders !=="" && Cidata.CountOfStakeHolders !==undefined) ? SubmittedFeasinilityCount+"/"+Cidata.CountOfStakeHolders : SubmittedFeasinilityCount+"/"+"0" } </Form.Label> */}

                            <div class="slidecontainer">

                                Count of Feasibility Report : <input type="range" min="1" backgroundColor="#04AA6D" color="#04AA6D" max="100" value={(Cidata.CountOfStakeHolders !== "" && Cidata.CountOfStakeHolders !== undefined && SubmittedFeasinilityCount !== 0) ? (SubmittedFeasinilityCount / Cidata.CountOfStakeHolders) * 100 : 0}></input> {(Cidata.CountOfStakeHolders !== "" && Cidata.CountOfStakeHolders !== undefined) ? SubmittedFeasinilityCount + "/" + Cidata.CountOfStakeHolders : "  0" + "/" + "0"}


                            </div>
                            {/* <Progress id="progressbar" completed={ (Cidata.CountOfStakeHolders !=="" && Cidata.CountOfStakeHolders !==undefined) ? (SubmittedFeasinilityCount / Cidata.CountOfStakeHolders)*100 : (SubmittedFeasinilityCount / 0)*100} />   */}
                        </span>

                    </Row><br></br>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Feasibility Submitted : </Form.Label>
                            </Form.Group></Col>
                        <Col>
                            <Form.Group className="mb-3">

                            </Form.Group></Col>

                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkContracts">
                                <Form.Check type="checkbox" readOnly id="Contracts" label="Contracts" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkCRE">
                                <Form.Check type="checkbox" readOnly id="CRE" label="CRE" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkBE&P">
                                <Form.Check type="checkbox" readOnly id="BE&P" label="BE&P" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkCM">
                                <Form.Check type="checkbox" readOnly id="CM" label="CM" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkDesign">
                                <Form.Check type="checkbox" readOnly id="Design" label="Design" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkFinance">
                                <Form.Check type="checkbox" readOnly id="Finance" label="Finance" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkEES">
                                <Form.Check type="checkbox" readOnly id="EES" label="EES" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkProcurement">
                                <Form.Check type="checkbox" readOnly id="Procurement" label="Procurement" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkIT">
                                <Form.Check type="checkbox" readOnly id="IT" label="IT" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkCES">
                                <Form.Check type="checkbox" readOnly id="CES" label="CES" />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group className="mb-3" controlId="chkStatutory">
                                <Form.Check type="checkbox" readOnly id="Statutory Approval" label="Statutory Approval" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkPDA">
                                <Form.Check type="checkbox" readOnly id="PDA" label="PDA" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkREL">
                                <Form.Check type="checkbox" readOnly id="RE-L" label="RE-L" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkSafety">
                                <Form.Check type="checkbox" readOnly id="Safety" label="Safety" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            <Form.Group className="mb-3">
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkMarketing">
                                <Form.Check type="checkbox" readOnly id="Marketing" label="Marketing" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkRED">
                                <Form.Check type="checkbox" readOnly id="RE-D" label="RE-D" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkHR">
                                <Form.Check type="checkbox" readOnly id="HR" label="HR" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkKhalapur">
                                <Form.Check type="checkbox" readOnly id="Khalapur" label="Khalapur" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkHMS">
                                <Form.Check type="checkbox" readOnly id="HMS" label="HMS" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkSA">
                                <Form.Check type="checkbox" readOnly id="SA" label="SA" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chkENE">
                                <Form.Check type="checkbox" readOnly id="ENE" label="ENE" />
                            </Form.Group>
                        </Col>

                    </Row>
                    <Row>

                        <Col>
                            <Form.Group className="mb-3">
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="chk_RED">
                                <Form.Check type="checkbox" readOnly id="RED" label="RED" />
                            </Form.Group>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>

                    <Row><br></br></Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Change Analysis: </Form.Label>
                            </Form.Group></Col>

                        <Col>
                            <Form.Group className="mb-3" controlId="chkanalysis">
                                {ChangeAnalysisData.map((Cadata, index) =>
                                    <Form.Check type="checkbox" checked={Cadata.ChangeAnalysisNumber !== "" ? true : false} id={Cadata.ChangeAnalysisNumber} label={Cadata.ChangeAnalysisNumber !== "" ? Cadata.ChangeAnalysisNumber : "Not Submitted"} />
                                )}
                            </Form.Group>
                        </Col>

                        <Col></Col>
                        <Col></Col>
                        <Col></Col>

                    </Row>
                    <Row>

                        <Col>
                            <Form.Group className="mb-3">
                            </Form.Group>
                        </Col>


                    </Row>
                </Form>
            )}
        </Container>

    )
}

export default ChangeInitiationDetailScreen;






