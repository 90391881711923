import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "ProjectMaster"; //process.env.REACT_APP_PROJECT_API_URL;
//const axios = require("axios");

export async function getAll() {
  return fetch(API_URL).then((response) => response.json());
}

export async function getWorktype() {
  return fetch(API_URL).then((response) => response.json());
}

export async function getTeams() {
  return fetch(API_URL).then((response) => response.json());
}

export async function getProject(Team, WorkType) {
  let url = `${API_URL}/Team/${Team}/WorkType/${WorkType}`;
  console.log("getProject-Service")
  console.log(url)
  return fetch(url).then((response) => response.json());
 
}

export async function getProjectDetails(Team, WorkType, ProjName) {
  let url = `${API_URL}/Team/${Team}/WorkType/${WorkType}/ProjName/${ProjName}`;
  console.log("getProject-Service")
  console.log(url)
  return fetch(url).then((response) => response.json());
 
}




export async function deleteProject(tm, ProjectId) {
  //console.log("deleteProject");
  //console.log(Team, ProjectId);
  let url = `${API_URL}/tm/${tm}/ProjectId/${ProjectId}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export const EnrollNewProject = async (Project) => {
  console.log(JSON.stringify(Project));

  const resp = await axios
    .post(API_URL, JSON.stringify(Project), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};


