/* 
Program Name : Uploder.jsx
Written By: Mayur Solanki
Written on: 12 Mar 2022 
System Name: Change Management App
Purpose:  Screen to Update details of Change Analysis
Type of Program: React Frontend
*/
import {getInitation1,getChangeAnalysisNumber,getInitation2} from "../services/ca-service";
import axios from "axios";
import { getAll,getEdmcDetails} from "../services/edmc-service";
import React from 'react'
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { FileEarmarkText, Trash } from "react-bootstrap-icons";
import { Table, Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import "../App_Arun.css";

const Uploader = () => {    
    const navigate = useNavigate();
    const [CorrectiveAction, setCorrectiveAction] = useState("");
    const [PreventiveAction, setPreventiveAction] = useState("");
    const [ProcessUpdationDate, setProcessUpdationDate] = useState("");
    const [Justification, setJustification] = useState("");
    const [TrackingPeriod, setTrackingPeriod] = useState(0);
    const [TrackingEffectivenessEDMCMember, setTrackingEffectivenessEDMCMember] = useState("");
    const [TrackingDMCMember, setTrackingDMCMember] = useState("");
    const [ProcessChangeImpact, setProcessChangeImpact] = useState("No");
    const [ChangeAnalysisDetails, setChangeAnalysisDetails] = useState([]);
    
     //attachment
     const [UploadedFileLocation, setUploadedFileLocation ] = useState("");
     const [UploadedFileNames, setUploadedFileNames] = useState("");
  const [MultipleFileLocations, setMultipleFileLocations ] = useState([]);
  const [FileNames, setFileNames] = useState([]);
  
  const [newAttachedFile, setnewAttachedFile] = useState([]);
  const [fileList, setFileList] = useState([]);

  //const axios = require("axios").default;
 

  let username = localStorage.getItem('UserName');
  let userEmail = localStorage.getItem('UserId');

  var uploadtoprint = sessionStorage.getItem('toUploadFilePrevLocation')
  var uploadfilenames = sessionStorage.getItem('toUploadFilePrevNames')
  var ca = sessionStorage.getItem('keytoprintca')
  var ProcessImpact =sessionStorage.getItem('keytoprintProcessChangeImpact')
  var pa = sessionStorage.getItem('keytoprintpa')
  var j = sessionStorage.getItem('keytoprintjustification')
  var PADate = sessionStorage.getItem('keytoprintprocessupdationdate')
  
  var tp = sessionStorage.getItem('keytoprinttp')
  var ted = sessionStorage.getItem('keytoprintted')
  var td = sessionStorage.getItem('keytoprinttd')
  var ChangeInitiationOwner= sessionStorage.getItem('keysesvarcio'); 

  


  useEffect(() => {
    fetchsetret();
  }, [])

  const fetchsetret = async () => { 
    var chanuber = sessionStorage.getItem('keycanum');
    const result2 = await getInitation2(chanuber);
    setChangeAnalysisDetails(result2);

    if((result2.map(i => i.IsRejected)).toString().toUpperCase()=="YES" && ChangeInitiationOwner===username){
     
      document.getElementById("attachmentrow").style.display = "none";
      document.getElementById("Dropzone").style.display = "block";
  }
  else{
   
      document.getElementById("Dropzone").style.display = "none";
      document.getElementById("attachmentrow").style.display = "block";
  }
setUploadedFileLocation(uploadtoprint);
setUploadedFileNames(uploadfilenames);
setCorrectiveAction(ca);
setPreventiveAction(pa);
setJustification(j);
setProcessUpdationDate(PADate);
setTrackingPeriod(tp);
setTrackingEffectivenessEDMCMember(ted);
setTrackingDMCMember(td);
setProcessChangeImpact(ProcessImpact);
const flocation=  result2.map(i => i.UploadedFileLocation).toString().split(",") ;
const fname=  result2.map(i => i.UploadedFileNames).toString().split(","); 
const sesflocation=  result2.map(i => i.UploadedFileLocation).toString() ;
const sesfname=  result2.map(i => i.UploadedFileNames).toString(); 
const Prevrejected= result2.map(i => i.IsRejected).toString();
 sessionStorage.setItem("keys3Location", sesflocation);
 sessionStorage.setItem("keysFileNames", sesfname);
 sessionStorage.setItem("keysPrevrejected", Prevrejected);
let s="";    
// for (let i=0; i<flocation.length;i++) {
// //    s=" <a href=" + {flocation[i]} + "> Download Here </a>";
// s +=" <a href=" + flocation[i] + ">" + fname[i] +"</a>";
   
// }
for (let i=0; i<fname.length;i++) {
  let fileerror="";

  const API_ENDPOINT = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Attachment/filename/"+fname[i] 
  const response = await axios({
      method: 'GET',
      url: API_ENDPOINT
  }).catch((error) => {
    fileerror="Yes"
      console.log(error);               
  });
//    s=" <a href=" + {flocation[i]} + "> Download Here </a>";
if( fileerror !="Yes"){
  s +=" <a href=" +  response.data.uploadURL + ">" + fname[i] +"</a>";
  }
}

(document.getElementById("attachmentrow").innerHTML = s)


}    
    const handleChangeStatus = ({ meta, remove }, status) => {
     
    }  
      
    
    
  const onFileDrop = (e) => {
      
    const newFile = e.target.files[0];
    
    const abc= [...newAttachedFile, newFile];
    let tmpAllowedFileType = process.env.REACT_APP_ATTACHMENT_TYPE;
    const AllowedFileType = tmpAllowedFileType.split(",");
    let isvalid = true;
    let isvalidFormat = false;      
    let Filetype = "";

    Filetype = newFile.name.slice((Math.max(0, newFile.name.lastIndexOf(".")) || Infinity) + 1)

    for (let j = 0; j < AllowedFileType.length; j++) {
        if (Filetype == AllowedFileType[j]) {               
            isvalidFormat = true;
        }           
    }

    if (isvalidFormat != true) {
        alert("Attached file should be of type .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg \r\n Files are not saved ");
        isvalidFormat = false;
        return;
    }
    if (newFile.size > 5000000) {
        alert("File " + newFile.name + " is greater than 5 MB \r\n Files are not saved");
        isvalid = false;
        return;
    }
   
    if(isvalid){
    if (newFile) {
        const updatedList = [...fileList, newFile];                      
        setFileList(updatedList); 
        handleAttachmentSubmit(abc);            
       
       setnewAttachedFile([]);
    }
}
};

const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    
    setFileList(updatedList);
  
    MultipleFileLocations.splice(fileList.indexOf(file), 1);
    FileNames.splice(fileList.indexOf(file), 1);
    
    const FileLocations = MultipleFileLocations.map(i => i.s3Location).toString();
    const FileName = FileNames.map(i => i.Fnames).toString();


    sessionStorage.setItem("keys3Location", FileLocations);
    sessionStorage.setItem("keysFileNames", FileName);

  
};

const handleAttachmentSubmit = async (files) => {

    let isvalid = true;
    
    let tmpAllowedFileType = process.env.REACT_APP_ATTACHMENT_TYPE;
    const AllowedFileType = tmpAllowedFileType.split(",");
    
    for (let i = 0; i < files.length; i++) {
        let isvalidFormat = false;
        const tempf = files[i];
       
        let Filetype = "";
        Filetype = files[i].name.slice((Math.max(0, files[i].name.lastIndexOf(".")) || Infinity) + 1)
        
        for (let j = 0; j < AllowedFileType.length; j++) {
            if (Filetype == AllowedFileType[j]) {                    
                isvalidFormat = true;
            }           
        }

        if (isvalidFormat != true) {
            alert("Attached file should be of type .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg \r\n Files are not saved ");
            isvalidFormat = false;
            return;
        }
        if (files[i].size > 5000000) {
            alert("File " + files[i].name + " is greater than 5 MB \r\n Files are not saved");
            isvalid = false;
            return;
        }

    }
    if (isvalid == true) {
     
        for (let i = 0; i < files.length; i++) {
            const f = files[i];                
            FileNames.push({ Fnames: files[i].name });
            const API_ENDPOINT = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Attachment/filename/"+files[i].name 

            const response = await axios({
                method: 'GET',
                url: API_ENDPOINT
            }).catch((error) => {
                console.log(error);
               
            });
            
                       
            const result = await fetch(response.data.uploadURL, {
                method: 'PUT',
                body: files[i]
            }).catch((error) => {
                console.log(error);
              
            });                
            
            let var2 = response.data.uploadURL;
            // let s3Location = var2.substring(0, var2.indexOf("?"));
            let s3Location=response.data.uploadURL;
                         
            setUploadedFileLocation(s3Location);
            MultipleFileLocations.push({ s3Location: s3Location });
            
        }
      
    }
    
   const FileLocations = MultipleFileLocations.map(i => i.s3Location).toString();
     const FileName = FileNames.map(i => i.Fnames).toString();


     sessionStorage.setItem("keys3Location", FileLocations);
     sessionStorage.setItem("keysFileNames", FileName);

}

const CheckValidEDMCEmail = async () => {  
  let validEmailAlertMessage="";    
   const EDMCResult = await getAll(); 
   let ValidEDMCEmailId=true;
   let ValidDMCEmailId=true;
   
   let EdmcMember=TrackingEffectivenessEDMCMember.split(";");
   let DmcMember=TrackingDMCMember.split(";");
   let EdmcMemberdata = EDMCResult.map(t => t.EDMCMemberEmail); 
   let DmcMemberdata = EDMCResult.map(t => t.DMCofEDMC);   
 
   for (let i = 0; i < EdmcMember.length; i++) {   
    if(!(EdmcMemberdata.includes(EdmcMember[i].trim()))){
     ValidEDMCEmailId=false;       
    }   
  }
  for (let i = 0; i < DmcMember.length; i++) {   
   if(!(DmcMemberdata.includes(DmcMember[i].trim()))){
     ValidDMCEmailId=false;   
   } 
 }
 if(ValidEDMCEmailId==false){
   validEmailAlertMessage+="Entered EDMC Member is Not Valid or same is not maintained in database \r\n";
 }
 if(ValidDMCEmailId==false){
   validEmailAlertMessage+="Entered DMC Member is Not Valid or same is not maintained in database \r\n";
 }
 return validEmailAlertMessage;
} ; 
   

      function chkValidation(e) {  
        let alertmsg="";   
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 

        if(sessionStorage.getItem("keysFileNames")=="" ||sessionStorage.getItem("keysFileNames")== null ){
          alertmsg+="Please Upload File \r\n";
        }
        if(CorrectiveAction.trim().toString()=="" ||CorrectiveAction.trim().toString()== null ){
          alertmsg+="Please Enter Corrective Action \r\n";
        }
        // if(CorrectiveAction.trim().length < 50 ){
        //   alertmsg+="Minimum length for Corrective Action is 50 \r\n";
        // }
        if(PreventiveAction.trim().toString()=="" ||PreventiveAction.trim().toString()== null ){
          alertmsg+="Please Enter Preventive Action \r\n";
        }
        // if(PreventiveAction.trim().length < 50 ){
        //   alertmsg+="Minimum length for Preventive Action  is 50 \r\n";
        // }
        if(ProcessChangeImpact.trim().toUpperCase()=="YES" && (ProcessUpdationDate.trim().toString()=="" || ProcessUpdationDate.trim().toString()==null )){
          alertmsg+="Please Select Process Updation Date  \r\n";
        }
        if(ProcessChangeImpact.trim().toUpperCase() !="YES" && (Justification.trim().toString()=="" || Justification.trim().toString()==null )){
          alertmsg+="Please Enter Justification note  \r\n";
        }
        if(TrackingPeriod.trim().toString()=="" || TrackingPeriod.trim().toString()==null ){
          alertmsg+="Please Enter Tracking Period  \r\n";
        }
        
        if(!(TrackingPeriod.trim() ==3 || TrackingPeriod.trim() ==6 || TrackingPeriod.trim() ==9 || TrackingPeriod.trim() ==12)){
          alertmsg+="Tracking Period should be 3,6,9 or 12 Months  \r\n";
        }
        let EdmcMember=TrackingEffectivenessEDMCMember.split(";");
        let isvalEdmcMember=true;
        let isvalDmcMember=true;
        if(TrackingEffectivenessEDMCMember.trim().toString()=="" || TrackingEffectivenessEDMCMember.trim().toString()==null ){
          alertmsg+="Please Enter EDMC Member responsible for tracking effectiveness  \r\n";
        }
        else{
     
          for (let i = 0; i < EdmcMember.length; i++) {
           
           if(EdmcMember[i].trim()!=""){
           if(!(EdmcMember[i].match(mailformat))){
             isvalEdmcMember=false;     
           }
         }else{
          isvalEdmcMember=false; 
         }
       }
     }
     let DmcMember=TrackingDMCMember.split(";");
        if(TrackingDMCMember.trim().toString()=="" || TrackingDMCMember.trim().toString()==null ){
          alertmsg+="Please Enter DMC Member responsible for effectiveness \r\n";
        }
        else{
          for (let i = 0; i < DmcMember.length; i++) {
            if(DmcMember[i].trim()!=""){
            if(!(DmcMember[i].match(mailformat))){
             isvalDmcMember=false; 
            }
       
       }
       else{  isvalDmcMember=false; }
         }
        }
       
    if(isvalEdmcMember==false){
    alertmsg+="Please Enter Valid EmailId of EDMC Member  \r\n";
    }
       
    
       
      if(isvalDmcMember==false){
        alertmsg+="Please Enter Valid EmailId of DMC Member  \r\n";
        }
    
        if(alertmsg.trim() !=""){
          alert(alertmsg);
          return;
        }else{
          let validemail=CheckValidEDMCEmail().then(
            (response) => {           
        
              if(response.toString()!=""){
                alert(response.toString());
                return;
              } 
              else{navigate("/feedback")}
             
            }
          );
          
        }
      }

      
  function toggle(e) {

    const e1 = e.target;

    
    setProcessChangeImpact(e1.value);

    if (e1.value == "Yes") {
      document.getElementById('dtProcessUpdationDate').disabled = false;
      document.getElementById('txt').disabled = true;
      document.getElementById('txt').value = "";
    }

    else {
      document.getElementById('dtProcessUpdationDate').disabled = true;
      document.getElementById('txt').disabled = false;
      document.getElementById('yesno').value = "";
    }

  }

        var s = (new Date(ProcessUpdationDate)).toString().split(' ');
        var tosavedate=  [s[2],s[1],s[3]].join('-');        
    
      sessionStorage.setItem("keycorrectiveaction", CorrectiveAction)
      sessionStorage.setItem("keypreventiveaction", PreventiveAction)
      sessionStorage.setItem("keyProcessUpdationDate", ProcessUpdationDate)
      sessionStorage.setItem("keyJustification", Justification)
      sessionStorage.setItem("keyTrackingPeriod", TrackingPeriod)
      sessionStorage.setItem("keyTrackingEffectivenessEDMCMember", TrackingEffectivenessEDMCMember)
      sessionStorage.setItem("keyTrackingDMCMember", TrackingDMCMember)
    
      sessionStorage.setItem("keyProcessChangeImpact", ProcessChangeImpact)


    return (
      <Container className="analysis">
      {ChangeAnalysisDetails.map((Cidata, index) =>
        <Form className="form" >  {/*  //onSubmit={handleSubmit} */}
       <div>
           
         
           <h5>Reason For Change (Applicable Analytical Tools can be attached) </h5> (Size per file upto 5 MB max (max 5 files allowed), allowed file types are .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg)
       <hr />
        {/* disabled={(Cidata.PendingWithInitiator==="Yes" && Cidata.ChangeInitiationOwner===username) ? false : true} */}
       <Row id="Dropzone" disabled={(Cidata.IsRejected==="Yes" && Cidata.ChangeInitiationOwner===username) ? false : true}>
                    <Row>

<div>
    <input
    id="attachmentbtn"
        type="file"             
        title="Select or Drag Files here"
        onChange={onFileDrop}
        disabled={fileList.length >= 5 ? true : false}

    />
</div>
</Row><br></br> 
<Row>
<div>
    {fileList.length > 0 ? (
        <div className="files-list">
            {fileList.map((item, index) => (
                <div className="files-list-map" key={index}>
                    <FileEarmarkText />
                    <span className="files-note">{item.name}</span>
                    <Trash
                        className="files-trash-icon"
                        onClick={() => fileRemove(item)}
                    />
                </div>
            ))}
        </div>
    ) : null}
</div>
</Row>
<br></br>
                
                  
                    </Row>
          <Row id="attachmentrow" >                      
                   </Row>
                
   <br></br>
        <h5 class="required-field" > Corrective Action</h5>
        <hr/>
        
        <textarea 
         name="paragraph_text" value={CorrectiveAction} disabled={ChangeInitiationOwner==username ? false : true} cols="136" rows="5" onChange={(e) => setCorrectiveAction(e.target.value)} ></textarea>
        
        <br/>
        <br />
        <h5 class="required-field"> Preventive Action </h5>
        <hr/>
        <textarea  value={PreventiveAction} name="paragraph_text" disabled={ChangeInitiationOwner==username ? false : true} cols="136" rows="5" onChange={(e) => setPreventiveAction(e.target.value)} ></textarea>
        <br/>
        <br />
        <hr/>
        <h5>Process Change</h5>
        <hr/>
        <div class="row">
                  <div class="col-3">
                    <div className="control">
                    <label for="yesno" class="required-field"> Will Process address the issue ? </label>                      
                    </div>
                  </div>
                  <div class="col-1 mb-2">
                    <div className="control">                      
                    <select name="yesno" disabled={ChangeInitiationOwner==username ? false : true} value={ProcessChangeImpact} id="yesno" onChange={toggle}>
<option value="No">No</option>
<option value="Yes">Yes</option>
<option value="NA">NA</option>
</select></div>
                  </div>
                </div>
<br/>
                <div class="row">
                  <div class="col-4">
                    <div className="control">
                    <label for="yesno">If Yes, by when will Process be updated ? </label>                      
                    </div>
                  </div>
                  <div class="col-2 mb-8">
                    <div className="control">                      
                    <input id="dtProcessUpdationDate" 
       class="input input--bravo pad-1/2 span-1x field-dtProcessUpdationDate is-invalid" 
       data-palm="span-1x" 
       placeholder="dd/mm/yyyy" 
       
       required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" 
       name="payload[dtProcessUpdationDate]" 
       type="date" _flx1_12_1="1" _flx1_13_1="1"
       value={ProcessUpdationDate} disabled={ ChangeInitiationOwner==username && ProcessChangeImpact=="Yes" ? false : true} onChange={(e) => setProcessUpdationDate(e.target.value)} />
                    </div>
                  </div>
                </div>
<br/>
                <div class="row">
                  <div class="col-4">                    
                    <label for="yesno">If No or NA, Kindly annex justification note </label> 
                  </div>
                  <div class="col-1 mb-2">
                    <textarea id="txt" value={Justification} disabled={ChangeInitiationOwner==username && ProcessChangeImpact!="Yes" ? false : true} name="paragraph_text" cols="90" rows="3" onChange={(e) => setJustification(e.target.value)}></textarea>
                  </div>
                </div>
                <br/>
        <br />
        <hr/>
        <h5>Effectiveness Of Preventive Action :</h5>
        <hr/>
        <div class="row justify-content-between">
                  <div class="col">
                    <div className="control">
                      <label id="crnumber" class="required-field"> Period for which to be tracked (min 3, 6, 9, 12 months) :</label>                      
                    </div>
                  </div>
                  <div class="col-8">
                    <div className="control">                      
                      <input type="number" value={TrackingPeriod} disabled={ChangeInitiationOwner==username ? false : true} onChange={(e)=>setTrackingPeriod(e.target.value)} ></input>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="col">
                    <div className="control">
                      <label id="crnumber" class="required-field"> EDMC Member responsible for tracking effectiveness:</label>                      
                    </div>
                  </div>
                  <div class="col-8">
                    <div className="control">                      
                      <input type="email" value={TrackingEffectivenessEDMCMember} disabled={ChangeInitiationOwner==username ? false : true} placeholder="user@domain.com" onChange={(e)=>setTrackingEffectivenessEDMCMember(e.target.value)}></input>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="col">
                    <div className="control">
                      <label id="crnumber" class="required-field"> DMC Member responsible for effectiveness :</label>                      
                    </div>
                  </div>
                  <div class="col-8">
                    <div className="control">
                      
                      <input type="email" value={TrackingDMCMember} disabled={ChangeInitiationOwner==username ? false : true} placeholder="user@domain.com" onChange={(e)=>setTrackingDMCMember(e.target.value)} ></input>
                    </div>
                  </div>
                </div>
                <hr/>
                <br />
                <div>

                <Button class="btn-primary"
                
                  size="sm"
                  style={{ float: "right" }}
                  onClick={chkValidation}
                  
                >

                  Next
                </Button>
                <Button class="btn-primary"
                
                  size="sm"
                  style={{ float: "left" }}
                  onClick={() => { 
                   navigate("/ChangeAnalysisForm")
                  }}
                  
                >
                  Back
                </Button>
                </div>
                

       </div>
  
       </Form>
            )}
        </Container> )
  }
  
  
  
  // <Uploader />
export default Uploader











    
