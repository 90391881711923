//this is Master code

export const msalConfig = {
    auth: {
      clientId: "d6c02df8-9d3e-4ad5-8055-22bcb1808a81",
      authority: "https://login.microsoftonline.com/d44ff723-4fa7-405e-afc0-43c21e573043", //"Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})

      redirectUri: "https://www.changemanagementgc.godrejenterprises.com",  // "http://localhost:3000/",

    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  // export const graphConfig = {
  //     graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  // };