import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "changeanalysis"
//const axios = require("axios");

export async function getAll() {
  
  return fetch(API_URL).then((response) => response.json());
}

export async function getChangeAnalysisNumber() {
  return fetch(API_URL).then((response) => response.json());
}

export const addChangeAnalysis = async (ChangeAnalysis) => {
  
  console.log(JSON.stringify(ChangeAnalysis));

  const resp = await axios
    .post(API_URL, JSON.stringify(ChangeAnalysis), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });

  console.log(resp);

  return resp;
};

export const UpdateChangeAnalysis = async (ChangeAnalysisdata) => {
  const ProjectNum=ChangeAnalysisdata.ProjectNumber;
  const ChangeAnalysisNumber=ChangeAnalysisdata.ChangeAnalysisNumber;

  let url = `${API_URL}/ProjectNum/${ProjectNum}/ChangeAnalysisNumber/${ChangeAnalysisNumber}`;
  const resp = await axios
    .post(url, JSON.stringify(ChangeAnalysisdata), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });

  console.log(resp);

  return resp;
  
}


export async function getEmployee(ProjectNumber, ChangeAnalysisNumber) {
  let url = `${API_URL}/ProjectNumber/${ProjectNumber}/ChangeAnalysisNumber/${ChangeAnalysisNumber}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
        
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function deleteEmployee(proj, ChangeAnalysisNumber)
{
  //console.log("deleteEmployee");
  //console.log(ProjectNumber, ChangeAnalysisNumber);
  let url = `${API_URL}/proj/${proj}/ChangeAnalysisNumber/${ChangeAnalysisNumber}`;
  const resp = await axios
  .post(url)
  .then(function (response) {
    console.log(response);
    return response;
  })
  .catch(function (error) {
    console.log(error);
  });
console.log(resp);
return resp;
}

export async function getInitation1(ChangeInitiationNumber) {
  let url = `${API_URL}/ChangeInitiationNumber/${ChangeInitiationNumber}`;
  return fetch(url).then((response) => response.json());
}

export async function getInitation2(ChangeAnalysisNumber) {
  let url = `${API_URL}/ChangeAnalysisNumber/${ChangeAnalysisNumber}`;
  return fetch(url).then((response) => response.json());
}

export const EnrollNewEmployee = async (Employee) => {
  console.log(JSON.stringify(Employee));

  const resp = await axios
    .post(API_URL, JSON.stringify(Employee), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};


