import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "OtherDivisions";// "http://localhost:5009/OtherDivisions";//process.env.REACT_APP_OTHERDIVISIONS_API_URL;
//const axios = require("axios");

export async function getOtherDiv() {
    console.log("Under getOtherDiv");
    console.log("Other_API_URL");
    console.log(API_URL);
    return fetch(API_URL).then((response) => response.json());    
  }
  
  export async function getOtherDivEmail(division) {
    if(division!=""){
    let url = `${API_URL}/division/${division}`;    
    return fetch(url).then((response) => response.json());
    }
    else{
      return({});
    }
  }