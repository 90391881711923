import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "FinanceMaster"; //process.env.REACT_APP_FINANCE_API_URL;
//const axios = require("axios");

export async function getFinanceTeam() {
  return fetch(API_URL).then((response) => response.json());
}

export async function getFinance(FTeam, FEmail) {
  let url = `${API_URL}/FTeam/${FTeam}/FEmail/${FEmail}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function deleteFinance(FTeam, FEmail) {
  //console.log("deleteEmployee");
  //console.log(PNDAMember, PNDAMemberEmail);
  let url = `${API_URL}/fTeam/${FTeam}/FEmail/${FEmail}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export const EnrollNewFinance = async (Finance) => {
  console.log(JSON.stringify(Finance));

  const resp = await axios
    .post(API_URL, JSON.stringify(Finance), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};

export async function getFinanceEmail(Fname) {
  let url = `${API_URL}/Fname/${Fname}`;  
  return fetch(url).then((response) => response.json());
 
}


