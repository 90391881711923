import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "WorkType" ;//process.env.REACT_APP_WORKTYPE_API_URL;
//const axios = require("axios");

export async function getWorktype() {
 
  return fetch(API_URL).then((response) => response.json());
}


