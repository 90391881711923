/* 
Program Name : ChangeAnalysisNewForm.jsx
Written By: Mayur Solanki
Written on: 12 Mar 2022 
System Name: Change Management App
Purpose:  Screen to Add change analysis
Type of Program: React Frontend
*/
import {
  getAll,
  getInitation,
  getChangeInitiationForAnalysis,

} from "../services/ca1-service";
import {
  getChangeAnalysisNumber,
  getInitation1


} from "../services/ca-service";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Placeholder, Row, Stack } from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/js/dist/dropdown';
// import S3FileUpload from 'react-s3';
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";







function ChangeAnalysisNewForm() {


  // window.location.reload(false);
  const navigate = useNavigate();
  const [Chngrenumber, setChngrenumber] = useState([]);

  
  
  var username =localStorage.getItem('UserName');
  var userEmail = localStorage.getItem('UserId');
  

  

  const fetchdatafromdiftable = async (variee) => {


    const resulttre = await getInitation(variee)
    

    var ChangeInitiationOwner = resulttre.map(t => t.ChangeInitiationOwner).toString();
    
    sessionStorage.setItem('toNewSubmitChangeInitiationOwner', ChangeInitiationOwner);

    var DMCMember = resulttre.map(t => t.DMCMember).toString();
    var DMCMemberEmail = resulttre.map(t => t.DMCMemberEmail).toString();
    
    sessionStorage.setItem('toNewSubmitDmcMember', DMCMember);
    sessionStorage.setItem('toNewSubmitDmcMemberEmail', DMCMemberEmail);

    var DMCMemberOwner = resulttre.map(t => t.DMCMemberOwner).toString();
    
    sessionStorage.setItem('toNewSubmitDMCMemberOwner', DMCMemberOwner);
    

    const result1 = await getInitation1(variee);
    
    
    
    var AnalysisProvidedBy = result1.map(t => t.AnalysisProvidedBy).toString();
    
    let seeti1 = AnalysisProvidedBy.substring(0, AnalysisProvidedBy.indexOf(","));
    
    // sessionStorage.setItem('tofeedbackAnalysisProvidedBy1',seeti1 || AnalysisProvidedBy );
    
    var DMCAcceptanceBy = result1.map(t => t.DMCAcceptanceBy).toString();
    
    let seeti2 = DMCAcceptanceBy.substring(0, DMCAcceptanceBy.indexOf(","));
    
    sessionStorage.setItem('tofeedbackDMCAcceptanceBy1',seeti2 || DMCAcceptanceBy );

    var QAAcceptanceBy = result1.map(t => t.QAAcceptanceBy).toString();
    
    let seeti3 = QAAcceptanceBy.substring(0, QAAcceptanceBy.indexOf(","));
    
    sessionStorage.setItem('tofeedbackQAAcceptanceBy1',seeti3 || QAAcceptanceBy );

    var PDAAcceptanceBy = result1.map(t => t.PDAAcceptanceBy).toString();
    
    let seeti4 = PDAAcceptanceBy.substring(0, PDAAcceptanceBy.indexOf(","));
    
    sessionStorage.setItem('tofeedbackPDAAcceptanceBy1',seeti4 || PDAAcceptanceBy);

    var AnalysisAcceptedBy = result1.map(t => t.AnalysisAcceptedBy).toString();
    
    let seeti5 = AnalysisAcceptedBy.substring(0, AnalysisAcceptedBy.indexOf(","));
    
    sessionStorage.setItem('tofeedbackAnalysisAcceptedBy1',seeti5 || AnalysisAcceptedBy);
  }

 
 


  const [ChangeRequestOptions, setChangeRequestOptions] = useState([]);
  const [ChangeAnalysisNumber, setChangeAnalysisNumber] = useState("");
  const [ChangeInitiationOwner, setChangeInitiationOwner] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [ProjectNumber, setProjectNumber] = useState("");
  const [ProjectOwner, setProjectOwner] = useState("");
  const [DMCMemberOwner, setDMCMemberOwner] = useState("");
  const [WorkType, setWorkType] = useState("");
  const [ChangeRequestDate, setChangeRequestDate] = useState("");
  const [ChangeAnalysisDate, setChangeAnalysisDate] = useState("");

  

  const [ImpactTime, setImpactTime] = useState("");
  const [ImpactCost, setImpactCost] = useState("");
  const [ImpactQuality, setImpactQuality] = useState("");
  const [ImpactOthers, setImpactOthers] = useState("");
  const [ImpactSafety, setImpactSafety] = useState("");
  const [ImpactContract, setImpactContract] = useState("");
  const [ImpactCompliance, setImpactCompliance] = useState("");

  
  const [ImpactTimeInd, setImpactTimeInd] = useState("No");
  const [ImpactCostInd, setImpactCostInd] = useState("No");
  const [ImpactQualityInd, setImpactQualityInd] = useState("No");
  const [ImpactOthersInd, setImpactOthersInd] = useState("No");
  const [ImpactSafetyInd, setImpactSafetyInd] = useState("No");
  const [ImpactContractInd, setImpactContractInd] = useState("No");
  const [ImpactComplianceInd, setImpactComplianceInd] = useState("No");

  const [employees, setEmployee] = useState(null);
  useEffect(() => {
    fetchChangeRequest(); fetchEmployees(); ChangeInitiationData(); fetchDmcDate(); fetchEmployees1(); fetchChangeAnalysisNumber();
  }, []);

  var postback1 = sessionStorage.getItem("postbackimpacttime")  
  var postback2 = sessionStorage.getItem("postbackimpactcost")
  var postback3 = sessionStorage.getItem("postbackimpactquality")
  var postback4 = sessionStorage.getItem("postbackimpactothers")
  var postback5 = sessionStorage.getItem("postbackimpactsafety")
  var postback6 = sessionStorage.getItem("postbackimpactcontract")
  var postback7 = sessionStorage.getItem("postbackimpactcompliance")
  var postbackImpactTimeInd = sessionStorage.getItem("postbackImpactTimeInd")
  var postbackImpactCostInd = sessionStorage.getItem("postbackImpactCostInd")
  var postbackImpactQualityInd = sessionStorage.getItem("postbackImpactQualityInd")
  var postbackImpactOthersInd = sessionStorage.getItem("postbackImpactOthersInd")
  var postbackImpactSafetyInd = sessionStorage.getItem("postbackImpactSafetyInd")
  var postbackImpactContractInd = sessionStorage.getItem("postbackImpactContractInd")
  var postbackImpactComplianceInd = sessionStorage.getItem("postbackImpactComplianceInd")
  var postback8 = sessionStorage.getItem("postbackcrn1")
  var postback9 = sessionStorage.getItem("toNewSubmitChangeInitiationOwner")
  var postback10 = sessionStorage.getItem("postbackwt1")
  var postback11 = sessionStorage.getItem("postbackpn1")
  var postback12 = sessionStorage.getItem("postbackpnum1")
  var postback13 = sessionStorage.getItem("postbackpo1")
  var postback14 = sessionStorage.getItem("toNewSubmitDMCMemberOwner")
  var postback15 = sessionStorage.getItem("postsbackcrd1")

  var CorrectiveAction = sessionStorage.getItem('keycorrectiveaction');
  
  sessionStorage.setItem("postbackcorrectiveaction",CorrectiveAction)
  var PreventiveAction = sessionStorage.getItem('keypreventiveaction');
  
  sessionStorage.setItem("postbackpreventiveaction",PreventiveAction)
  var ProcessChangeImpact = sessionStorage.getItem('keyProcessChangeImpact');
  
  sessionStorage.setItem("postbackProcessChangeImpact",ProcessChangeImpact)
  var ProcessUpdationDate = sessionStorage.getItem('keyProcessUpdationDate');
  
  sessionStorage.setItem("postbackProcessUpdationDate",ProcessUpdationDate)
  var Justification = sessionStorage.getItem('keyJustification');
  
  sessionStorage.setItem("postbackJustification",Justification)
  var TrackingPeriod = sessionStorage.getItem('keyTrackingPeriod')
  
  sessionStorage.setItem("postbackTrackingPeriod", TrackingPeriod)
  var TrackingEffectivenessEDMCMember = sessionStorage.getItem('keyTrackingEffectivenessEDMCMember')
  
  sessionStorage.setItem("postbackTrackingEffectivenessEDMCMember",TrackingEffectivenessEDMCMember)
  var TrackingDMCMember = sessionStorage.getItem('keyTrackingDMCMember')
  
  sessionStorage.setItem("postbackTrackingDMCMember", TrackingDMCMember);

  if(postback1==null){
    postback1="";
  }
  if(postbackImpactTimeInd==null){postbackImpactTimeInd="No"}
  if(postbackImpactCostInd==null){postbackImpactCostInd="No"}
  if(postbackImpactQualityInd==null){postbackImpactQualityInd="No"}  
  if(postbackImpactOthersInd==null){postbackImpactOthersInd="No"}
  if(postbackImpactSafetyInd==null){postbackImpactSafetyInd="No"}
  if(postbackImpactContractInd==null){postbackImpactContractInd="No"}
  if(postbackImpactComplianceInd==null){postbackImpactComplianceInd="No"}
  if(postback2==null){postback2=""}
  if(postback3==null){postback3=""}
  if(postback4==null){postback4=""}
  if(postback5==null){postback5=""}
  if(postback6==null){postback6=""}
  if(postback7==null){postback7=""}
  if(postback8==null){postback8=""}
  if(postback9==null){postback9=""}
  if(postback10==null){postback10=""}
  if(postback11==null){postback11=""}
  if(postback12==null){postback12=""}
  if(postback13==null){postback13=""}
  if(postback14==null){postback14=""}
  if(postback15==null){postback15=""}  
  const fetchChangeRequest = async () => {  
          const result = await getChangeInitiationForAnalysis(userEmail);
        
      setChangeRequestOptions(result);
      setImpactTime(postback1);
      setImpactTimeInd(postbackImpactTimeInd);
      setImpactCostInd(postbackImpactCostInd);      
      setImpactQualityInd(postbackImpactQualityInd);
      setImpactOthersInd(postbackImpactOthersInd);      
      setImpactSafetyInd(postbackImpactSafetyInd);
      setImpactContractInd(postbackImpactContractInd);      
      setImpactComplianceInd(postbackImpactComplianceInd);      
      setImpactCost(postback2);
      setImpactQuality(postback3);
      setImpactOthers(postback4);
      setImpactSafety(postback5);
      setImpactContract(postback6);
      setImpactCompliance(postback7);
      setChngrenumber(postback8);
      setChangeInitiationOwner(postback9);
      setWorkType(postback10);
      setProjectName(postback11);
      setProjectNumber(postback12);
      setProjectOwner(postback13);
      setDMCMemberOwner(postback14);
      setChangeRequestDate(postback15);      




     

  };

  




  // const chkValidation  = (value) => {
    function chkValidation(e) {  
    let alertmsg="";    
    if(Chngrenumber.trim().toString()=="" ||Chngrenumber.trim().toString()== null ){
      alertmsg+="Please Select Change Request Number";
    }
    if(ImpactTimeInd.trim().toUpperCase()=="YES" && (ImpactTime.trim().toString()=="" || ImpactTime.trim().toString()==null )){
      alertmsg+="Please Enter Time  \r\n";
    }
    if(ImpactCostInd.trim().toUpperCase()=="YES" && (ImpactCost.trim().toString()=="" || ImpactCost.trim().toString()==null )){
      alertmsg+="Please Enter Cost  \r\n";
    }
    if(ImpactQualityInd.trim().toUpperCase()=="YES" && (ImpactQuality.trim().toString()=="" || ImpactQuality.trim().toString()==null )){
      alertmsg+="Please Enter Quality  \r\n";
    }
    if(ImpactOthersInd.trim().toUpperCase()=="YES" && (ImpactOthers.trim().toString()=="" || ImpactOthers.trim().toString()==null )){
      alertmsg+="Please Enter Others  \r\n";
    }
    if(ImpactSafetyInd.trim().toUpperCase()=="YES" && (ImpactSafety.trim().toString()=="" || ImpactSafety.trim().toString()==null )){
      alertmsg+="Please Enter Safety  \r\n";
    }
    if(ImpactContractInd.trim().toUpperCase()=="YES" && (ImpactContract.trim().toString()=="" || ImpactContract.trim().toString()==null )){
      alertmsg+="Please Enter Contract  \r\n";
    }
    if(ImpactComplianceInd.trim().toUpperCase()=="YES" && (ImpactCompliance.trim().toString()=="" || ImpactCompliance.trim().toString()==null )){
      alertmsg+="Please Enter Compliance  \r\n";
    }
    if(alertmsg.trim() !=""){
      alert(alertmsg);
      return;
    }else(
      navigate("/ChangeAnalysisUploader2")
    )
  }

  const ChangeInitiationData = (value) => {

    

    

    ChangeRequestOptions.forEach((element) => {

      
      

      if (element.ChangeRequestNumber === value) {
          
        
      
        setWorkType(element.WorkType);

        setProjectName(element.ProjectName);

        setProjectNumber(element.ProjectNumber);

        setProjectOwner(element.ProjectOwner);

        setDMCMemberOwner(element.DMCMemberOwner);




        setChangeRequestDate(element.ChangeRequestDate);

        setChangeInitiationOwner(element.ChangeInitiationOwner);

        fetchdatafromdiftable(element.ChangeRequestNumber)

        
      
        return;

      }

    })

  }


  const fetchEmployees1 = async () => {
    
    
    

    
  };


  


  const fetchDmcDate = async () => {

    const today = new Date();

    const yyyy = today.getFullYear();

    let mm = today.getMonth() + 1; // Months start at 0!

    let dd = today.getDate();



    if (dd < 10) dd = '0' + dd;

    if (mm < 10) mm = '0' + mm;



    const todaysdate = dd + '/' + mm + '/' + yyyy;






    setChangeAnalysisDate(todaysdate);

};



  const fetchEmployees = async () => {

    const result = await getInitation();

    
    setEmployee(result);

     
       

  };


  const fetchChangeAnalysisNumber = async () => {

    const resultchangeanalysisnumber = await getChangeAnalysisNumber();

    
    let canumbers = resultchangeanalysisnumber.map(t => (t.ChangeAnalysisNumber).split("-").pop());

      

      // let arracan = [];
      // for(let i=0; i<canumbers.length; i++){
      //   arracan.push(canumbers[i].split("-").pop());

      // }

       let MaxRevNo = Math.max.apply(null, canumbers);
       if (!(isFinite(MaxRevNo))) {
        MaxRevNo=0;
       }

      setChangeAnalysisNumber(MaxRevNo + 1);

   
  }













  function toggle(e) {    
    const e1 = e.target;
    
    setImpactTimeInd(e1.value);
        if (e1.value == "Yes") {
          
      document.getElementById('time').disabled = false;
    }
    else {
      document.getElementById("time").disabled = true;
      document.getElementById("time").value = "";
      setImpactTime("");
    }
  }

  function togglecost(e) {
    const e1 = e.target;
    
    setImpactCostInd(e1.value);
    if (e1.value == "Yes") {
      document.getElementById('cost').disabled = false;
    }
    else {
      document.getElementById("cost").disabled = true;
      document.getElementById("cost").value = "";
      setImpactCost("");
    }


  }

  function togglequality(e) {
    const e1 = e.target;
    
    setImpactQualityInd(e1.value);
    if (e1.value == "Yes") {
      document.getElementById('quality').disabled = false;
    }
    else {
      document.getElementById("quality").disabled = true;
      document.getElementById("quality").value = "";
      setImpactQuality("");
    }


  }

  function toggleothers(e) {
    const e1 = e.target;
    
    setImpactOthersInd(e1.value);
    if (e1.value == "Yes") {
      document.getElementById('others').disabled = false;
    }
    else {
      document.getElementById("others").disabled = true;
      document.getElementById("others").value = "";
      setImpactOthers("");
    }


  }

  function togglesafety(e) {
    const e1 = e.target;
    
    setImpactSafetyInd(e1.value);
    if (e1.value == "Yes") {
      document.getElementById('safety').disabled = false;
    }
    else {
      document.getElementById("safety").disabled = true;
      document.getElementById("safety").value = "";
      setImpactSafety("");
    }


  }

  function togglecontract(e) {
    const e1 = e.target;
    
    setImpactContractInd(e1.value);
    if (e1.value == "Yes") {
      document.getElementById('contract').disabled = false;
    }
    else {
      document.getElementById("contract").disabled = true;
      document.getElementById("contract").value = "";
      setImpactContract("");
    }


  }

  function togglecompliance(e) {
    const e1 = e.target;
    
    setImpactComplianceInd(e1.value);
    if (e1.value == "Yes") {
      document.getElementById('compliance').disabled = false;
    }
    else {
      document.getElementById("compliance").disabled = true;
      document.getElementById("compliance").value = "";
      setImpactCompliance("");
    }
  }

  sessionStorage.setItem("keyImpactTime", ImpactTime)  
  sessionStorage.setItem("keyImpactCost", ImpactCost)
  sessionStorage.setItem("keyImpactQuality", ImpactQuality)
  sessionStorage.setItem("keyImpactOthers", ImpactOthers)
  sessionStorage.setItem("keyImpactSafety", ImpactSafety)
  sessionStorage.setItem("keyImpactContract", ImpactContract)
  sessionStorage.setItem("keyImpactCompliance", ImpactCompliance)

  sessionStorage.setItem("keyImpactTimeInd", ImpactTimeInd)
  sessionStorage.setItem("keyImpactCostInd", ImpactCostInd)
  sessionStorage.setItem("keyImpactQualityInd", ImpactQualityInd)
  sessionStorage.setItem("keyImpactOthersInd", ImpactOthersInd)
  sessionStorage.setItem("keyImpactSafetyInd", ImpactSafetyInd)
  sessionStorage.setItem("keyImpactContractInd", ImpactContractInd)
  sessionStorage.setItem("keyImpactComplianceInd", ImpactComplianceInd)

  sessionStorage.setItem('keysesvarcrn1', Chngrenumber) ;
  sessionStorage.setItem('keysesvarcio1', ChangeInitiationOwner);
    sessionStorage.setItem('keysesvarwt1', WorkType); 
    sessionStorage.setItem('keysesvarpn1', ProjectName);
    sessionStorage.setItem('keysesvarpnum1', ProjectNumber );
    sessionStorage.setItem('keysesvarpo1', ProjectOwner);
    sessionStorage.setItem('keysesvardmo1', DMCMemberOwner);
    sessionStorage.setItem('keysesvarcrd1', ChangeRequestDate);
    sessionStorage.setItem('keycanum1', "CA-"+ChangeAnalysisNumber);
    sessionStorage.setItem('keycadate1', ChangeAnalysisDate);











    return (
      employees && (
        <body>
          <div >

            <Container className="LogbookForm">
              <h3>Change Analysis Form</h3>



              <hr />

              <form className="form">
                <div class="row justify-content-between">
                  {/* <div class="col-5 mb-2">
                      <div className="control">
                        <label id="crnumber">Change Request Number : </label>
                        <select type="text" value={valuecrn} onChange={(e) => { setChangeRequestNumber(e.target.value); getOutputs() }}></select>
                        {valuecrnOptions.map((item, index) => {

return <option key={index} value={item.valuecrn}>{item.valuecrn}
</option>
                      
</div>
</div> */}

                  <Form.Group className="mb-3">

                    <Form.Label class="required-field"> Change Request Number</Form.Label>

                    <Form.Select aria-label="Chngrenumber" name="Chngrenumber" id="Chngrenumber"  value={Chngrenumber} required onChange={(e) => { setChngrenumber(e.target.value); ChangeInitiationData(e.target.value); fetchEmployees1(); }} >
                    <option value="" disabled selected>Select your option</option>
                      {ChangeRequestOptions.map((item, index) => {

                        return <option value={item.ChangeRequestNumber}>{item.ChangeRequestNumber}</option>

                      })}







                    </Form.Select>

                  </Form.Group>





                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Initiator : </label>
                      <input className="inputText"

                        type="text"

                        required

                        // autoFocus="autofocus"

                        value={ChangeInitiationOwner}

                        onChange={(e) => setChangeInitiationOwner(e.target.value)}

                        disabled

                      />
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Change Analysis Number : </label>
                      <input type="text" value={"CA-"+ ChangeAnalysisNumber}
                       disabled></input>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Work Type : </label>
                      <input className="inputText"

                        type="text"

                        required

                        // autoFocus="autofocus"

                        value={WorkType}

                        onChange={(e) => setWorkType(e.target.value)}

                        disabled

                      />
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Initiative / Project Name : </label>
                      <input className="inputText"

                        type="text"

                        required

                        // autoFocus="autofocus"

                        value={ProjectName}

                        onChange={(e) => setProjectName(e.target.value)}

                        disabled

                      />
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Initiative / Project Number : </label>
                      <input className="inputText"

                        type="text"

                        required

                        // autoFocus="autofocus"

                        value={ProjectNumber}

                        onChange={(e) => setProjectNumber(e.target.value)}

                        disabled

                      />
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Initiative / Project Owner : </label>
                      <input className="inputText"

                        type="text"

                        required

                        // autoFocus="autofocus"

                        value={ProjectOwner}

                        onChange={(e) => setProjectOwner(e.target.value)}

                        disabled

                      />
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">DMC Member (Owner) : </label>
                      <input className="inputText"

type="text"

required

// autoFocus="autofocus"

value={DMCMemberOwner}

onChange={(e) => setDMCMemberOwner(e.target.value)}

disabled

/>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Change Request Date : </label>
                      <input className="inputText"

type="text"

required

// autoFocus="autofocus"

value={ChangeRequestDate}

onChange={(e) => setChangeRequestDate(e.target.value)}

disabled

/>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Change Analysis Date : </label>
                      <input className="inputText"

                        type="text"

                        required

                        // autoFocus="autofocus"

                        value={ChangeAnalysisDate}

                        onChange={(e) => setChangeAnalysisDate(e.target.value)}

                        disabled

                      />
                    </div>
                  </div>
                </div>
                <hr />
                <h5>Impact of Change</h5>
                <hr />

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Time : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">                           

                              <select name="yesno" id="yesnofortime" value={ImpactTimeInd} onChange={toggle}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                                <option value="NA">NA</option>

                              </select>
                            
                          </div>
                        </div>
                        <div class="col">
                          <input type="text" id="time"disabled value={ImpactTime} onChange={(e) => setImpactTime(e.target.value)} ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Cost : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                            
                              <select name="yesno" id="yesnoforcost" value={ImpactCostInd} onChange={togglecost}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                                <option value="NA">NA</option>

                              </select>
                            
                          </div>
                        </div>
                        <div class="col">
                          <input type="text" id="cost" disabled
                            value={ImpactCost} onChange={(e) => setImpactCost(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Quality : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                            

                              <select name="yesno" id="yesnoforquality" value={ImpactQualityInd}  onChange={togglequality}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                                <option value="NA">NA</option>

                              </select>
                            
                          </div>
                        </div>
                        <div class="col">
                          <input type="text" id="quality" disabled
                            value={ImpactQuality} onChange={(e) => setImpactQuality(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Others : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                          
                              <select name="yesno" id="yesnoforothers" value={ImpactOthersInd} onChange={toggleothers}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                                <option value="NA">NA</option>

                              </select>
                            
                          </div>
                        </div>
                        <div class="col">
                          <input type="text" id="others" disabled
                            value={ImpactOthers} onChange={(e) => setImpactOthers(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Safety : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                                <select name="yesno" id="yesnoforsafety" value={ImpactSafetyInd} onChange={togglesafety}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                                <option value="NA">NA</option>

                              </select>
                            
                          </div>
                        </div>
                        <div class="col">
                          <input type="text" id="safety" disabled
                            value={ImpactSafety} onChange={(e) => setImpactSafety(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Contract : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                            
                              <select name="yesno" id="yesnoforcontract"  value={ImpactContractInd}  onChange={togglecontract}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                                <option value="NA">NA</option>

                              </select>
                            
                          </div>
                        </div>
                        <div class="col">
                          <input type="text" id="contract" disabled
                            value={ImpactContract} onChange={(e) => setImpactContract(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Compliance : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                           
                              <select name="yesno" id="yesnoforcompliance"  value={ImpactComplianceInd} onChange={togglecompliance}>
                                <option value="No">No</option>
                                <option value="Yes">Yes</option>
                                <option value="NA">NA</option>

                              </select>
                            
                          </div>
                        </div>
                        <div class="col">
                          <input type="text" id="compliance" disabled
                            value={ImpactCompliance} onChange={(e) => setImpactCompliance(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <hr />
                <br />
                <Button class="btn-primary"
                  size="sm"
                  style={{ float: "right" }}
                  onClick={chkValidation}                
                >
                  Next
                </Button>
              </form>
            </Container>
          </div>
          
        </body>





      )



    );





  
}

export default ChangeAnalysisNewForm;

