/* 
Program Name : FinanceEnrollment.jsx
Written By: Snehal Kurhade
Written on: 18 MAR 2022 
System Name: Change Management App
Purpose:  Screen to Add details of Finance Members
Type of Program: React Frontend
*/
import React from 'react';
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  EnrollNewFinance,
 
} from "../services/finance-service";

const Enrollment = () => {
  const navigate = useNavigate();
  const [FTeam, setFTeam] = useState("");
  const [FEmail, setFEmail] = useState("");
  const [FName, setFName] = useState("");  

  const [isPending, setIsPending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    const Finance = {
      FTeam,
      FEmail,
      FName,     
    
    };
    console.log(Finance);

    EnrollNewFinance(Finance)
      .then((resp) => {
        //console.log(resp.data);
        if (resp.data.status) {
          console.log(resp.data.Message);
          setIsPending(false);
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*const checkEmployeeCode = (PNDAMember) => {
   getEmployeeByCode(Number(PNDAMember.value.trim()))
      .then((resp) => {
        if (resp.data.length > 0) {
          setError(PNDAMember, "Employee Already Exists");
        } else {
          setSuccess(PNDAMember);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };*/

  return (
    <div className="create">
      <h2>Add New Data in Finance Master Table</h2>
      <form className="form">
        <div className="control">
          <label>Team : </label>
          <input
            type="text"
            required
            value={FTeam}
            onChange={(e) => setFTeam(e.target.value)}
          />
         
        </div>
        <div className="control">
          <label>Finance Member Email : </label>
          <input
            type="text"
            required
            value={FEmail}
            onChange={(e) => setFEmail(e.target.value)}
          />
        </div>

        <div className="control">
          <label>Finance Member Name : </label>
          <input
            type="text"
            required
            value={FName}
            onChange={(e) => setFName(e.target.value)}
          />
        </div>


        
        
        <Button
          variant="outline-info"
          disabled={isPending}
          onClick={!isPending ? handleSubmit : null}
        >
          {isPending ? "Loading…" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default Enrollment;
