
  import React, { useState, useEffect } from "react";
  import BootstrapTable from "react-bootstrap-table-next";
  import { Button } from "react-bootstrap";
  
  function FeasibilityBrowseScreenDetails() {
   
  
    return (
      
        <div >
         This Is BrowswScreen
        </div>
      
    );
  }
  
  export default FeasibilityBrowseScreenDetails;
  