import axios from "axios";
const API_URL =  process.env.REACT_APP_CHANGEINITIATION_API_URL + "WorkPackage"; //"http://localhost:5009/WorkPackage";//process.env.REACT_APP_WORKPACKAGE_API_URL;
//const axios = require("axios");


export async function getWorkPackage() {
 
    return fetch(API_URL).then((response) => response.json());
}





