/* 
Program Name : SectionMasterDetails.jsx
Written By: Snehal Kurhade
Written on: 20 APR 2022 
System Name: Change Management App
Purpose:  Screen to Display details of Section Master
Type of Program: React Frontend
*/
import {
    deleteSection,
    getAll,
    
  } from "../services/SectionMaster-service";
  import React, { useState, useEffect } from "react";
  import BootstrapTable from "react-bootstrap-table-next";
  import { Button } from "react-bootstrap";
  
  function SectionMasterDetails() {
    const [SectionMaster, setSectionMaster] = useState(null);
    useEffect(() => {
      fetchSectionMaster();
    }, []);
  
    const fetchSectionMaster = async () => {
      const result = await getAll();
      //console.log(result);
      setSectionMaster(result);
    };
  
    const handleDelete = (row) => {
      
      let ans = window.confirm("Do you want to Delete Data?") ;
      if(ans){
        deleteSection(row.SMTeamName, row.SMEmailID)
        .then((resp) => {
          if (resp.data.status) {
            console.log(resp.data.Message);
            fetchSectionMaster();
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    };
  
    const deleteButton = (cell, row, rowIndex, formatExtraData) => {
      return (
        <Button
          size="sm"
          variant="outline-danger"
          onClick={() => {
            handleDelete(row);
          }}
        >
          Delete
        </Button>
      );
    };
  
    const columns = [
      {
        dataField: "SMTeamName",
        text: "SMTeamName",
        sort: true,
      },
      {
        dataField: "SMEmailID",
        text: "SMEmailID",
        sort: true,
      },
      {
        dataField: "SMName",
        text: "SMName",
        sort: true,
      },
     
     
      {
        dataField: "",
        text: "",
        formatter: deleteButton,
      },
    ];
  
    const defaultSorted = [
      {
        dataField: "",
        order: "asc",
      },
    ];
      
    return (
      SectionMaster && (
        <div >
          <BootstrapTable
            keyField="SMTeamName"
            data={SectionMaster}
            columns={columns}
            // loading={true}
            defaultSorted={defaultSorted}
          />
        </div>
      )
    );
  }
  
  export default SectionMasterDetails;
  