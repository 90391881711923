import axios from "axios";
const API_URL =  process.env.REACT_APP_CHANGEINITIATION_API_URL + "Division"; //"http://localhost:5009/Division";//process.env.REACT_APP_DIVISION_API_URL
//const axios = require("axios");

export async function getAllDivision() {
   return fetch(API_URL).then((response) => response.json());
}

export async function getEmpdetails(email) {
  let url = `${API_URL}/email/${email}`;
  console.log(url);
  return fetch(url).then((response) => response.json());
}
