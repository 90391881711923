import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Changefeasibility";
const API_URL1 = process.env.REACT_APP_CHANGEINITIATION_API_URL + "getfeasibility";// "http://localhost:5009/getfeasibility";
//const API_EDMC_URL = process.env.REACT_APP_EDMC_API_URL;
// const API_URL_EDMC=process.env.REACT_APP_EMPLOYEE_API_URL
//const axios = require("axios");

export async function getAll1() {  
  return fetch(API_URL1).then((response) => response.json());

}

export async function deleteFeasibility(ChangeRequest,Title) {
 
  let url = `${API_URL1}/ChangeRequest/${ChangeRequest}/Title/${Title}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });
  //console.log(resp);
  return resp;
}


export async function getFeasibilityRaised(Title) {
  let url1 =  `${API_URL1}/Title/${Title}`;
  return fetch(url1).then((response) => response.json());
}

export async function getEdmcDetails(EDMCemail) {
  let url = `${API_URL}/EDMCemail/${EDMCemail}`;
  console.log("Service")
  //console.log(url)
  return fetch(url).then((response) => response.json());
 
}

export async function getDetails(ChangeRequestNo, Title) {
  let url = `${API_URL}/ChangeRequestNo/${ChangeRequestNo}/Title/${Title}`;
  console.log(" getAll response");
  console.log(url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response)
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}
export async function getRequestDetails(WorkType, ProjectName, ProjectNumber,Division) {
    let url = `${API_URL}/WorkType/${WorkType}/ProjectName/${ProjectName}/ProjectNumber/${ProjectNumber}/Division/${Division}`;
    console.log("getProject-Service")
    console.log(url)
    return fetch(url).then((response) => response.json());
   
  }
 
  

export const SubmitDetails = async (Details) => {
  console.log(JSON.stringify(Details));
  

  const resp = await axios
    .post(API_URL1, JSON.stringify(Details), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};
export const getSectionMasterDivision = async (SMEmailID) => {
  let url1 =  `${API_URL1}/SectionMaster/${SMEmailID}`;
  const resp = await axios
  .get(url1)
  .then((response) =>  {return response})
  .catch((err) => {console.log(err); return null;})
  return resp;
}
 export const getChangeInitiationAndApproval = async (ChangeRequestNumber) => {
  let url1 =  `${API_URL1}/ChangeInitiationAndApproval/${ChangeRequestNumber}`;
  const resp = await axios
  .get(url1)
  .then( (response) => {return response;})
  .catch((err) => { console.log(err); return null;})
  return resp;
}

export async function checkPreviousRecord(ChangeRequestNumber,Division,User) {
  
  let url = `${API_URL1}/ChangeRequestNumber/${ChangeRequestNumber}/Division/${Division}/User/${User}`; 
  return fetch(url).then((response) => response.json()); 
}

// export const checkPreviousRecord = async (ChangeRequestNumber,Division,User) => {
//   const resp = await axios
//   .get(`http://localhost:5009/getfeasibility/ChangeRequestNumber/${ChangeRequestNumber}/Division/${Division}/User/${User}`)
//   .then( (response) => {return response;})
//   .catch((err) => { console.log(err); return null;})
//   return resp;
// }
  




