/* 
Program Name : ChangeFeasibilityDetailsEDMCApproval.jsx
Written By: Snehal Kurhade
Written on: 12 APR 2022 
System Name: Change Management App
Purpose:  Screen to display details of change feasibility pending with EDMC Members
Type of Program: React Frontend
*/
import {    
    getAll,
    getFeasibilityData,
  } from "../services/ChangeFeasibility-service";
  import React, { useState, useEffect, useRef, Component } from "react";
  import BootstrapTable from "react-bootstrap-table-next";
  import { Table, Button, Col, Container, Form, Row, InputGroup, FormControl, Stack } from "react-bootstrap";
  import { BrowserRouter as Router, Link, Route, Routes, useLinkClickHandler } from 'react-router-dom'
  import { useNavigate } from "react-router-dom";
  import ComboBox from 'react-responsive-combo-box'
  
  function ChangeFeasibilityDetailsEDMCApproval() {
    // let useremail = "snehalsk@godrej.com";
    // let username = "Snehal Kurhade";
    
  
  var username =localStorage.getItem('UserName');
  var useremail = localStorage.getItem('UserId');
    let query = window.location.href
    let indication = "";  
    indication = query.split("/").pop();
    const navigate = useNavigate();
    const searchInput = useRef('');
    
    const [ChangeFeasibility, setChangeFeasibility] = useState([]);
    const [FilteredRequest, setFilteredRequest] = useState("");
    const [InProgressedRequest, setInProgressedRequest] = useState("");
    const [ProcessedRequest, setProcessedRequest] = useState("");
    const [OnholdRequest, setOnholdRequest] = useState("");
    let abc="";
   
  
     const [ChangeInitionDate, setChangeInitionDate] = useState("");
     const [tempSort, settempSort] = useState(false);
     
     
    let filteredResult = [];
    useEffect(() => {
      fetchChangeFeasibility();
    }, []);
  
    const SortOrder = () =>{ 
      
     settempSort((tempSort) => !tempSort); 
     fetchChangeFeasibility();
   } ;
  
    const handleSearch = async (searchText) => {
      const result = await getAll();
      
      if (searchText && searchText.length > 0) {
        
        searchText = searchText.toUpperCase();
        filteredResult = result.filter((item) => (item.ChangeRequestNo.toUpperCase().indexOf(searchText) >= 0
          || item.ChangeRequestNo.toUpperCase() == searchText.toUpperCase()) && (item.FeasibilityEDMCMemberEmail == useremail))
          setChangeFeasibility(filteredResult)
      } else {
        fetchChangeFeasibility();
      }
      abc=ChangeFeasibility.length;
      setFilteredRequest(filteredResult.length);
      setInProgressedRequest((filteredResult.filter((item)  => (item.ProcessIndication = "Inprogress"))).length);
          setProcessedRequest((filteredResult.filter((item)  => (item.ProcessIndication = "SubmittedByProjectOwner"))).length);
          setOnholdRequest((filteredResult.filter((item)  => (item.ProcessIndication = "OnHold"))).length);
        
    }

    const fetchChangeFeasibility = async () => {
      document.getElementById("feasibilitydashboard").classList.remove('active');
      document.getElementById("FeasibilityRaised").classList.remove('active');
      document.getElementById("EDMCApproval").classList.add('active');
      document.getElementById("DMCApproval").classList.remove('active');
      document.getElementById("PDATeam").classList.remove('active');
      sessionStorage.setItem("clickedEvent","EDMCApproval");
      const result = await getAll(); 
          filteredResult = result.filter((item) => (item.FeasibilityEDMCMember == useremail || item.FeasibilityEDMCMemberEmail == useremail));

          if(tempSort==false){
            filteredResult.sort();     
          }
          else{
            filteredResult.reverse();
         
          }
          setChangeFeasibility(filteredResult);
         
      abc=ChangeFeasibility.length;
      setFilteredRequest(filteredResult.length);
      setInProgressedRequest((filteredResult.filter((item)  => (item.ProcessIndication = "Inprogress"))).length);
      setProcessedRequest((filteredResult.filter((item)  => (item.ProcessIndication = "SubmittedByProjectOwner"))).length);
      setOnholdRequest((filteredResult.filter((item)  => (item.ProcessIndication = "OnHold"))).length);
      if(filteredResult.length==0){
        alert("No Records Found");
        return;
      }
    };
    
   
    
  
    const handleView = (row) => {
      
      
      var ChangeReqNo = row.ChangeRequestNo;
      var CFRNo = row.Title;
      var EDMCdata12 = row.EDMCInputAcceptanceBy;
      var ProjectNamefordisplay = row.ProjectName;
      var ProjectNo = row.ProjectNumber;
      var DMCnamefordisplay = row.DMCInputAcceptanceBy;
      var Createdfordisplay = row.FeasibilityInputGivenBy;
      var RaisedOn = row.FeasibilityInputGivenOn;
      var Divisionfordisplay = row.Division;    
      var WorkTypefordisplay = row.WorkType;    
      var ChangeRequestNofordisplay = row.ChangeRequestNo; 

      sessionStorage.setItem('ChangeReqNo', ChangeReqNo);
      sessionStorage.setItem('CFRNo', CFRNo)
      sessionStorage.setItem('edmckey', EDMCdata12)
      sessionStorage.setItem('projectnamekey', ProjectNamefordisplay)
      sessionStorage.setItem('dmckey', DMCnamefordisplay)
      sessionStorage.setItem('Createdkey', Createdfordisplay)
      sessionStorage.setItem('divisionkey', Divisionfordisplay)
      sessionStorage.setItem('worktypekey', WorkTypefordisplay)
      sessionStorage.setItem('ChangeRequestNokey', ChangeRequestNofordisplay)
      sessionStorage.setItem('RaisedOn', RaisedOn)
      sessionStorage.setItem('ProjectNo', ProjectNo)
      sessionStorage.setItem('NavPath', "/EDMCApproval");
      navigate("/feasibilityraisedDetails");
    };
  
  
  
    return (  
      <Container className="LogbookForm">
       <Row>
        <h3> <span class="text-primary">Change Feasibility Dashboard </span></h3>
        </Row>
        <br></br>
        <Row>      
        <Col>
        <Form.Label> Filtered Requests : {FilteredRequest}</Form.Label><br></br>
        <Form.Label> In-Progress (Out of Filtered) : {InProgressedRequest}  </Form.Label><br></br>
        <Form.Label> Processed (Out of Filtered) : {ProcessedRequest}  </Form.Label><br></br>
        <Form.Label> On-Hold (Out of Filtered) : {OnholdRequest}  </Form.Label>
        </Col>
          <Col>
       <div>
    
        <div class="float-end m-1">
        <Button class=" ml-5 btn-primary"  style={{ float: "right" } }  size="sm"
        onClick={() => { navigate("/feasibilitydashboard") }} > Back </Button>
     </div>
         <div class="float-end m-1">
        <Button class=" ml-5 btn-primary"  style={{ float: "right" } }  size="sm"
       onClick= {SortOrder} > &#8645;</Button>
     </div>
     </div>

        <br></br>
        <br></br>
       
        </Col>
       
        </Row>
        <InputGroup className="mb-3">
          <InputGroup.Text id="search">Search</InputGroup.Text>
          <FormControl ref={searchInput}
            onChange={() => handleSearch(searchInput.current.value)}
            placeholder="Search by Change Request Number"
            aria-label="Search"
            aria-describedby="search"
          />
        </InputGroup>
        <hr />
        {ChangeFeasibility.map((Cidata, index) =>
          <Form className="form" key={index} >
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
              <Row>
              <Col width="40%">
                <Form.Group className="mb-3">
                  <Form.Label> {Cidata.Title}  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
              
                  <Col width="60%">
                    <Form.Group className="mb-3">
                    <Form.Label> EDMC : {Cidata.FeasibilityEDMCMember==useremail && Cidata.EDMCInputAcceptanceBy=="" ?  "You need to approve" : Cidata.EDMCInputAcceptanceBy !== "" ? "Approval by "+ Cidata.EDMCInputAcceptanceBy :""} </Form.Label>
                 
                    </Form.Group>
                  </Col>
                   <Col>
                    <Button size="sm" style={{ float: "right" }} variant="outline-success" onClick={() => { handleView(Cidata); }}  > View </Button>
                  </Col> 
               
              </Col>
  
            </Row>
  
  
            <Row>
              <Col>
                <Form.Group className="mb-3">
                <Form.Label> Project : {Cidata.ProjectName}  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                <Form.Label> DMC : {Cidata.FeasibilityDmcMember==username && Cidata.DMCInputAcceptanceBy=="" ?  "You need to approve" : Cidata.DMCInputAcceptanceBy !== "" ? "Approval by "+ Cidata.DMCInputAcceptanceBy :""} </Form.Label>
                </Form.Group></Col>  
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Raised : {Cidata.Created} </Form.Label>
                </Form.Group></Col>
                <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Division : {Cidata.Division} </Form.Label>
                </Form.Group></Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Work Type : {Cidata.WorkType} </Form.Label>
                </Form.Group></Col>
                <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Change Initiation Request No : {Cidata.ChangeRequestNo} </Form.Label>
                </Form.Group></Col>
            </Row>
        
            <Row>
  
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>_____________________________________________________________________________________________________________________________________________________</Form.Label>
                </Form.Group>
              </Col>
  
  
            </Row>
          </Form>
        )}
      </Container>
  
  
    );
  }
  
  export default ChangeFeasibilityDetailsEDMCApproval;
  
  
  
  
  
  
  
  
  
  
  
  