import axios from "axios";
const API_URL =   process.env.REACT_APP_CHANGEINITIATION_API_URL + "EmailService" ; //process.env.REACT_APP_CHANGEINITIATION_API_URL;
//const axios = require("axios");

export const EnrollNewPassword = async (ChangePasswordData) => {
    console.log(JSON.stringify(ChangePasswordData));
    const resp = await axios
      .post(API_URL, JSON.stringify(ChangePasswordData), {
        headers: { "Content-Type": "application/json" },
      })
      .then(function (response) {
        console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  
    console.log(resp);
  
    return resp;
  };

  
export async function getdetails(Description) {
  let url = `${API_URL}/Description/${Description}`; 
  return fetch(url).then((response) => response.json()); 
}
  