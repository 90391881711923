/* 
Program Name : ProjectDetails.jsx
Written By: Snehal Kurhade
Written on: 19 APR 2022 
System Name: Change Management App
Purpose:  Screen to Add details of Section Master
Type of Program: React Frontend
*/
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
    EnrollNewSectionMaster,
  //getSectionMasterByCode,
} from "../services/SectionMaster-service";
//import { setError, setSuccess } from "../services/myFunctions";
/*import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";*/

const SectionMasterEnrollment = () => {
  const navigate = useNavigate();
  const [SMTeamName, setSMTeamName] = useState("");
  const [SMEmailID, setSMEmailID] = useState("");
  const [SMName, setSMName] = useState("");
  const [SMCreatedBy, setSMCreatedBy] = useState("");
  const [SMModifiedOn, setSMModifiedOn] = useState("");
  const [SMCreatedOn, setSMCreatedOn] = useState("");
  const [SMModifiedBy, setSMModifiedBy] = useState("");

  const [isPending, setIsPending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    const SectionMaster = {
        SMTeamName,
        SMEmailID,
        SMName,
        SMCreatedBy,
        SMModifiedOn,
        SMCreatedOn,
        SMModifiedBy
      
    
    };
    console.log(SectionMaster);

    EnrollNewSectionMaster(SectionMaster)
      .then((resp) => {
        //console.log(resp.data);
        if (resp.data.status) {
          console.log(resp.data.Message);
          setIsPending(false);
          alert(resp.data.Message);
          navigate("/SectionMaster");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*const checkSectionMasterCode = (PNDAMember) => {
   getSectionMasterByCode(Number(PNDAMember.value.trim()))
      .then((resp) => {
        if (resp.data.length > 0) {
          setError(PNDAMember, "SectionMaster Already Exists");
        } else {
          setSuccess(PNDAMember);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };*/

  return (
    <div className="create">
      <h2>Add New Data in SectionMaster Table</h2>
      <form className="form" onSubmit={handleSubmit}>
        <div className="control">
          <label>Team : </label>
          <input
            type="text"
            required
            value={SMTeamName}
            onChange={(e) => setSMTeamName(e.target.value)}
          />
         
        </div>
        <div className="control">
          <label>Email Id : </label>
          <input
            type="text"
            required
            value={SMEmailID}
            onChange={(e) => setSMEmailID(e.target.value)}
          />
        </div>

        <div className="control">
          <label> Name : </label>
          <input
            type="text"
            required
            value={SMName}
            onChange={(e) => setSMName(e.target.value)}
          />
        </div>


        
        <Button
          variant="outline-info"
          disabled={isPending}
         // onClick={!isPending ? handleSubmit : null}
         type="submit"
        >
          {isPending ? "Loading…" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default SectionMasterEnrollment;
