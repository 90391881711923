import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Teams" ; //process.env.REACT_APP_TEAMS_API_URL;
//const axios = require("axios");

export async function getTeams() {
  console.log("Team API_URL");
  console.log(API_URL);
  return fetch(API_URL).then((response) => response.json());
}

export async function getWorkPackage() {
  console.log("Team API_URL");
  console.log(API_URL);
  return fetch(API_URL).then((response) => response.json());
}





