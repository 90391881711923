/* 
Program Name : ChangeInitiation.jsx
Written By: Snehal Kurhade
Written on: 16 MAR 2022 
System Name: Change Management App
Purpose:  Screen to add new chnage Note
Type of Program: React Frontend
*/
import React, { useState, useEffect, } from "react";
import { Table, Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import 'react-responsive-combo-box/dist/index.css'
import "../App_Arun.css";
import 'react-dropzone-uploader/dist/styles.css';
import {
    addChangeAnalysis,  
} from "../services/ca-service";


export default function ChangeInitiation() {
   
    let username =localStorage.getItem('UserName');
    let userEmail = localStorage.getItem('UserId');
    
    const navigate = useNavigate();
        var AnalysisProvidedBy = username ;//sessionStorage.getItem('tofeedbackAnalysisProvidedBy1');
        

        var DMCAcceptanceBy = sessionStorage.getItem('toNewSubmitDmcMember');
        var DMCAcceptanceByEmail = sessionStorage.getItem('toNewSubmitDmcMemberEmail');
        

        var QAAcceptanceBy = sessionStorage.getItem('keyap1');
        

        var PDAAcceptanceBy = sessionStorage.getItem('keyap2');
        

        var AnalysisAcceptedBy = sessionStorage.getItem('keyap3');
        
        
        var QAAcceptanceByEmail = sessionStorage.getItem('keyapEmail1');
        var PDAAcceptanceByEmail = sessionStorage.getItem('keyapEmail2');
        var AnalysisAcceptedByEmail = sessionStorage.getItem('keyapEmail3');


        
       var ChangeInitiationNumber = sessionStorage.getItem('keysesvarcrn1');
        
        var ChangeInitiationOwner= sessionStorage.getItem('keysesvarcio1');
        
        var WorkType = sessionStorage.getItem('keysesvarwt1');
        
        var ProjectName= sessionStorage.getItem('keysesvarpn1');
        
        var ProjectNumber = sessionStorage.getItem('keysesvarpnum1');
        
        var ProjectOwner = sessionStorage.getItem('keysesvarpo1');
        
        var DMCMemberOwner = sessionStorage.getItem('keysesvardmo1');
        
        var crd = sessionStorage.getItem('keysesvarcrd1');
        
        var ChangeAnalysisNumber = sessionStorage.getItem('keycanum1');
        
        var ChangeAnalysisDate = sessionStorage.getItem('keycadate1');
        
        var UploadedFileLocation = sessionStorage.getItem('keys3Location');
        
        var UploadedFileNames = sessionStorage.getItem('keysFileNames');
        
        var CorrectiveAction = sessionStorage.getItem('keycorrectiveaction');
        
        sessionStorage.setItem("postbackcorrectiveaction",CorrectiveAction)
        var PreventiveAction = sessionStorage.getItem('keypreventiveaction');
        
        sessionStorage.setItem("postbackpreventiveaction",PreventiveAction)
        var ProcessChangeImpact = sessionStorage.getItem('keyProcessChangeImpact');
        
        sessionStorage.setItem("postbackProcessChangeImpact",ProcessChangeImpact)
        var ProcessUpdationDate = sessionStorage.getItem('keyProcessUpdationDate');
        
        sessionStorage.setItem("postbackProcessUpdationDate",ProcessUpdationDate)
        var Justification = sessionStorage.getItem('keyJustification');
        
        sessionStorage.setItem("postbackJustification",Justification)
        var TrackingPeriod = sessionStorage.getItem('keyTrackingPeriod')
        
        sessionStorage.setItem("postbackTrackingPeriod", TrackingPeriod)
        var TrackingEffectivenessEDMCMember = sessionStorage.getItem('keyTrackingEffectivenessEDMCMember')
        
        sessionStorage.setItem("postbackTrackingEffectivenessEDMCMember",TrackingEffectivenessEDMCMember)
        var TrackingDMCMember = sessionStorage.getItem('keyTrackingDMCMember')
        
        sessionStorage.setItem("postbackTrackingDMCMember", TrackingDMCMember)
        var ImpactTime = sessionStorage.getItem("keyImpactTime")
        
        var ImpactTimeInd = sessionStorage.getItem("keyImpactTimeInd")
        var ImpactCostInd=  sessionStorage.getItem("keyImpactCostInd");
        var ImpactQualityInd=  sessionStorage.getItem("keyImpactQualityInd")
        var ImpactOthersInd=  sessionStorage.getItem("keyImpactOthersInd")
        var ImpactSafetyInd=  sessionStorage.getItem("keyImpactSafetyInd")
        var ImpactContractInd=  sessionStorage.getItem("keyImpactContractInd")
        var ImpactComplianceInd=  sessionStorage.getItem("keyImpactComplianceInd")
          

        

        var ImpactCost=  sessionStorage.getItem("keyImpactCost")
        

        var ImpactQuality=  sessionStorage.getItem("keyImpactQuality")
        

        var ImpactOthers=  sessionStorage.getItem("keyImpactOthers")
        
  
        var ImpactSafety=  sessionStorage.getItem("keyImpactSafety")
        

        var ImpactContract=  sessionStorage.getItem("keyImpactContract")
        
  
        var ImpactCompliance=  sessionStorage.getItem("keyImpactCompliance")
        
 
        
        const today = new Date();

        const yyyy = today.getFullYear();

        let mm = today.getMonth() + 1; // Months start at 0!

        let dd = today.getDate();



        if (dd < 10) dd = '0' + dd;

        if (mm < 10) mm = '0' + mm;



        const todaysdate = dd + '/' + mm + '/' + yyyy;


        var AnalysisProvidedOn= todaysdate;
        

        var DMCAcceptanceOn = todaysdate;
        

        var QAAcceptanceOn = todaysdate;
        
        
        var PDAAcceptanceOn = todaysdate;
        
        
        var AnalysisAcceptedOn = todaysdate;
        







        
        
    const handleChange = async (e) => {
        
        
      this.fetchEmployees()
        const e1 = e.target;
        
        this.setState({
            switches: {
                ...this.state.switches,
                [e1.id]: !e1.checked
            }
        })
        

    }
    
    
   const handleSubmit = async(e)  => {     
         e.preventDefault();
         var AnalysisProvidedBy = username ;//sessionStorage.getItem('tofeedbackAnalysisProvidedBy1');
        

        var DMCAcceptanceBy = sessionStorage.getItem('toNewSubmitDmcMember');
        var DMCAcceptanceByEmail = sessionStorage.getItem('toNewSubmitDmcMemberEmail');
        

      
        var QAAcceptanceBy = sessionStorage.getItem('keyap1');
        



        var PDAAcceptanceBy = sessionStorage.getItem('keyap2');
        

        var AnalysisAcceptedBy = sessionStorage.getItem('keyap3');
        
        
        var QAAcceptanceByEmail = sessionStorage.getItem('keyapEmail1');
        var PDAAcceptanceByEmail = sessionStorage.getItem('keyapEmail2');
        var AnalysisAcceptedByEmail = sessionStorage.getItem('keyapEmail3');



        
       var ChangeInitiationNumber = sessionStorage.getItem('keysesvarcrn1');
        
        var ChangeInitiationOwner= sessionStorage.getItem('keysesvarcio1');
        
        var WorkType = sessionStorage.getItem('keysesvarwt1');
        
        var ProjectName= sessionStorage.getItem('keysesvarpn1');
        
        var ProjectNumber = sessionStorage.getItem('keysesvarpnum1');
        
        var ProjectOwner = sessionStorage.getItem('keysesvarpo1');
        
        var DMCMemberOwner = sessionStorage.getItem('keysesvardmo1');
        
        var crd = sessionStorage.getItem('keysesvarcrd1');
        
        var ChangeAnalysisNumber = sessionStorage.getItem('keycanum1');
        
        var ChangeAnalysisDate = sessionStorage.getItem('keycadate1');
        
        var UploadedFileLocation = sessionStorage.getItem('keys3Location');
        
        var UploadedFileNames = sessionStorage.getItem('keysFileNames');
        
        var CorrectiveAction = sessionStorage.getItem('keycorrectiveaction');
        
        var PreventiveAction = sessionStorage.getItem('keypreventiveaction');
        
        var ProcessUpdationDate = sessionStorage.getItem('keyProcessUpdationDate');
        
        var ProcessChangeImpact = sessionStorage.getItem('keyProcessChangeImpact');
        
        var Justification = sessionStorage.getItem('keyJustification');
        
        var TrackingPeriod = sessionStorage.getItem('keyTrackingPeriod')
        
        var TrackingEffectivenessEDMCMember = sessionStorage.getItem('keyTrackingEffectivenessEDMCMember')
        
        var TrackingDMCMember = sessionStorage.getItem('keyTrackingDMCMember')
        
        var ImpactTime = sessionStorage.getItem("keyImpactTime")
        
        var ImpactTimeInd = sessionStorage.getItem("keyImpactTimeInd")
        var ImpactCostInd=  sessionStorage.getItem("keyImpactCostInd");
        var ImpactQualityInd=  sessionStorage.getItem("keyImpactQualityInd")
        var ImpactOthersInd=  sessionStorage.getItem("keyImpactOthersInd")
        var ImpactSafetyInd=  sessionStorage.getItem("keyImpactSafetyInd")
        var ImpactContractInd=  sessionStorage.getItem("keyImpactContractInd")
        var ImpactComplianceInd=  sessionStorage.getItem("keyImpactComplianceInd")
            

        

        var ImpactCost=  sessionStorage.getItem("keyImpactCost")
        

        var ImpactQuality=  sessionStorage.getItem("keyImpactQuality")
        

        var ImpactOthers=  sessionStorage.getItem("keyImpactOthers")
        
  
        var ImpactSafety=  sessionStorage.getItem("keyImpactSafety")
        

        var ImpactContract=  sessionStorage.getItem("keyImpactContract")
        
  
        var ImpactCompliance=  sessionStorage.getItem("keyImpactCompliance")
        

        const today = new Date();

        const yyyy = today.getFullYear();

        let mm = today.getMonth() + 1; // Months start at 0!

        let dd = today.getDate();



        if (dd < 10) dd = '0' + dd;

        if (mm < 10) mm = '0' + mm;



        const todaysdate = dd + '/' + mm + '/' + yyyy;

        var AnalysisProvidedOn= todaysdate;
        

        var DMCAcceptanceOn = "";
        

        var QAAcceptanceOn = "";
        
        
        var PDAAcceptanceOn = "";
        
        
        var AnalysisAcceptedOn = "";
        

        var DMCAcceptance = "";
        var QAAcceptance = "";
        var PDAAcceptance = "";
        var AnalysisAccepted = "";
        var RejectionCount = 0;   
        var IsRejected="";
        var PendingWithInitiator="";     
 
        const item = {ChangeInitiationNumber,ChangeInitiationOwner,WorkType,ProjectName,ProjectNumber,ProjectOwner, DMCMemberOwner, ChangeAnalysisDate, ChangeAnalysisNumber, ImpactTime, ImpactTimeInd, ImpactCost, ImpactCostInd, ImpactQuality, ImpactQualityInd, ImpactOthers, ImpactOthersInd, ImpactSafety, ImpactSafetyInd,IsRejected,  ImpactContract, ImpactContractInd, ImpactCompliance, ImpactComplianceInd, UploadedFileLocation,UploadedFileNames, CorrectiveAction, PreventiveAction,
             
            ProcessChangeImpact,  ProcessUpdationDate, Justification, TrackingPeriod, TrackingEffectivenessEDMCMember, TrackingDMCMember ,
                          AnalysisProvidedBy, AnalysisProvidedOn,  DMCAcceptanceBy,DMCAcceptanceByEmail, DMCAcceptanceOn, DMCAcceptance, QAAcceptanceBy, QAAcceptanceOn, QAAcceptance, PDAAcceptanceBy, PDAAcceptanceOn, PDAAcceptance, QAAcceptanceByEmail, PDAAcceptanceByEmail, AnalysisAcceptedByEmail,
                     AnalysisAcceptedBy, AnalysisAcceptedOn, AnalysisAccepted, RejectionCount}
                     

                     addChangeAnalysis(item)
            .then((resp) => {
                console.log("resp");
                console.log(resp);
                if (resp.data.status) {
                    console.log(resp.data.Message);
                    navigate('/changeanalysis', {replace: true});
                    alert("Change Analysis Inserted Successfully");
                          
                }
                else{
                    console.log(resp.data.Message);
                    alert(resp.data.Message);
                }
            })
            .catch((error) => {
                console.log(error);
            });

                //      addChangeAnalysis(item);
                //   let  ans1 = window.confirm("Analysis Added Successfully");                            
                //            console.log(ans1)
                //            if(ans1){
                //                  window.location = "/changeanalysis";
                //            } else{ window.location = "/changeanalysis";} 


                    //  fetch(API_URL, {
                    //     method: 'POST',
                    //     headers:{
                    //       'Accept':'application/json',
                    //       'Content-Type':'application/json'
                    //     },
                    //     body:JSON.stringify(item)
                    //   }).then((resp) => {
                                          
                            
                           
                    //     })
                        
                    //     ans1 = window.confirm("Analysis Added Successfully");                            
                    //     console.log(ans1)
                    //     if(ans1){
                    //           window.location = "/changeanalysis";
                    //     } else{ window.location = "/changeanalysis";}   
    }





    
    
    

    
        return (
            
            <Container >
              
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Analysis Provided By:
(To be filled by Project Head / EDMC Member)</Form.Label>
<Form.Control name="analysedby" value={AnalysisProvidedBy}  />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Date</Form.Label>
                               
                                <Form.Control type="text" id="date" value={todaysdate}
                                name="date"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />

                    <Form.Group className="mb-3">
                       
                        <Row>
                            <Col>
                            <Form.Label className="FeedbackLabel" >Analysis Found to be acceptable
(To be filled by DMC Member of team seeking change)</Form.Label>
</Col>
<Col>
                                <Form.Check
                                      disabled
                                    type="switch"
                                    id="Dispositioninput"
                                   
                                /></Col>
                            <Col>
                                <Form.Control name="DMCacceptanceby" readOnly={true} value={DMCAcceptanceBy}  />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                            <Form.Label>Date</Form.Label>
                            </Col>
                            <Col>
                                </Col>
                            <Col>
                                <Form.Control readOnly={true} type="text" name="okinput" value={DMCAcceptanceOn} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <br/>

                    <Form.Group className="mb-3">
                       
                        <Row>
                            <Col>
                            <Form.Label className="FeedbackLabel" >Analysis Found to be acceptable
To be filled by QA Lead (If Applicable)</Form.Label>
</Col>
<Col>
<Form.Check
                                      disabled
                                    type="switch"
                                    id="tailinput"
                                    
                                /></Col>
                            <Col>
                                <Form.Control readOnly={true} value={QAAcceptanceBy}  type="text" name="tailinput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                            <Form.Label>Date</Form.Label>
                            </Col>
                            <Col>
                                </Col>
                            <Col>
                                <Form.Control readOnly={true} type="text" name="okinput" value={QAAcceptanceOn} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <br/>

                    <Form.Group className="mb-3">
                       
                        <Row>
                            <Col>
                            <Form.Label className="FeedbackLabel" >Analysis Found to be acceptable by P&DA
                            (To be filled by P&DA Head)</Form.Label>
</Col>
<Col> <Form.Check
                                    disabled
                                    type="switch"
                                    id="isolatedPorosityInput"
                                    
                                /></Col>
                            <Col>
                                <Form.Control readOnly={true} value={PDAAcceptanceBy}  type="text" name="isolatedPorosityInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                            <Form.Label>Date</Form.Label>
                            </Col>
                            <Col>
                                </Col>
                            <Col>
                                <Form.Control readOnly={true}  type="text" name="okinput" value={PDAAcceptanceOn} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <br />

                    <Form.Group className="mb-3">
                       
                        <Row>
                            <Col>
                            <Form.Label className="FeedbackLabel" >Analysis Accepted
(To be filled by BH)</Form.Label>
</Col>
<Col>
<Form.Check
                                    disabled
                                    type="switch"
                                    id="alignedPorosityInput"
                                    
                                /></Col>
                            <Col>
                                <Form.Control readOnly={true} value={AnalysisAcceptedBy}  type="text" name="alignedPorosityInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                            <Form.Label>Date</Form.Label>
                            </Col>
                            <Col>
                                </Col>
                            <Col>
                                <Form.Control readOnly={true} type="text" name="okinput" value={AnalysisAcceptedOn}  />
                            </Col>
                        </Row>
                        
                    </Form.Group>
                    <br/>

                   
                    <Container className="FormButtons">
                        <Button variant="success" size="lg" value="Reload Page" type="button" onClick={handleSubmit}>Submit</Button>
                    </Container>
                    <br></br>
                    <Row>
                        <div class="float-start m-1">
                <Button class="btn-primary"
                    style={{ float: "left" }}
                    size="sm"
                    onClick={() => {
                        window.location = "/ChangeAnalysisUploader2"}}>
                    Back
                </Button>

            </div>
                       
                        </Row>
                </Form>
            </Container >
        )
    
};



