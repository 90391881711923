import axios from "axios";
const API_INIT_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "ChangeInitiation";
// "http://localhost:5009/ChangeInitiation"; //process.env.REACT_APP_CHANGEINITIATION_API_URL;
//const axios = require("axios");

export async function getAll(userEmail) {
    return fetch(API_INIT_URL).then((response) => response.json());
  }
  

  export async function getChangeInitiationForAnalysis(UserEmail) {
    let url = `${API_INIT_URL}/UserEmail/${UserEmail}`; 
    return fetch(url).then((response) => response.json()); 
  }
  

export async function getInitation(creqno) {
    let url = `${API_INIT_URL}/creqno/${creqno}`;
    
    return fetch(url).then((response) => response.json());
  }


  // export async function getChangereqNumber() {
  //   return fetch(API_INIT_URL).then((response) => response.json());
  // }

