/* 
Program Name : ChangeInitiation.jsx
Written By: Snehal Kurhade
Written on: 16 MAR 2022 
System Name: Change Management App
Purpose:  Screen to add new chnage Note
Type of Program: React Frontend
*/
import React, { useState, useEffect, } from "react";
import { Table, Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import 'react-responsive-combo-box/dist/index.css'
import "../App_Arun.css";
import axios from "axios";
import 'react-dropzone-uploader/dist/styles.css';
import { FileEarmarkText, Trash } from "react-bootstrap-icons";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Dropzone from 'react-dropzone-uploader';
// import {DatePicker }from "react-datepicker";
import DatePicker from 'react-date-picker';
import moment from 'moment';
import $ from 'jquery';
// import Moment from 'react-moment';

import {
    EnrollNewPassword,
    getdetails
 
} from "../services/ChangePassword-service";


//const axios = require("axios").default;
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "EmailService" ; // process.env.REACT_APP_CHANGEINITIATION_API_URL;

export default function ChangePassword() {
    const navigate = useNavigate();
    let query = window.location.href
    let indication = "";
    indication = query.split("?")
    if(indication.length>1){
        navigate("/Raised");
    }

    useEffect(() => {
        fetchDetails();
    }, []);

    const[Details, setDetails] = useState([]);
    const [EmailId, setEmailId] = useState("");
    const [PrevEmailId, setPrevEmailId] = useState("");
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");    
    const [PrevPassword, setPrevPassword] = useState("");
    const [Description, setDescription] = useState("");   
    const [CreatedBy, setCreatedBy] = useState("");
    const [CreatedOn, setCreatedOn] = useState("");
    const [ModifiedBy, setModifieddBy] = useState("");
    const [ModifiedOn, setModifiedOn] = useState("");


    let username = localStorage.getItem('UserName');
    let useremail = localStorage.getItem('UserId');
    const fetchDetails = async () => {
    const result = await getdetails("SendMail");
    setDetails(result); 
    
    setPrevEmailId(result[0].EmailId);
    setPrevPassword(result[0].Password)
    setEmailId(result[0].EmailId)
    document.getElementById("EmailId").value= result[0].EmailId;  
    console.log(PrevEmailId);
    console.log(PrevPassword);
    // setEmailId(result[0].EmailId);
    }
    

    
   

  
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const todaysdate = dd + '/' + mm + '/' + yyyy;

const onBackClick = () =>{
    var NavPath= sessionStorage.getItem("NavPath");       
    navigate(NavPath);
  }
    const btnCancelClick = async (e) => {
        navigate("/Raised");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;  
        var PasswordFormat = /^(?=.*[0-9])(?=.*[~!@%^_{}:?-\[\].])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~!@%^_{}:?-\[\].]{10,15}$/;
        let Description="SendMail";
        const ChangePasswordData = {
            EmailId, PrevEmailId, Password, PrevPassword, Description
           
        };
        var alertMessage = "";


        if (EmailId.trim() == "") { alertMessage += "Please Enter EmailId \r\n"; }
        if(EmailId.trim() != ""){
        if(!(EmailId.match(mailformat))) { alertMessage += "Please Enter Valid EmailId \r\n"; }
        let Domainname= EmailId.split("@")[1];
        if(Domainname != "godrej.com"){
            { alertMessage += "Domain of Email should be @godrej.com \r\n"; }
        }
    }

        if (Password.trim() == "") { alertMessage += "Please Enter Password \r\n "; }
        if(Password.trim() != ""){
            if(!(Password.match(PasswordFormat))) { alertMessage += "Please Enter Valid Password \r\n"; }
        }
        // if (Password.length < 10 || Password.length >15) {
        //     alertMessage +="Password has to be Minimum of 10 Characters and Maximum 15 Characters Only."; 
        // }
        // if(Password.search(/[a-z]/) < 0) { alertMessage += "Your password must contain at least one lowercase letter." } 
        //  if (Password.search(/[A-Z]/) < 0) {alertMessage +="Your password must contain at least one uppercase letter."}
        //  if (Password.search(/[A-Z]/) < 0) {alertMessage +="Your password must contain at least one uppercase letter."}
      
        if (alertMessage != "") {
            alert(alertMessage);
            return;
        }

        fetch(`${API_URL}/EmailId/${EmailId}/Password/${Password}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ChangePasswordData)
        }).then((result) => {
            result.json().then((resp) => {
              console.warn(resp);
              console.log(resp.Message);
              if(resp.Message=="Successfully Added Record"){
              alert("Details Updated Successfully");
              navigate("/homescreen") 
              }else{
                alert("Error While Updating Record"); 
              }                      
            })
          })
        
        // .then((resp) => { 
        //     alert("Details Updated Successfully");
        //     var NavPath= sessionStorage.getItem("NavPath");       
          
        //     navigate(NavPath, {replace: true}); 
            
                
                  
                          
        // })
    };




    return (
        <Container className="LogbookForm">
            <h5> <span class="text-primary">Change Password </span></h5>
            <Form className="form" value="Form" onSubmit={handleSubmit}>              
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> EmailId:</Form.Label>
                                    <Form.Control type="text" value={EmailId}  id="EmailId" name="EmailId" onChange={(e) => setEmailId(e.target.value)} />
                                </Form.Group></Col>
                                <Col></Col>
                        </Row> 
                        <Row>
                        <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> New Password</Form.Label>
                                    <Form.Control type="password" value={Password}  id="Password" name="Password" onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                        <h8> <span class="text-primary">Only the following Special characters can be used ( ~!@%^_{}:?-[]. ).</span></h8>
                        <h8> <span class="text-primary">Atleast one special character has to be used in the Password Mandatorily. No other special characters can be used.</span></h8>
                        <h8> <span class="text-primary">Password has to be Minimum of 10 Characters and Maximum 15 Characters Only.</span></h8>
                        <h8> <span class="text-primary">Spaces are not allowed in the password.</span></h8>
                        <h8> <span class="text-primary">Password Must contain Characters in both Upper(Capital Letter) and Lower Case(Small Letter).</span></h8>
       
                        </Row>
                        {/* <Row>
                        <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Confirm Password</Form.Label>
                                    <Form.Control type="password" value={ConfirmPassword}  id="ConfirmPassword" name="ConfirmPassword" onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row> */}
                        <Row><br></br></Row>
                        
                        <Container style={{ display: "flex", justifyContent: "center" }}>
                            <Stack direction="horizontal" gap={4}>
                                <Button variant="success" size="lg" value="submit" type="submit">Submit</Button>
                                <Button variant="danger" onClick={btnCancelClick} size="lg" type="reset">Cancel</Button>
                            </Stack>
                        </Container>
                       
                  
            </Form>
        </Container>

    )

};



