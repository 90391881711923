import axios from "axios";
const API_URL =   process.env.REACT_APP_CHANGEINITIATION_API_URL + "ChangeInitiation" ; //process.env.REACT_APP_CHANGEINITIATION_API_URL;
const REPORT_API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Report";
// "http://localhost:5009/Report" ; //process.env.REACT_APP_REPORT_API_URL;

//const axios = require("axios");

//import axios from "axios";

export async function getAll() {  
  return fetch(API_URL).then((response) => response.json());
}

export async function getProjects(){
  return fetch(API_URL).getProjects.then((response) => response.json());
   }

export async function getChangeInitiation(ProjectNumber, ChangeRequestNumber) {
  let url = `${API_URL}/ProjectNumber/${ProjectNumber}/ChangeRequestNumber/${ChangeRequestNumber}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function getdetails(ChangeReqNo) {
  let url = `${API_URL}/ChangeReqNo/${ChangeReqNo}`; 
  return fetch(url).then((response) => response.json()); 
}

export async function getReport(useremail) { 
  let url = `${REPORT_API_URL}/useremail/${useremail}`;
  return fetch(url).then((response) => response.json()); 
}

export async function getChangeInitationdata( ChangeRequestNumber,ProjectNumber) {
  let url = `${API_URL}/ChangeRequestNumber/${ChangeRequestNumber}/ProjectNumber/${ProjectNumber}`;
  console.log(url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function getSelectedProjectRevisionNo(ProjNum) {
  let url = `${API_URL}/ProjNum/${ProjNum}`;
  console.log("getSelectedProjectRevisionNo-Service")
  console.log(url)
  return fetch(url).then((response) => response.json());
 
}

export async function getSelectedProjectTimeandCost(ProjNum,RevisionNum) {
  let url = `${API_URL}/ProjNum/${ProjNum}/RevisionNum/${RevisionNum}`;
  console.log("getSelectedProjectRevisionNo-Service")
  console.log(url)
  return fetch(url).then((response) => response.json()); 
}

export async function getFeasibilityData(ChangeRequestNumber) {
  let url = `${API_URL}/ChangeRequestNumber/${ChangeRequestNumber}`;
  return fetch(url).then((response) => response.json()); 
}

export async function getChangeAnalysisData(ChangeRequestNo) {
  let url = `${API_URL}/ChangeRequestNo/${ChangeRequestNo}`;
  return fetch(url).then((response) => response.json()); 
}
export async function getRejectionData(CRNo) {
  let url = `${API_URL}/CRNo/${CRNo}`;
  return fetch(url).then((response) => response.json()); 
}



export async function deleteChangeInitiation(Proj, ChangeRequestNumber) {

  let url = `${API_URL}/Proj/${Proj}/ChangeRequestNumber/${ChangeRequestNumber}`;
  console.log("url");
  console.log(url);
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}



export const EnrollNewChangeInitiation = async (ChangeInitiation) => {
  
  console.log(JSON.stringify(ChangeInitiation));

  const resp = await axios
    .post(API_URL, JSON.stringify(ChangeInitiation), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });

  console.log(resp);

  return resp;
};





// function updateUser()
//   {
//     let item={DMCTeam,DMCMemberCode,Project,DMCMember,ActiveIndication,DMCMemberEmail}
//     console.warn("item",item)
//     fetch(API_URL+`/DMCTeam/${DMCTeam}/DMCMemberCode/${DMCMemberCode}`, {
//       method: 'PUT',
//       headers:{
//         'Accept':'application/json',
//         'Content-Type':'application/json'
//       },
//       body:JSON.stringify(item)
//     }).then((result) => {
//       result.json().then((resp) => {
//         console.warn(resp)
// navigate("/DMCMaster")
//         //getUsers()
//       })
//     })
//   }

export const UpdateChangeInitiation = async (ChangeInitiation) => {
  console.log("ChangeInitiation" , ChangeInitiation);
  const ProjectNumber=ChangeInitiation.ProjectNumber;
  const ChangeRequestNumber=ChangeInitiation.ChangeRequestNumber;  
  let url = `${API_URL}/ProjectNumber/${ProjectNumber}/ChangeRequestNumber/${ChangeRequestNumber}`;
  const resp = await axios
    .post(url, JSON.stringify(ChangeInitiation), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log("resp in service1", response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });

  console.log("resp in service2", resp);

  return resp;
  // let url = `${API_URL}/ProjectNumber/${ProjectNumber}/ChangeRequestNumber/${ChangeRequestNumber}`;
  
  // const resp = await axios
  //   .put(url, JSON.stringify(ChangeInitiation), {
  //     headers: { "Content-Type": "application/json" },
  //   })
  //   .then(function (response) {
  //     console.log(response);
  //     return response;
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  // console.log(resp);

  // return resp;
}






