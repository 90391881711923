import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "user" ; //process.env.REACT_APP_EMPLOYEE_API_URL;
//const axios = require("axios");

export async function authenticate(EmailId,accessToken) {    
    let options = { headers : {Authorization : `Bearer ${accessToken}`}}
    let userUrl = `${API_URL}/authenticate/${EmailId}`;
    console.log(userUrl);
    return fetch(userUrl,options)
        .then(response => response.json());
}
