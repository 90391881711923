/* 
Program Name : FeasibilityDashboard.jsx
Written By: Neha Hankare
Written on: 26 MAR 2022 
System Name: Change Management App
Purpose:  Screen to Display details of Change Feasibility
Type of Program: React Frontend
*/


import React,{ useState, useEffect, useRef, Component } from "react";
import { Table, Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import "../App_Arun.css";
import {  
   getAll,
   getUsersChangeInitationdata,
   getUsersChangeFeasibilitydata,
   getUsersChangeAnalysisdata,
   
  
  } from "../services/ChangeFeasibility-service";

  import {  
    getFeasibilityData,
   
   } from "../services/ChangeInitiation-service";
   

// import { getChangeInitationdata ,getFeasibilityData} from "../services/ChangeInitiation-service";

  const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "ChangeInitiation" ; //process.env.REACT_APP_CHANGEINITIATION_API_URL;

function FeasibilityDashboard  ()  {  

  let FromInitiationDetailScreen= sessionStorage.getItem('FromInitiationDetailScreen');
  let reqChangeRequestNo= sessionStorage.getItem('reqChangeReqNo');
 
  let username =localStorage.getItem('UserName');
  let userId = localStorage.getItem('UserId');

  // var element=document.querySelector('ul.checkout-bar > li.active');
  // return element ? element.classList.value : undefined;
    useEffect(() => {
    FetchFeasibilityData();
    }, []);    
    const navigate = useNavigate();
    const [changeInitiationData, setchangeInitiationData] = useState([]);
    const [FeasibilityData, setFeasibilityData] = useState([]);
    const [ChangeAnalysisData, setChangeAnalysisData] = useState([]);
    const [SubmittedFeasinilityCount, setSubmittedFeasinilityCount] = useState(0);
    const [RejectionCount, setRejectionCount] = useState(0); 
    const [ChangeInitiationResultCount, setChangeInitiationResultCount] = useState(0); 
    const [ChnageFeasibilityResultCount, setChnageFeasibilityResultCount] = useState(0); 
    const [ChangeAnalysisResultCount, setChangeAnalysisResultCount] = useState(0); 
    const [ChangeInitiationProcessedCount, setChangeInitiationProcessedCount] = useState(0); 
    const [ChangeInitiationStopWorkCount, setChangeInitiationStopWorkCount] = useState(0); 
    const [ChangeFeasibilityInProgressedCount, setChangeFeasibilityInProgressedCount] = useState(0); 
    const [ChangeFeasibilityProcessedCount, setChangeFeasibilityProcessedCount] = useState(0);
    const FetchFeasibilityData = async () => {   
      
        
      
      document.getElementById("feasibilitydashboard").classList.add('active');
      document.getElementById("FeasibilityRaised").classList.remove('active');
      document.getElementById("EDMCApproval").classList.remove('active');
      document.getElementById("DMCApproval").classList.remove('active');
      document.getElementById("PDATeam").classList.remove('active');
      document.getElementById("Raised").classList.remove('active');
      document.getElementById("approvalrequest").classList.remove('active');
      document.getElementById("approvedrequest").classList.remove('active');
      document.getElementById("pdainitiation").classList.remove('active');
      sessionStorage.setItem("clickedEvent","feasibilitydashboard");
      const ChangeInitiationResult=await getUsersChangeInitationdata(userId);   
      const ChnageFeasibilityResult=await getUsersChangeFeasibilitydata(username);
      const ChangeAnalysisResult=await getUsersChangeAnalysisdata(username);
      setChangeInitiationResultCount(ChangeInitiationResult.length);
      setChnageFeasibilityResultCount(ChnageFeasibilityResult.length);
      setChangeAnalysisResultCount(ChangeAnalysisResult.length);

      setChangeInitiationProcessedCount((ChangeInitiationResult.filter((item) => (item.StopWork != "Yes" ))).length);
      setChangeInitiationStopWorkCount((ChangeInitiationResult.filter((item) => (item.StopWork == "Yes" ))).length);
      setChangeFeasibilityInProgressedCount((ChnageFeasibilityResult.filter((item) => (item.ProcessIndication != "SubmittedByDMC" ))).length);
      setChangeFeasibilityProcessedCount((ChnageFeasibilityResult.filter((item) => (item.ProcessIndication == "SubmittedByDMC" ))).length);
    
      if(FromInitiationDetailScreen =="Yes"){
        
        if(reqChangeRequestNo !="" && reqChangeRequestNo != null && reqChangeRequestNo!= undefined){
          sessionStorage.setItem('reqChangeReqNo', reqChangeRequestNo );
         sessionStorage.setItem('FromInitiationDetailScreentmp', "Yes" );
          const result1 = await getFeasibilityData(reqChangeRequestNo); 
        for(let i=0; i<result1.length; i++){            
             const EdmcAging=  getAging(result1[i].FeasibilityInputGivenOn,result1[i].EDMCInputAcceptanceOn)
             const DmcAging=  getAging(result1[i].EDMCInputAcceptanceOn,result1[i].DMCInputAcceptanceOn)
            result1[i].EdmcAging=EdmcAging;
            result1[i].DmcAging=DmcAging; 
        }
        setFeasibilityData(result1);
        
      }
      }
      else{
      const result1 = await getAll(); 
        for(let i=0; i<result1.length; i++){            
             const EdmcAging=  getAging(result1[i].FeasibilityInputGivenOn,result1[i].EDMCInputAcceptanceOn)
             const DmcAging=  getAging(result1[i].EDMCInputAcceptanceOn,result1[i].DMCInputAcceptanceOn)
            result1[i].EdmcAging=EdmcAging;
            result1[i].DmcAging=DmcAging; 
        }
        setFeasibilityData(result1);
       };    
       
     
       };
       

       const getAging = (d1,d2) => {        
        const dateObjtmp1 = d1.split(" ")[0].split("/");        
        const dateObjtmp2 = d2.split(" ")[0].split("/");
        const dateObj1=new Date(dateObjtmp1[2]+"-"+ dateObjtmp1[1] +"-"+ dateObjtmp1[0]);
        const dateObj2=new Date(dateObjtmp2[2]+"-"+ dateObjtmp2[1] +"-"+ dateObjtmp2[0]);      
        const diff = dateObj2.getTime() - dateObj1.getTime();
        return diff / (1000 * 60 * 60 * 24);     
    };

       const routeChange = () =>{ 
       // sessionStorage.setItem('ChangeReqNo', ChangeReqNo );
        navigate("/FeasibilityRaised");
      }
    var bgColors = {  
        "Orange": "#FFA500",
        "Gray": "#A0A0A0",
        "Green": "#006400",
        "Red": "#FF0000",
        "Brown": "#D2691E",
      };
      

    return (
      
      <Container className="LogbookForm">       
        
        
          <div>
          <h2> <span class="text-primary">Change Feasibility </span></h2>

          <div class="float-end m-1"><Button class="btn-primary" style={{ float: "end" }} size="sm"
      onClick={() => { navigate("/changefeasibility") }}> Add New</Button></div>

<div class="float-end m-1"><Button class="btn-primary" style={{ float: "end" }} size="sm"
      onClick={() => { navigate("/FeasibilityRaised") }}>Search</Button></div>
              
          </div> 
          <Row>
          <br></br>    
          <br></br>       
          </Row>        
          <Row>
            
              <Col>
          <Table>
          <thead>
                            <tr><th></th>                                
                                <th>In-Progressed</th>
                                <th>Processed</th> 
                                <th>Stop-Work</th>                                
                            </tr>
                        </thead>
                        <tbody>
                       
                                <tr>                               
                                    <td>Initiation : {ChangeInitiationResultCount}</td>                                   
                                    <td>{ChangeInitiationResultCount}</td>
                                    <td>{ChangeInitiationProcessedCount}</td> 
                                    <td>{ChangeInitiationStopWorkCount}</td>  
                                                           
                                </tr> 
                                 <tr>
                                    <td>Feasibility : {ChnageFeasibilityResultCount}</td>                                    
                                    <td>{ChangeFeasibilityInProgressedCount}</td>
                                    <td>{ChangeFeasibilityProcessedCount}</td> 
                                    <td></td>                                     
                                </tr> 
                                <tr>
                                    <td>Analysis : {ChangeAnalysisResultCount}</td>
                                    <td>{ChangeAnalysisResultCount}</td>
                                    <td>{ChangeAnalysisResultCount}</td> 
                                    <td></td>                                     
                                </tr>                  
                        </tbody>
</Table>
</Col>

</Row><Row>
<h3> <span class="text-primary">Change Feasibility Records </span></h3>
</Row>
               <hr />
               {FeasibilityData.map((Cfdata, index) =>
               <Form className="form" key={index} >
                   
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                   <Col>
                   <Row>
                       <Col>
                   <Form.Group className="mb-3">
                <Form.Label> Feasibility No: {Cfdata.Title}  </Form.Label>
              </Form.Group>
              </Col>
              <Col>
              <Form.Group className="mb-3">
                <Form.Label> Project Name: {Cfdata.ProjectName}  </Form.Label>
              </Form.Group>
              </Col>
                   </Row>
                   <Row>
                   <Form.Group className="mb-3">
                <Form.Label> Change Request Number: {Cfdata.ChangeRequestNo}  </Form.Label>
              </Form.Group> 
                   </Row>
                   <Row>
                       <Col>
                   <Form.Group className="mb-3">
                <Form.Label> DMC Name: {Cfdata.FeasibilityInputGivenBy}  </Form.Label>
              </Form.Group> 
              </Col>
              <Col>
              <span Class="float-end fa fa-arrow-right" onClick={routeChange}></span>
              </Col>
                   </Row>
                   <Row>
                   <Form.Group className="mb-3">
                <Form.Label> Date: {Cfdata.ChangeRequestDate}  </Form.Label>
              </Form.Group> 
                   </Row>
                   <Row>
            <Col class="col-md-2">
            <Form.Label >Change Initiater&nbsp;</Form.Label>
              <span class="fa fa-circle" style={{ color: (Cfdata.FeasibilityInputGivenBy === "" ) ? bgColors.Red : bgColors.Green }} >   </span>
               <Form.Label >&nbsp;{Cfdata.FeasibilityInputGivenBy}&nbsp;-------{'>'} </Form.Label>           
            <Form.Label >&nbsp;EDMC &nbsp;</Form.Label><Form.Label style={{ color: bgColors.Brown }}  >({Cfdata.EdmcAging +" Days"})&nbsp;  </Form.Label>
              <span class="fa fa-circle" style={{ color: (Cfdata.EDMCInputAcceptance === "Yes" ) ? bgColors.Green  : bgColors.Red }} >   </span>
               <Form.Label >&nbsp;{Cfdata.EDMCInputAcceptanceBy}&nbsp;-------{'>'}  </Form.Label>          
            <Form.Label >&nbsp;DMC&nbsp;</Form.Label><Form.Label style={{ color: bgColors.Brown }}  >({Cfdata.DmcAging +" Days"})&nbsp;  </Form.Label>    
             <span class="fa fa-circle" style={{ color: (Cfdata.DMCInputAcceptance === "Yes" ) ? bgColors.Green  : bgColors.Red }} >   </span>
               <Form.Label >&nbsp;{Cfdata.DMCInputAcceptanceBy}</Form.Label>  

            </Col>
          
          </Row>
                   <Row>
                       _______________________________________________________________________________________________________________________________________________________________
                   </Row>
                   </Col>

                   
                  
                 
               </Form>
               )}
           </Container>

)
}

export default FeasibilityDashboard;

           




