import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Table, Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
 var XLSX = require("xlsx");
export  default function ContactUs  ()  {
  function DownloadJSON2CSV()
{
  const objArray=
 [ {Title: 'QALead', ApproverName: 'Snehal Kurhade', ApproverEmailID: 'snehalsk@godrej.com'},
 {Title: 'ChangeTimeLinePDA', ApproverName: 'Snehal Kurhade', ApproverEmailID: 'snehalsk@godrej.com'},
 {Title: 'ChangeCostLine', ApproverName: 'Snehal Kurhade', ApproverEmailID: 'snehalsk@godrej.com'},
 {Title: 'GCBusinessHead', ApproverName: 'Snehal Kurhade', ApproverEmailID: 'snehalsk@godrej.com'},
 {Title: 'test', ApproverName: 'snehal kurhade', ApproverEmailID: 'snehalsk@godrej.com'}];
  
 var array = objArray;

    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = new Array();

        for (var index in array[i]) {
           line.push('"' + array[i][index] + '"');
        }

        str += line.join(';');
        str += '\r\n';
    }
    window.open( "data:text/csv;charset=utf-8," + encodeURIComponent(objArray));

    let excel=( "data:text/csv;charset=utf-8," + encodeURIComponent(objArray));
    window.open(excel);

    const XLSX = require('xlsx');

var ws = XLSX.utils.aoa_to_sheet([
  "SheetJS".split(""),
  [1,2,3,4,5,6,7],
  [2,3,4,5,6,7,8]
]);

XLSX.writeFile(ws, 'out.xlsb');
window.open('out.xlsb')

}




//   const form = useRef();
  
//   var wb = XLSX.utils.book_new();
//   wb.Props = {
//     Title: "SheetJS Tutorial",
//     Subject: "Test",
//     Author: "Red Stapler",
//     CreatedDate: new Date(2017,12,19)
// };
// wb.SheetNames.push("Test Sheet");
// var ws_data = [['hello' , 'world']];  //a row with 2 columns
// var ws = XLSX.utils.aoa_to_sheet(ws_data);
// wb.Sheets["Test Sheet"] = ws;
// var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

// function s2ab(s) { 
//   var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
//   var view = new Uint8Array(buf);  //create uint8array as viewer
//   for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
//   return buf;    
// }

// const updateData = async (e) => {  
//   saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'test.xlsx');
// }
//   const sendEmail = (e) => {
//       console.log("under sendemail");
//     e.preventDefault();

//     emailjs.sendForm('snehalsk@godrej.com', 'snehalsk@godrej.com', form.current, 'snehalsk')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//   };

  return (
    <form >
      
      {/* <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" /> */}
      {/* <input type="submit" value="Send" /> */}
      <Button variant="success" size="lg" onClick={DownloadJSON2CSV} type="submit">Submit</Button>
    </form>
  );
};