/* 
Program Name : FeasibilityUpdate.jsx
Written By: Neha Hankare
Written on: 26 MAR 2022 
System Name: Change Management App
Purpose:  Screen to Update details of  Change Feasibility 
Type of Program: React Frontend
*/
import { Button, Col, Form,  Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {
  getdetails,    
} from "../services/ChangeInitiation-service";
import {   
  getFeasibilityRaised,
} from "../services/cf-service";

const API_URL1 = process.env.REACT_APP_CHANGEINITIATION_API_URL + "getfeasibility";
 function FeasibilityUpdate() {
  let username =localStorage.getItem('UserName');
    let useremail = localStorage.getItem('UserId');
 var CFRNo=sessionStorage.getItem('CFRNo');
 var ChangeRequestNumber=sessionStorage.getItem('ChangeRequestNo');
  // var data1 = sessionStorage.getItem('CFRNo')
  // var data2 = sessionStorage.getItem("keyWorkType")
  // var data3 = sessionStorage.getItem("keyProjectName") 
  // var data4 = sessionStorage.getItem("keyProjectNumber")
  // var data5 = sessionStorage.getItem("keyDivision")
  // var data6 = sessionStorage.getItem("keyChangeRequestNo")
  // var data7 = sessionStorage.getItem("keyChangeRequestDate")
  // var data8 = sessionStorage.getItem("keyEDMCMember")
  // var data9 = sessionStorage.getItem("keyDMCMember")
  // var data10= sessionStorage.getItem("keyExistingWorkPackageDetails")
  // var data23 = sessionStorage.getItem("keyProposedWorkPackageDetails")
  // var data11= sessionStorage.getItem("keyAnticipatedTasksImpactedOwnFunction")
  // var data12= sessionStorage.getItem("keyAnticipatedTasksImpactedOtherFunction")
  // var data13= sessionStorage.getItem("keyAdditionalTimeRequiredforDetailedFeasibilityCheck")
  // var data14= sessionStorage.getItem("keyEstimatedTimeRequiredforChangeIncludingImpactedTasks")
  // var data15= sessionStorage.getItem("keyEstimatedCostForChangeIncludingImpactedTasks")
  // var data16= sessionStorage.getItem("keyAnticipatedImpactonQuality")
  // var data17= sessionStorage.getItem("keyAnticipatedImpactonSafety")
  // var data18= sessionStorage.getItem("keyAnticipatedImpactoncontractterms")
  // var data19= sessionStorage.getItem("keyAnticipatedImpactonCompliances")
  // var data20= sessionStorage.getItem("keyRemarksIfAny")
  // var data21= sessionStorage.getItem("keyFeasibilityInputGivenBy")
  // var data22= sessionStorage.getItem("keyFeasibilityInputGivenOn")
  // var data25 = sessionStorage.getItem("keyEDMCMemberacceptOn")
  // var data27 = sessionStorage.getItem("keyDMCMemberAcceptOn")
   
      const navigate = useNavigate();
      const [Title, setTitle] = useState("");
      const [WorkType, setWorkType] = useState("");
      const [ProjectName, setProjectName] = useState("");
      const [ProjectNumber, setProjectNumber] = useState("");
      const [Division, setDivision] = useState("");
      const [FeasibilityEDMCMember, setFeasibilityEDMCMember] = useState("");
      const [FeasibilityDmcMember, setFeasibilityDmcMember] = useState("");
      const [ChangeRequestNo, setChangeRequestNo] = useState("Select");
      const [ChangeRequestDate, setChangeRequestDate] = useState("");
      const [ExistingWorkPackageDetails, setExistingWorkPackageDetails] = useState("");
      const [ProposedChanges, setProposedChanges] = useState("");
      const [AnticipatedTasksImpactedOwnFunction, setAnticipatedTasksImpactedOwnFunction] = useState("");
      const [AnticipatedTasksImpactedOtherFunction, setAnticipatedTasksImpactedOtherFunction] = useState("");
      const [AdditionalTimeRequiredforDetailedFeasibilityCheck, setAdditionalTimeRequiredforDetailedFeasibilityCheck] = useState("");
      const [EstimatedTimeRequiredforChangeIncludingImpactedTasks, setEstimatedTimeRequiredforChangeIncludingImpactedTasks] = useState("");
      const [EstimatedCostForChangeIncludingImpactedTasks, setEstimatedCostForChangeIncludingImpactedTasks] = useState("");
      const [AnticipatedImpactonQuality, setAnticipatedImpactonQuality] = useState("");
      const [AnticipatedImpactonSafety, setAnticipatedImpactonSafety] = useState("");
      const [AnticipatedImpactoncontractterms, setAnticipatedImpactoncontractterms] = useState("");
      const [AnticipatedImpactonCompliances, setAnticipatedImpactonCompliances] = useState("");
      const [FeasibilityInputGivenBy, setFeasibilityInputGivenBy] = useState("");
      const [FeasibilityInputGivenOn, setFeasibilityInputGivenOn] = useState("");
      const [RemarksIfAny, setRemarksIfAny] = useState("");
      const [EDMCInputAcceptance, setEDMCInputAcceptance] = useState("Select");
      const [EDMCInputAcceptanceBy, setEDMCInputAcceptanceBy] = useState("");
      const [EDMCInputAcceptanceOn, setEDMCInputAcceptanceOn] = useState("");
      const [DMCInputAcceptance, setDMCInputAcceptance] = useState("");
      const [DMCInputAcceptanceBy, setDMCInputAcceptanceBy] = useState("");
      const [DMCInputAcceptanceOn, setDMCInputAcceptanceOn] = useState("");  
      const [FeasibilityEDMCMemberEmail, setFeasibilityEDMCMemberEmail] = useState("");  
      const [FeasibilityDmcMemberEmail, setFeasibilityDmcMemberEmail] = useState("");  
      const [PDASpocEmail, setPDASpocEmail] = useState("");
      const [FinanceMemberEmail, setFinanceMemberEmail] = useState("");
      
      const [FeasibilityDetails, setFeasibilityDetails] = useState([]);   
      let currentStage = "";
      let Tomail="";
      let CCMail="";
      let EmailSubject="";
      let EmailBody="";
      let ProcessIndication=""
      
      //const [ChangeRequest, setChangeRequest] = useState([]);
       const [ChangeRequestOptions, setChangeRequestOptions] = useState([]);
       const [key, setKey] = useState('Basic'); 
       const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const todaysdate = dd + '/' + mm + '/' + yyyy;   
    let Modified=todaysdate;
    let ModifiedBy=username;

    useEffect(() => {
      fetchChangeFeasibility1(); 
    //  renderEMDC();
    }, []);

    const fetchChangeFeasibility1 = async () => {  
    const result = await getFeasibilityRaised(CFRNo);
    const result1 =await getdetails((result.map(i => i.ChangeRequestNo)).toString());
    setFeasibilityDetails(result)
    setTitle((result.map(i => i.Title)).toString());         
        setWorkType((result.map(i => i.WorkType)).toString());
        setProjectName((result.map(i => i.ProjectName)).toString());
        setProjectNumber((result.map(i => i.ProjectNumber)).toString());
        setDivision((result.map(i => i.Division)).toString());
        setChangeRequestNo((result.map(i => i.ChangeRequestNo)).toString());
        setChangeRequestDate((result.map(i => i.ChangeRequestDate)).toString());
        setEDMCInputAcceptanceBy((result.map(i => i.EDMCInputAcceptanceBy)).toString());
        setFeasibilityEDMCMember((result.map(i => i.FeasibilityEDMCMember)).toString());
        setFeasibilityDmcMember((result.map(i => i.FeasibilityDmcMember)).toString());
        setDMCInputAcceptanceBy((result.map(i => i.DMCInputAcceptanceBy)).toString());
        setExistingWorkPackageDetails((result.map(i => i.ExistingWorkPackageDetails)).toString());
        setAnticipatedTasksImpactedOwnFunction((result.map(i => i.AnticipatedTasksImpactedOwnFunction)).toString());
        setAnticipatedTasksImpactedOtherFunction((result.map(i => i.AnticipatedTasksImpactedOtherFunction)).toString());
        setAdditionalTimeRequiredforDetailedFeasibilityCheck((result.map(i => i.AdditionalTimeRequiredforDetailedFeasibilityCheck)).toString());
        setEstimatedTimeRequiredforChangeIncludingImpactedTasks((result.map(i => i.EstimatedTimeRequiredforChangeIncludingImpactedTasks)).toString());
        setEstimatedCostForChangeIncludingImpactedTasks((result.map(i => i.EstimatedCostForChangeIncludingImpactedTasks)).toString());
        setAnticipatedImpactonQuality((result.map(i => i.AnticipatedImpactonQuality)).toString());
        setAnticipatedImpactonSafety((result.map(i => i.AnticipatedImpactonSafety)).toString());
        setAnticipatedImpactoncontractterms((result.map(i => i.AnticipatedImpactoncontractterms)).toString());
        setAnticipatedImpactonCompliances((result.map(i => i.AnticipatedImpactonCompliances)).toString());
        setRemarksIfAny((result.map(i => i.RemarksIfAny)).toString());
        setFeasibilityInputGivenBy((result.map(i => i.FeasibilityInputGivenBy)).toString());
        setFeasibilityInputGivenOn((result.map(i => i.FeasibilityInputGivenOn)).toString());
        setProposedChanges((result.map(i => i.ProposedChanges)).toString());
        setEDMCInputAcceptanceOn((result.map(i => i.EDMCInputAcceptanceOn)).toString());
        setDMCInputAcceptanceOn((result.map(i => i.DMCInputAcceptanceOn)).toString());
        setEDMCInputAcceptance((result.map(i => i.EDMCInputAcceptance)).toString());
        setDMCInputAcceptance((result.map(i => i.DMCInputAcceptance)).toString());
        setFeasibilityEDMCMemberEmail((result.map(i => i.FeasibilityEDMCMemberEmail)).toString());
        setFeasibilityDmcMemberEmail((result.map(i => i.FeasibilityDmcMemberEmail)).toString());
        setFinanceMemberEmail((result1.map(i => i.FinanceMemberEmail)).toString());
        setPDASpocEmail((result1.map(i => i.PDASpocEmail)).toString());
        
        
    }

    // const getUsers1 = async () => {
    //     setTitle(data1)
    //     setWorkType(data2)
    //     setProjectName(data3)
    //     setProjectNumber(data4)
    //     setDivision(data5)
    //     setChangeRequestNo(data6)
    //     setChangeRequestDate(data7)
    //     setEDMCInputAcceptanceBy(data8)
    //     setFeasibilityDmcMember(data9)
    //     setDMCInputAcceptanceBy(data9)
    //     setExistingWorkPackageDetails(data10)
    //     setAnticipatedTasksImpactedOwnFunction(data11)
    //     setAnticipatedTasksImpactedOtherFunction(data12)
    //     setAdditionalTimeRequiredforDetailedFeasibilityCheck(data13)
    //     setEstimatedTimeRequiredforChangeIncludingImpactedTasks(data14)
    //     setEstimatedCostForChangeIncludingImpactedTasks(data15)
    //     setAnticipatedImpactonQuality(data16)
    //     setAnticipatedImpactonSafety(data17)
    //     setAnticipatedImpactoncontractterms(data18)
    //     setAnticipatedImpactonCompliances(data19)
    //     setRemarksIfAny(data20)
    //     setFeasibilityInputGivenBy(data21)
    //     setFeasibilityInputGivenOn(data22)
    //     setProposedChanges(data23)
    //     setEDMCInputAcceptanceOn(data25)
    //     setDMCInputAcceptanceOn(data27)
    // }
    

    const chanegEvent = (e) => {

      const arrTAb = ["Basic", "Work Package", "Impact of Change", "Time", "Cost", "Safety", "Compliance", "Submit", "EDMC", "DMC"]
      for (let i = 0; i < arrTAb.length; i++) {
          let tabid = "controlled-tab-example-tab-" + arrTAb[i];
          let tabpaneid = "controlled-tab-example-tabpane-" + arrTAb[i];
      
          if (arrTAb[i] == e) {

              document.getElementById(tabid).removeAttribute('tabIndex');
              document.getElementById(tabid).classList.add('active');
              document.getElementById(tabid).ariaSelected = true;
              document.getElementById(tabpaneid).classList.add('active');
              document.getElementById(tabpaneid).classList.add('show');
          }
          else {
            
              document.getElementById(tabid).classList.remove('active');
              document.getElementById(tabid).ariaSelected = false;
              document.getElementById(tabid).tabIndex = -1;
              document.getElementById(tabpaneid).classList.remove('active');
              document.getElementById(tabpaneid).classList.remove('show');
              
          }
      }


  }

        const handleToggleButton = (e) => {
            document.getElementById("toggle1").disabled = e.target.checked;
            document.getElementById("toggle2").disabled = e.target.checked;
        }
       
        let emdcEnable;
      
      
      
        
        const updateUser = (e) => {      
        
var alertMessage="";
switch(e) {
    case "DMCInputAcceptance":                       
    if ((DMCInputAcceptance.toUpperCase().trim() == "SELECT") || (DMCInputAcceptance.toUpperCase().trim() == "NONE") || (DMCInputAcceptance.trim() == "") ) {
     alertMessage += "Please select Yes/No Option to Approve/Reject Request \r\n";
     alert(alertMessage);
     return;         
   }
      break;
      case "EDMCInputAcceptance":  
                           
      if ((EDMCInputAcceptance.toUpperCase().trim() == "SELECT") || (EDMCInputAcceptance.toUpperCase().trim() == "NONE") || (EDMCInputAcceptance.trim() == "") ) {
       alertMessage += "Please select Yes/No Option to Approve/Reject Request \r\n";
       alert(alertMessage);
       return;         
     }
      break;
    
    default:
      return
  }
            
          if ((EDMCInputAcceptanceBy.toUpperCase().trim() == username.toUpperCase().trim()) && !(document.getElementById('EDMCInputAcceptance').disabled) ) {
            currentStage="EDMC";
            ProcessIndication="SubmittedByEDMC";
            Tomail=FeasibilityDmcMemberEmail;
            
     
          }
          if ((DMCInputAcceptanceBy.toUpperCase().trim() == username.toUpperCase().trim()) && !(document.getElementById('DMCInputAcceptance').disabled) ) {
            currentStage="DMC"
            ProcessIndication="SubmittedByDMC";
             Tomail=PDASpocEmail+";"+FinanceMemberEmail;
          
          }
          EmailSubject="Feasibility Report"        
          EmailBody = "Dear Sir / Madam,<br /><br />"
          EmailBody += "A Feasibility Report For ( " + ChangeRequestNo + ") has been initiated by  " + username + " and same is pending for your inputs and necessary action.<br />"
          EmailBody += "Kindly log into the App or Following link to approve / reject / inputs.  <br />"
          EmailBody += '<a href="https://www.changemanagementgc.godrejenterprises.com/"> Click here </a><br /><br />'

          EmailBody += "Regards,<br />"
          EmailBody += "Team Construction.<br /><br />"
          EmailBody += "This is an automated mail, do not reply. ";

              let item={CFRNo, ChangeRequestNo, EDMCInputAcceptance,EDMCInputAcceptanceOn, DMCInputAcceptance, DMCInputAcceptanceOn,ProcessIndication,Tomail,CCMail,EmailSubject,EmailBody,Modified,ModifiedBy}
              
               fetch(`${API_URL1}/ChangeRequestNo/${ChangeRequestNo}/Title/${Title}`, {
                method: 'POST',

                headers:{
                  'Accept':'application/json',
                  'Content-Type':'application/json'
                },
                body:JSON.stringify(item)
              }).then((resp) => { 
                alert(CFRNo +" Updated Successfully");
                navigate("/feasibilitydashboard"); 
                              
            })
              // .then((response) => response.json()).then((res)=>{
              //   alert(CFRNo +" Updated Successfully");
              //   navigate("/feasibilitydashboard");
              //   // window.location.reload();
              // })
              // alert(CFRNo +" Updated Successfully");
              //    navigate("/feasibilitydashboard");
            }
        
  return (
    <div style={{ display: 'block' }}>
         <div class="float-end m-1">
        <Button class="btn-primary"
        style={{ float: "right" } }
       size="sm"
       onClick={() => {
        navigate("/feasibilityraisedDetails")
       }}
     >
      Back
     </Button>
     
     </div>
      <h3 className="form-title">Form No: {Title}</h3>
      {FeasibilityDetails.map((Cidata, index) =>
      <form className="form">
         <Row>
          <Col>
          <label><h5>Project No & Name :</h5>{ProjectNumber} - {ProjectName}   </label>
          </Col>
          <Col></Col>
        </Row>
        <br></br>
      <Tabs id="controlled-tab-example" onSelect={(e) => { chanegEvent(e) }}
      //activeKey={key}
     // onSelect={(k) => setKey(k)}
      className="mb-3">
        <Tab eventKey="Basic" title="Basic">
          <Row>
          <Col>
           <div className="control"  >
            <label>Title :</label>
            <input
              type="text"
              required
              // autoFocus="autofocus"
              value={ Title}
              disabled
            // hidden
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Work Type (SI/MI-A/MI-B/DI/OI-A/OI-B/Project): </label>
            <input className="inputText"
              type="text"
              required
              // autoFocus="autofocus"
              value={WorkType}              
              disabled
            />
          </div>
          </Col>
          </Row>
          <Row>
          <Col>
          <div className="control">
            <label>Initiative / Project Name:</label>
            <input
              type="text"
              required
              value={ProjectName}              
              disabled
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Initiative / Project Number:</label>
            <input
              type="text"
              required
              value={ProjectNumber}              
              disabled
            />
          </div>
          </Col>
          </Row>
          <Row>

          <Col>
          <div className="control">
            <label>Section / Division providing Feasibility Input: </label>
            <input
              type="text"
              required
              disabled
              value={Division}
              
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>EDMC Member: </label>
           <Form.Group className="control">
                                {/* <Form.Label>Work Type</Form.Label> */}
                                <input name="EDMCMember" value={EDMCInputAcceptanceBy} disabled >
                                    
                                </input>
                            </Form.Group>
                            </div>
                            </Col>
                            </Row>
                            <Row>
                              <Col>
          <div className="control">
            <label>DMC Member: </label>
            <input
              type="text"
              required
              value={FeasibilityDmcMember}
              
              disabled
            />
          </div>
          </Col>
          <Col>
          <Form.Group className="control">
                                 <Form.Label>Change Request Number:</Form.Label>
                                <input name="Change Request Number" disabled value={ChangeRequestNo}  >
                                   
                                </input>
                            </Form.Group>
                            </Col>
                            </Row>
          <div className="control">
            <label>Change Request Date: </label>
            <input
              type="text"
              required
              value={ChangeRequestDate}
              
              disabled
            />
          </div>
          <Row><br></br></Row>
          <Row>
         

         <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
             onClick={(e) => { chanegEvent("Work Package") }} > Next </Button> </div>
     </Row>
        </Tab>
        <Tab eventKey="Work Package" title="Work Package" >
        <Row>
          <Col>
          <div className="control">
            <label>Existing Work Package Details: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={ExistingWorkPackageDetails}
              
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Proposed Work Package Details: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={ProposedChanges}
              
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Basic") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Impact of Change") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Impact of Change" title="Impact of Change">
        {/* <label class="switch" >
            <input type="checkbox" onChange={handleToggleButton} disabled/>
            <span className="slider round"></span></label> */}
              <Row>
            <Col></Col>
            <Col></Col>
             <Col> <Form.Check 
                                    type="switch"
                                    id="ImpactofChangeswitch" disabled
                                    onChange={handleToggleButton}
                                width='3em'
                                /></Col>
                               
                                </Row>
                                <br></br>  
          <Row>
            <Col>
          <div className="control">
            <label>Anticipated Tasks Impacted - Own Function: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              id="toggle1"
              value={AnticipatedTasksImpactedOwnFunction}
              
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Anticipated Tasks Impacted - Other Function: </label>
            <textarea   rows="5" disabled
             id="toggle2"
              type="text" style={{height: "200px"}}
              required
              value={AnticipatedTasksImpactedOtherFunction}
              
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Work Package") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Time") }} > Next </Button> </div>
                      </div>
                        </Row>
        </Tab>
        <Tab eventKey="Time" title="Time">
        <Row>
          <Col>
          <div className="control">
            <label>Additional Time required for detailed feasibility check (If Applicable): </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={AdditionalTimeRequiredforDetailedFeasibilityCheck}
              
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Estimated Time required for change including impacted tasks: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={EstimatedTimeRequiredforChangeIncludingImpactedTasks}
              
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Impact of Change") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Cost") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Cost" title="Cost">
        <Row>
          <Col>
          <div className="control">
            <label>Estimated Cost for change including impacted tasks: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={EstimatedCostForChangeIncludingImpactedTasks}
              
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Anticipated impact on Quality: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={AnticipatedImpactonQuality}
              
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Time") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Safety") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Safety" title="Safety">
        <Row>
          <Col>
          <div className="control">
            <label>Anticipated impact on Safety: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={AnticipatedImpactonSafety}
              
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Anticipated impact on Contract Terms: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={AnticipatedImpactoncontractterms}
              
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Cost") }} > Prev </Button> </div>

                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Compliance") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Compliance" title="Compliance">
        <Row>
          <Col>
          <div className="control">
            <label>Anticipated impact on Compliances: </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px"}}
              required
              value={AnticipatedImpactonCompliances}
              
            />
          </div>
          </Col>
          <Col>
          <div className="control">
            <label>Remarks (If Any): </label>
            <textarea   rows="5" disabled
              type="text" style={{height: "200px" }}
              required
              value={RemarksIfAny}
              
            />
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div> 
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Safety") }} > Prev </Button> </div>

                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("Submit") }} > Next </Button> </div>
                       </div>
                        </Row>
        </Tab>
        <Tab eventKey="Submit" title="Submit">
        <Row>
          <Col>
          <div className="mb-2">
            <label><h5>Feasibility input given by :
              (To be filled and signed by person providing input) : <br></br>
              <input type="textarea" placeholder="Name" margin= "10px" value={FeasibilityInputGivenBy} onChange={(e) => setFeasibilityInputGivenBy(e.target.value)} disabled/> 
               <input type="textarea" placeholder="Date" margin= "10px" value={FeasibilityInputGivenOn} onChange={(e) => setFeasibilityInputGivenOn(e.target.value)} disabled/></h5></label> <br></br>
               <strong> <font color="red">NOTE: </font></strong><font color="blue">Kindly review the form prior to submission.</font><br></br> <strong> <font color="red">Submit </font></strong> <font color="red">button will send the form and trigger the workflow. 
            Once submitted, information on this form cannot be edited. </font> <br></br>
            <input id="agree" type="checkbox" checked={true} style={{width: "40px" , height: "40px"}} disabled
              // value={FeasibilityInputGivenBy}
              // onChange={(e) => setFeasibilityInputGivenBy(e.target.value)}
            /> <label>I agree that information entered is correct to the best
            of my knowledge. In case any change found that will be taken in fresh request form.</label>
            <br></br><br></br>
            <Button 
            class="button"
           // variant="outline-info"
            text-align= "center"
            onClick= {(e) => { updateUser("Submit") }} disabled>
             Submit
            
          </Button>
          </div>
          </Col>
          </Row>
          <Row><br></br></Row>
          <Row>
          <div>
          <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Compliance") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("EDMC") }} > Next </Button> </div>
                                </div>
                        </Row>
        </Tab>
        <Tab eventKey="EDMC" title="EDMC">
        {/* <label class="switch">
          <input type="checkbox" />
          <span className="slider round"></span></label> */}
<div className="control">
          <label>EDMC Input Acceptance: </label>
          <select id="EDMCInputAcceptance" disabled={(Cidata.FeasibilityEDMCMemberEmail === useremail &&  Cidata.EDMCInputAcceptance !== "Yes" && Cidata.DMCInputAcceptance !== "Yes") ? false : true}  value={EDMCInputAcceptance} onChange={(e) => setEDMCInputAcceptance(e.target.value)} style={{width: "70px"}}>
          <option value="Select">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
          </select>
        </div>
        <div className="control">
          <label>EDMC Input Acceptance By: </label>
         <input
            type="text"
            required
            value={EDMCInputAcceptanceBy}
            // onChange={(e) => setEDMCInputAcceptanceBy(e.target.value)}
            disabled
          />
        </div>
        <div className="control">
          <label>EDMC Input Acceptance On: </label>
          <input
            type="text"
            required
            value={(Cidata.FeasibilityEDMCMemberEmail === useremail &&  Cidata.EDMCInputAcceptance !== "Yes" && Cidata.DMCInputAcceptance !== "Yes") ? todaysdate : EDMCInputAcceptanceOn}  
            // onChange={(e) => setEDMCInputAcceptanceOn(e.target.value)}
            disabled
          />
          <Button 
          class="button"
            // variant="outline-info"
            text-align= "center"
            disabled={(Cidata.FeasibilityEDMCMemberEmail === useremail &&  Cidata.EDMCInputAcceptance !== "Yes" && Cidata.DMCInputAcceptance !== "Yes") ? false : true}
            onClick= {(e) => { updateUser("EDMCInputAcceptance") }} >
             Submit
            
          </Button>
        </div>
        <Row><br></br></Row>
        <Row>
          <div>
        <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("Submit") }} > Prev </Button> </div>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("DMC") }} > Next </Button> </div>
                        </div>
                        </Row>
        </Tab>
        <Tab eventKey="DMC" title="DMC">
        <div className="control">
          <label>DMC Input Acceptance: </label>
          <select id="DMCInputAcceptance" disabled={(Cidata.FeasibilityDmcMemberEmail === useremail &&  Cidata.EDMCInputAcceptance === "Yes" && Cidata.DMCInputAcceptance !== "Yes") ? false : true}  value={DMCInputAcceptance} onChange={(e) => setDMCInputAcceptance(e.target.value)} style={{width: "70px"}}>
          <option value="Select">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
          </select>
        </div>
       
        <div className="control">
          <label>DMC Input Acceptance By: </label>
          <input
            type="text"
            required
            value={DMCInputAcceptanceBy}
            // onChange={(e) => setDMCInputAcceptanceBy(e.target.value)}
            //{(element) => setDMCInputAcceptanceBy(element.DMCName)}
            disabled
          />
        </div>
        <div className="control">
          <label>DMC Input Acceptance On: </label>
          <input
            type="text"
            required
            value={(Cidata.FeasibilityDmcMemberEmail === useremail &&  Cidata.EDMCInputAcceptance === "Yes" && Cidata.DMCInputAcceptance !== "Yes") ? todaysdate : DMCInputAcceptanceOn} 
            //onChange={(e) => setDMCInputAcceptanceOn(e.target.value)}
            disabled
          />
        </div>
        <Button class="button"  onClick= {(e) => { updateUser("DMCInputAcceptance") }}  disabled={(Cidata.FeasibilityDmcMemberEmail === useremail &&  Cidata.EDMCInputAcceptance === "Yes" && Cidata.DMCInputAcceptance !== "Yes") ? false : true}>Submit</Button>   
        <Row><br></br></Row>
        <Row>
        
        <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("EDMC") }} > Prev </Button> </div>
                                </Row>
        </Tab>
      </Tabs>
      </form>
       )}
    </div>
  );
}



export default FeasibilityUpdate;