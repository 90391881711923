/* 
Program Name : ChangeAnalysisForm.jsx
Written By: Mayur Solanki
Written on: 12 Mar 2022 
System Name: Change Management App
Purpose:  Screen to update change analysis
Type of Program: React Frontend
*/
import {
  getInitation
} from "../services/ca1-service";
import {
  getInitation1,
  getChangeAnalysisNumber,
  getInitation2
} from "../services/ca-service";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Placeholder, Row, Stack } from "react-bootstrap";
import { Dropdown } from "bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/js/dist/dropdown';
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";







function ChangeAnalysisForm() {

 
  
  var username =localStorage.getItem('UserName');
  var userEmail = localStorage.getItem('UserId');
  
  const navigate = useNavigate();

  const [valuecrn, setvaluecrn] = useState("") 

  var valuecio = sessionStorage.getItem('keysesvarcio');

  var valuewt = sessionStorage.getItem('keysesvarwt');
  var valuepn = sessionStorage.getItem('keysesvarpn');
  var valuepnum = sessionStorage.getItem('keysesvarpnum');
  var valuepo = sessionStorage.getItem('keysesvarpo');
  var valuedmo = sessionStorage.getItem('keysesvardmo');
  var valuecrd = sessionStorage.getItem('keysesvarcrd');

  var data1 = sessionStorage.getItem('key');
  var chanuber = sessionStorage.getItem('keycanum');
  var chandate = sessionStorage.getItem('keycadate');
  

  const [ImpactTime, setImpactTime] = useState("");
  const [ImpactCost, setImpactCost] = useState("");
  const [ImpactQuality, setImpactQuality] = useState("");
  const [ImpactOthers, setImpactOthers] = useState("");
  const [ImpactSafety, setImpactSafety] = useState("");
  const[ImpactContract, setImpactContract] = useState("");
  const[ImpactCompliance, setImpactCompliance] = useState("");
  const[ChangeInitiationOwner, setChangeInitiationOwner] = useState("");  

  const [ImpactTimeInd, setImpactTimeInd] = useState("No");
  const [ImpactCostInd, setImpactCostInd] = useState("No");
  const [ImpactQualityInd, setImpactQualityInd] = useState("No");
  const [ImpactOthersInd, setImpactOthersInd] = useState("No");
  const [ImpactSafetyInd, setImpactSafetyInd] = useState("No");
  const [ImpactContractInd, setImpactContractInd] = useState("No");
  const [ImpactComplianceInd, setImpactComplianceInd] = useState("No");

  

  const [employees, setEmployee] = useState(null);
  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    const result = await getInitation(data1);
    
    setEmployee(result);

    const analysisnumber = await getChangeAnalysisNumber();
    

    const result2 = await getInitation2(chanuber);
    

    var ImpactTime = result2.map(t => t.ImpactTime).toString();
        setImpactTime(ImpactTime)
    var ImpactCost = result2.map(t => t.ImpactCost).toString();
        setImpactCost(ImpactCost)
    var ImpactQuality = result2.map(t => t.ImpactQuality).toString();
        setImpactQuality(ImpactQuality)
        var ImpactOthers = result2.map(t => t.ImpactOthers).toString();
        setImpactOthers(ImpactOthers)
    var ImpactSafety = result2.map(t => t.ImpactSafety).toString();
        setImpactSafety(ImpactSafety)
    var ImpactContract = result2.map(t => t.ImpactContract).toString();
        setImpactContract(ImpactContract)
    var ImpactCompliance = result2.map(t => t.ImpactCompliance).toString();
        setImpactCompliance(ImpactCompliance)

        var ImpactTimeInd = result2.map(t => t.ImpactTimeInd).toString();
        setImpactTimeInd(result2.map(t => t.ImpactTimeInd).toString());
    var ImpactCostInd = result2.map(t => t.ImpactCostInd).toString();
        setImpactCostInd(result2.map(t => t.ImpactCostInd).toString())
    var ImpactQualityInd = result2.map(t => t.ImpactQualityInd).toString();
        setImpactQualityInd(result2.map(t => t.ImpactQualityInd).toString())
        var ImpactOthersInd = result2.map(t => t.ImpactOthersInd).toString();
        setImpactOthersInd(result2.map(t => t.ImpactOthersInd).toString())
    var ImpactSafetyInd = result2.map(t => t.ImpactSafetyInd).toString();
        setImpactSafetyInd(result2.map(t => t.ImpactSafetyInd).toString())
    var ImpactContractInd = result2.map(t => t.ImpactContractInd).toString();
        setImpactContractInd(result2.map(t => t.ImpactContractInd).toString())
    var ImpactComplianceInd = result2.map(t => t.ImpactComplianceInd).toString();
        setImpactComplianceInd(result2.map(t => t.ImpactComplianceInd).toString())

        
   

    var CorrectiveAction = result2.map(t => t.CorrectiveAction).toString();
    
    sessionStorage.setItem('keytoprintca', CorrectiveAction)

    var PreventiveAction = result2.map(t => t.PreventiveAction).toString();
    
    sessionStorage.setItem('keytoprintpa', PreventiveAction)

    var ProcessChangeImpact = result2.map(t => t.ProcessChangeImpact).toString();
    
    sessionStorage.setItem('keytoprintProcessChangeImpact', ProcessChangeImpact)

    var ProcessUpdationDate = result2.map(t => t.ProcessUpdationDate).toString();
    
    sessionStorage.setItem('keytoprintprocessupdationdate', ProcessUpdationDate)

    
    var Justification = result2.map(t => t.Justification).toString();
    
    sessionStorage.setItem('keytoprintjustification', Justification)

    var TrackingPeriod = result2.map(t => t.TrackingPeriod).toString();
    
    
    sessionStorage.setItem('keytoprinttp', TrackingPeriod)

    var TrackingEffectivenessEDMCMember = result2.map(t => t.TrackingEffectivenessEDMCMember).toString();
    
    sessionStorage.setItem('keytoprintted', TrackingEffectivenessEDMCMember)
    var TrackingDMCMember = result2.map(t => t.TrackingDMCMember).toString();
    
    sessionStorage.setItem('keytoprinttd', TrackingDMCMember)
    

    var DMCAcceptanceOn = result2.map(t => t.DMCAcceptanceOn).toString();
    
    sessionStorage.setItem('keydmcaccpetanceonfromtable', DMCAcceptanceOn);

    var QAAcceptanceOn = result2.map(t => t.QAAcceptanceOn).toString();
    
    sessionStorage.setItem('keyqaaccpetanceonfromtable', QAAcceptanceOn);

    var PDAAcceptanceOn = result2.map(t => t.PDAAcceptanceOn).toString();
    
    sessionStorage.setItem('keypdaaccpetanceonfromtable', PDAAcceptanceOn);

    var AnalysisAcceptedOn = result2.map(t => t.AnalysisAcceptedOn).toString();
    
    sessionStorage.setItem('keyanalysisaccpetedonfromtable', AnalysisAcceptedOn);

    var DMCAcceptance = result2.map(t => t.DMCAcceptance).toString();
    
    sessionStorage.setItem('keydmcaccpetancefromtable', DMCAcceptance);

    var QAAcceptance = result2.map(t => t.QAAcceptance).toString();
    
    sessionStorage.setItem('keyqaaccpetancefromtable', QAAcceptance);

    var PDAAcceptance = result2.map(t => t.PDAAcceptance).toString();
    
    sessionStorage.setItem('keypdaaccpetancefromtable', PDAAcceptance);

    var AnalysisAccepted = result2.map(t => t.AnalysisAccepted).toString();
    
    sessionStorage.setItem('keyanalysisaccpetedfromtable', AnalysisAccepted);

    var Reject = result2.map(t => t.RejectionCount).toString();
    
    sessionStorage.setItem('toReject', Reject);

    var UploadFilePrevLocation = result2.map(t => t.UploadedFileLocation).toString();
    
    sessionStorage.setItem('toUploadFilePrevLocation', UploadFilePrevLocation);
    
    var UploadFilePrevNames = result2.map(t => t.UploadedFileNames).toString();
    
    sessionStorage.setItem('toUploadFilePrevNames', UploadFilePrevNames);


    var AnalysisProvidedBy = result2.map(t => t.AnalysisProvidedBy).toString();
    
    sessionStorage.setItem('tofeedbackAnalysisProvidedBy', AnalysisProvidedBy);

    var DMCAcceptanceBy = result2.map(t => t.DMCAcceptanceBy).toString();
    setChangeInitiationOwner(result2.map(t => t.ChangeInitiationOwner).toString());
    
    var DMCAcceptanceByEmail = result2.map(t => t.DMCAcceptanceByEmail).toString();
    
    
    sessionStorage.setItem('tofeedbackDMCAcceptanceBy', DMCAcceptanceBy);
    sessionStorage.setItem('tofeedbackDMCAcceptanceByEmail', DMCAcceptanceByEmail);

    var QAAcceptanceBy = result2.map(t => t.QAAcceptanceBy).toString();
    var QAAcceptanceByEmail = result2.map(t => t.QAAcceptanceByEmail).toString();
    
    sessionStorage.setItem('tofeedbackQAAcceptanceBy', QAAcceptanceBy);
    sessionStorage.setItem('tofeedbackQAAcceptanceByEmail', QAAcceptanceByEmail);

    var PDAAcceptanceBy = result2.map(t => t.PDAAcceptanceBy).toString();
    var PDAAcceptanceByEmail = result2.map(t => t.PDAAcceptanceByEmail).toString();
    
    sessionStorage.setItem('tofeedbackPDAAcceptanceBy', PDAAcceptanceBy);
    sessionStorage.setItem('tofeedbackPDAAcceptanceByEmail', PDAAcceptanceByEmail);

    var AnalysisAcceptedBy = result2.map(t => t.AnalysisAcceptedBy).toString();
    var AnalysisAcceptedByEmail = result2.map(t => t.AnalysisAcceptedByEmail).toString();
    
    sessionStorage.setItem('tofeedbackAnalysisAcceptedBy', AnalysisAcceptedBy);
    sessionStorage.setItem('tofeedbackAnalysisAcceptedByEmail', AnalysisAcceptedByEmail);

    const result1 = await getInitation1(data1);


    
  };

  function viewButton(cell, row, rowIndex, formatExtraData) {
    var sesvarcrn = row.ChangeRequestNumber;
    sessionStorage.setItem('keysesvarcrn', sesvarcrn);
    setvaluecrn(sessionStorage.getItem('keysesvarcrn'));

    var sesvarcio = row.ChangeInitiationOwner;
    sessionStorage.setItem('keysesvarcio', sesvarcio);

    var sesvarwt = row.WorkType;
    sessionStorage.setItem('keysesvarwt', sesvarwt);

    var sesvarpn = row.ProjectName;
    sessionStorage.setItem('keysesvarpn', sesvarpn);

    var sesvarpnum = row.ProjectNumber;
    sessionStorage.setItem('keysesvarpnum', sesvarpnum);

    var sesvarpo = row.ProjectOwner;
    sessionStorage.setItem('keysesvarpo', sesvarpo);

    var sesvardmo = row.DMCMemberOwner;
    sessionStorage.setItem('keysesvardmo', sesvardmo);

    var sesvarcrd = row.ChangeRequestDate;
    sessionStorage.setItem('keysesvarcrd', sesvarcrd);

    var sesvarcio = row.ChangeInitiationOwner;
    sessionStorage.setItem('keysesvarcio', sesvarcio);

    var sesvarcio = row.ChangeInitiationOwner;
    sessionStorage.setItem('keysesvarcio', sesvarcio);
    


  };




  const columns = [
    {
      dataField: "ChangeRequestNumber",
      text: "ChangeRequestNumber",
      sort: true,

    },
    {
      dataField: "ChangeInitiationOwner",
      text: "ChangeInitiationOwner",
      sort: true,
    },
    {
      dataField: "WorkType",
      text: "Work Type",
      sort: true,
    },
    {
      dataField: "ProjectName",
      text: "ProjectName",
      sort: true,
    },
    {
      dataField: "ProjectNumber",
      text: "ProjectNumber",
      sort: true,
    },
    {
      dataField: "ProjectOwner",
      text: "ProjectOwner",
      sort: true,
    },
    {
      dataField: "DMCMemberOwner",
      text: "DMCMemberOwner",
      sort: true,
    },
    {
      dataField: "ChangeRequestDate",
      text: "ChangeRequestDate",
      sort: true,
    },
    {
      dataField: "",
      text: "",
      formatter: viewButton,
    },
  ];


   // const chkValidation  = (value) => {
    function chkValidation(e) {  
      let alertmsg="";    
      if(valuecrn.trim().toString()=="" ||valuecrn.trim().toString()== null ){
        alertmsg+="Please Select Chnage Request Number";
      }
      if(ImpactTimeInd.trim().toUpperCase()=="YES" && (ImpactTime.trim().toString()=="" || ImpactTime.trim().toString()==null )){
        alertmsg+="Please Enter Time  \r\n";
      }
      if(ImpactCostInd.trim().toUpperCase()=="YES" && (ImpactCost.trim().toString()=="" || ImpactCost.trim().toString()==null )){
        alertmsg+="Please Enter Cost  \r\n";
      }
      if(ImpactQualityInd.trim().toUpperCase()=="YES" && (ImpactQuality.trim().toString()=="" || ImpactQuality.trim().toString()==null )){
        alertmsg+="Please Enter Quality  \r\n";
      }
      if(ImpactOthersInd.trim().toUpperCase()=="YES" && (ImpactOthers.trim().toString()=="" || ImpactOthers.trim().toString()==null )){
        alertmsg+="Please Enter Others  \r\n";
      }
      if(ImpactSafetyInd.trim().toUpperCase()=="YES" && (ImpactSafety.trim().toString()=="" || ImpactSafety.trim().toString()==null )){
        alertmsg+="Please Enter Safety  \r\n";
      }
      if(ImpactContractInd.trim().toUpperCase()=="YES" && (ImpactContract.trim().toString()=="" || ImpactContract.trim().toString()==null )){
        alertmsg+="Please Enter Contract  \r\n";
      }
      if(ImpactComplianceInd.trim().toUpperCase()=="YES" && (ImpactCompliance.trim().toString()=="" || ImpactCompliance.trim().toString()==null )){
        alertmsg+="Please Enter Compliance  \r\n";
      }
      if(alertmsg.trim() !=""){
        alert(alertmsg);
        return;
      }else(
        navigate("form2")
      )
    }
  

  function toggle  (e) 
   {
    const e1 = e.target;
    
    setImpactTimeInd(e1.value);
    if(e1.value == "Yes"){
      document.getElementById('time').disabled = false;
    }
    else{
      document.getElementById("time").disabled = true;
    }
  

}

function togglecost (e) 
   {
    const e1 = e.target;
    
    setImpactCostInd(e1.value);
    if(e1.value == "Yes"){
      document.getElementById('cost').disabled = false;
    }
    else{
      document.getElementById("cost").disabled = true;
    }
  

}

function togglequality (e) 
   {
    const e1 = e.target;
    
    setImpactQualityInd(e1.value);
    if(e1.value == "Yes"){
      document.getElementById('quality').disabled = false;
    }
    else{
      document.getElementById("quality").disabled = true;
    }
  

}

function toggleothers (e) 
   {
    const e1 = e.target;
    
    setImpactOthersInd(e1.value);
    if(e1.value == "Yes"){
      document.getElementById('others').disabled = false;
    }
    else{
      document.getElementById("others").disabled = true;
    }
  

}

function togglesafety (e) 
   {
    const e1 = e.target;    
    setImpactSafetyInd(e1.value);
    if(e1.value == "Yes"){
      document.getElementById('safety').disabled = false;
    }
    else{
      document.getElementById("safety").disabled = true;
    }
  

}

function togglecontract (e) 
   {
    const e1 = e.target;
    
    setImpactContractInd(e1.value);
    if(e1.value == "Yes"){
      document.getElementById('contract').disabled = false;
    }
    else{
      document.getElementById("contract").disabled = true;
    }
  

}
 
function togglecompliance (e)
{
  const e1 = e.target;
  
  setImpactComplianceInd(e1.value);
  if(e1.value == "Yes"){
    document.getElementById('compliance').disabled = false;
  }
  else{
    document.getElementById("compliance").disabled = true;
  }
}




  sessionStorage.setItem("keyImpactTime", ImpactTime)
  sessionStorage.setItem("keyImpactCost", ImpactCost)
  sessionStorage.setItem("keyImpactQuality", ImpactQuality)
  sessionStorage.setItem("keyImpactOthers", ImpactOthers)
  sessionStorage.setItem("keyImpactSafety", ImpactSafety)
  sessionStorage.setItem("keyImpactContract", ImpactContract)
  sessionStorage.setItem("keyImpactCompliance", ImpactCompliance)

  sessionStorage.setItem("keyImpactTimeInd", ImpactTimeInd)
  sessionStorage.setItem("keyImpactCostInd", ImpactCostInd)
  sessionStorage.setItem("keyImpactQualityInd", ImpactQualityInd)
  sessionStorage.setItem("keyImpactOthersInd", ImpactOthersInd)
  sessionStorage.setItem("keyImpactSafetyInd", ImpactSafetyInd)
  sessionStorage.setItem("keyImpactContractInd", ImpactContractInd)
  sessionStorage.setItem("keyImpactComplianceInd", ImpactComplianceInd)
  
    return (
      employees && (
        <body>
          <div >


            <Row hidden>
              <BootstrapTable
                striped
                keyField="ChangeRequestNumber"
                data={employees}
                columns={columns}
              // loading={true}
              //defaultSorted={defaultSorted}
              />
            </Row>
            <Container className="LogbookForm">
              <h3>Change Analysis Form</h3>



              <hr />

              <form className="form">
                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Change Request Number : </label>
                      <input type="text" value={valuecrn} disabled ></input>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Initiator : </label>
                      <input type="text" value={valuecio} disabled></input>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Change Analysis Number : </label>
                      <input type="text" value={chanuber} disabled></input>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Work Type : </label>
                      <input type="text" value={valuewt} disabled></input>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Initiative / Project Name : </label>
                      <input type="text" value={valuepn} disabled></input>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Initiative / Project Number : </label>
                      <input type="text" value={valuepnum} disabled></input>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Initiative / Project Owner : </label>
                      <input type="text" value={valuepo} disabled></input>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">DMC Member (Owner) : </label>
                      <input type="text" value={valuedmo} disabled></input>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Change Request No & Date : </label>
                      <input type="text" value={valuecrd} disabled></input>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Change Analysis Date : </label>
                      <input type="text" value={chandate} disabled></input>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>Impact of Change</h5>
                <hr />
               
                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Time : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                  
                      
                    <select name="yesno" disabled={ChangeInitiationOwner==username ? false : true} value={ImpactTimeInd} id="yesnofortime" onChange={toggle}>
<option value="No">No</option>
<option value="Yes">Yes</option>
<option value="NA">NA</option>

</select>
                    
                  </div>
                        </div>
                        <div class="col">
                          <input type="text" id="time" disabled={ChangeInitiationOwner==username && ImpactTimeInd.trim().toUpperCase() ==="YES" ? false : true } value={ImpactTime}  onChange={(e) => setImpactTime(e.target.value)} ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Cost : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                    <div className="control">
                      
                    <select name="yesno" id="yesnoforcost" disabled={ChangeInitiationOwner==username ? false : true}  value={ImpactCostInd} onChange={togglecost}>
<option value="No">No</option>
<option value="Yes">Yes</option>
<option value="NA">NA</option>

</select>
                    </div>
                  </div>
                        </div>
                        <div class="col">
                          <input type="text" id="cost"  
                            value={ImpactCost} disabled={ChangeInitiationOwner==username && ImpactCostInd.trim().toUpperCase() ==="YES" ? false : true } onChange={(e) => setImpactCost(e.target.value)}
                            ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Quality : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                                          
                    <select name="yesno" id="yesnoforquality" disabled={ChangeInitiationOwner==username ? false : true}  value={ImpactQualityInd} onChange={togglequality}>
<option value="No">No</option>
<option value="Yes">Yes</option>
<option value="NA">NA</option>

</select>
                    
                  </div>
                        </div>
                        <div class="col">
                          <input type="text" id="quality" 
                          value={ImpactQuality} disabled={ChangeInitiationOwner==username && ImpactQualityInd.trim().toUpperCase() ==="YES" ? false : true } onChange={(e)=>setImpactQuality(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Others : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                  
                      
                    <select name="yesno" id="yesnoforothers" disabled={ChangeInitiationOwner==username ? false : true}  value={ImpactOthersInd} onChange={toggleothers}>
<option value="No">No</option>
<option value="Yes">Yes</option>
<option value="NA">NA</option>

</select>
                    
                  </div>
                        </div>
                        <div class="col">
                          <input type="text" id="others" 
                          value={ImpactOthers}  disabled={ChangeInitiationOwner==username && ImpactOthersInd.trim().toUpperCase() ==="YES" ? false : true } onChange={(e)=> setImpactOthers(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Safety : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                   
                    <select name="yesno" id="yesnoforsafety" disabled={ChangeInitiationOwner==username ? false : true}  value={ImpactSafetyInd} onChange={togglesafety}>
<option value="No">No</option>
<option value="Yes">Yes</option>
<option value="NA">NA</option>

</select>
                    
                  </div>
                        </div>
                        <div class="col">
                          <input type="text" id="safety" disabled={ChangeInitiationOwner==username && ImpactSafetyInd.trim().toUpperCase() ==="YES" ? false : true }
                          value={ImpactSafety}  onChange={(e) => setImpactSafety(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Contract : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                 
                    <select name="yesno" id="yesnoforcontract" disabled={ChangeInitiationOwner==username ? false : true}  value={ImpactContractInd} onChange={togglecontract}>
<option value="No">No</option>
<option value="Yes">Yes</option>
<option value="NA">NA</option>

</select>
                    
                  </div>
                        </div>
                        <div class="col">
                          <input type="text" id="contract" disabled={ChangeInitiationOwner==username && ImpactContractInd.trim().toUpperCase() ==="YES" ? false : true }
                          value={ImpactContract}  onChange={(e) => setImpactContract(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="row justify-content-between">
                  <div class="col-5 mb-2">
                    <div className="control">
                      <label id="crnumber">Compliance : </label>
                      <div class="row">
                        <div class="col-2">
                          <br />
                          <div class="col-1 mb-2">
                   
                      
                    <select name="yesno" id="yesnoforcompliance"disabled={ChangeInitiationOwner==username ? false : true}   value={ImpactComplianceInd} onChange={togglecompliance}>
<option value="No">No</option>
<option value="Yes">Yes</option>
<option value="NA">NA</option>

</select>
                    
                  </div>
                        </div>
                        <div class="col">
                          <input type="text" id="compliance" 
                          value={ImpactCompliance} disabled={ChangeInitiationOwner==username && ImpactComplianceInd.trim().toUpperCase() ==="YES" ? false : true }  onChange={(e) => setImpactCompliance(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <hr />
                <br />   <Button class="btn-primary"
                  size="sm"
                  style={{ float: "right" }}
                  // onClick={() => {
                  //   navigate("form2")
                  // }}
                  onClick={chkValidation}
                >
                  Next
                </Button>
              </form>
            </Container>          
          </div>
          {/* window.onload = function () {window.location.reload()} */}
        </body>





      )



    );





  
}

export default ChangeAnalysisForm;

