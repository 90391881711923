/* 
Program Name : Home.jsx
Written By: Mayur Colanki
Written on: 18 MAR 2022 
System Name: Change Management App
Purpose:  page for routing
Type of Program: React Frontend
*/

import React from "react";

import { Container, Navbar, Nav, Collapse, Stack, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom'
import { PersonCircle, Book, Pencil, ArrowDown, Person, Stack as Stk, Reply, Lock, House, Tools, Box, Xbox, Power } from 'react-bootstrap-icons';
import { ArrowLeftRight } from 'react-bootstrap-icons';

import ChangeInitiation from "../Components/ChangeInitiation";
import ProjectDetails from "../Components/ProjectDetails";
import ProjectEnrollment from "../Components/ProjEnrollment";
import ContactUs from "../Components/EmailTemp";
import SectionMasterDetails from "../Components/SectionMasterDetails";
import SectionMasterEnrollment from "../Components/SectionMasterEnrollment";
import FinanceEnrollment from "../Components/FinanceEnrollment";
import FinanceDetails from "../Components/FinanceDetails";
import ChangeInitiationDetails from "../Components/ChangeInitiationDetails";
import ChangeInitiationEnrollMent from "../Components/ChangeInitiationEnrollMent";
import ChangeInitiationDetailScreen from "../Components/ChangeInitiationDetailScreen";
import EditChangeInitiation from "../Components/EditChangeInitiation";
import ChangeInitiationDashboard from "../Components/ChangeInitiationDashboard";
import ChangeInitiationDetailsRaised from "../Components/ChangeInitiationDetailsRaised";
import ChangeInitiationDetailsApproved from "../Components/ChangeInitiationDetailsApproved";
import ChangeInitiationDetailsApproval from "../Components/ChangeInitiationDetailsApproval";
import ChangeInitiationDetailsPDAInitiated from "../Components/ChangeInitiationDetailsPdaInitiated";
import PdaInitiation from "../Components/PdaInitiation";
import FeasibilityDashboard from "../Components/FeasibilityDashboard";
import FeasibilityBrowseScreenDetails from "../Components/FeasibilityBrowseScreen";
import ChangeFeasibilityDetailsRaised from "../Components/ChangeFeasibilityDetailsRaised";
import ChangeFeasibilityDetailsEDMCApproval from "../Components/ChangeFeasibilityDetailsEDMCApproval";
import ChangeFeasibilityDetailsDMCApproval from "../Components/ChangeFeasibilityDetailsDMCApproval";
import ChangeFeasibilityDetailsPDATeam from "../Components/ChangeFeasibilityDetailsPDATeam";
//Mayurs Code
import ChangeAnalysisForm from "../Components/ChangeAnalysisForm";
import ChangeAnalysisTable from "../Components/ChangeAnalysisTable";
import Feedback from "../Components/FeedBack";
import Uploader from "../Components/Uploader";
import ChangeAnalysisNewForm from "../Components/ChangeAnalysisNewForm";
import ChangeAnalysisUploader2 from "../Components/ChangeAnalysisUploader2";
import NewSubmit from "../Components/NewSubmit";

import FeasibilityRaised from "../Components/FeasibilityRaised";
import ChangeFeasibilityDashboard1 from "../Components/ChangeFeasibilityDashboard1";
import FeasibilityRaisedDetails from "../Components/FeasibilityRaisedDetails";
import Dashboard from "./DashboardofFeasibility";
import FeasibilityUpdate from "../Components/FeasibilityUpdate";
import HomeScreen from "../Components/HomeScreen";
import ChangePassword from "../Components/ChangePassword";

//Mayurs Code







import { useState, useEffect } from "react";
import product from "../images/Godrej-logo.png";
import logo from "../images/Constructionapplogo.png";
import { useMsal } from "@azure/msal-react";

export default function Home() {
  const [open, setOpen] = useState(false);
  const [FeasibilityOpen, setFeasibilityOpen] = useState(false);
  const [MastersOpen, setMastersOpen] = useState(false);
  const [openSideNav, setOpenSideNav] = useState(true);
  const [active, setActive] = useState('pending');
  const [menuLink, setMenuLink] = useState("activeMenuLink");
  const { instance } = useMsal();

  let initiationopenstatus = false;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {


    setActive(sessionStorage.getItem("clickedEvent"));
    setFeasibilityOpen(FeasibilityOpen);
    if (sessionStorage.getItem("FeasibilityOpen") == "true") {
      setFeasibilityOpen(true);
    }
    if (sessionStorage.getItem("FeasibilityOpen") == "false") {
      setFeasibilityOpen(false);
    }
    setOpen(open);
    if (sessionStorage.getItem("InitiationOpen") == "true") {
      setOpen(true);
    }
    if (sessionStorage.getItem("InitiationOpen") == "false") {
      setOpen(false);
    }

    if (localStorage.getItem('UserId') == "nprath@godrej.com" || localStorage.getItem('UserId') == "kamran@godrej.com" || localStorage.getItem('UserId') == "kapilnkm@godrej.com") {
      document.getElementById("changepasswordtab").style.display = "block";
    }
    else {

      document.getElementById("changepasswordtab").style.display = "none";
    }




  }
  function toggle() {
    setOpenSideNav(!openSideNav);
    sessionStorage.setItem("sidenav", openSideNav)
    setMenuLink(menuLink === "activeMenuLink" ? "MenuLink" : "activeMenuLink");
  }

  function getFeasibilitySession() {
    sessionStorage.setItem('FromInitiationDetailScreen', "No");
    sessionStorage.setItem("FeasibilityOpen", !FeasibilityOpen);

  }

  function getInitiationSession() {

    sessionStorage.setItem("InitiationOpen", !open);

  }

  function onlinkclick(e) {
    if (e != "feasibilitydashboard") {
      document.getElementById("feasibilitydashboard").classList.remove('active');
    }
    sessionStorage.setItem("clickedEvent", e);

    sessionStorage.setItem("Initiationopen", open);

  }

  function handleLogout(instance) {
    localStorage.removeItem('UserName');
    localStorage.removeItem('UserId');
    localStorage.removeItem('UserId1');
    localStorage.removeItem('Role');
    localStorage.removeItem('Token');
    localStorage.removeItem('Team');
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }


  return (
    <div className="wrapper">
      <Navbar className="header" sticky="top">
        <Container>
          <Stack direction="horizontal" gap={5}>
            <Navbar.Brand><img src={logo} alt="Godrej" /></Navbar.Brand>

            {/* <div className="UserLogin vr" /> */}
            <Navbar.Text><span className="UserLogin MenuHead"> Change Management System </span></Navbar.Text>
          </Stack>
          <Navbar.Text className="justify-content-end">
            <Stack direction="horizontal" gap={2}><div className="UserLogin ms-auto">Welcome, {localStorage.getItem('UserName')}</div><PersonCircle color="white" size="40" />
            </Stack>
          </Navbar.Text>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => toggle()} />
        </Container>
      </Navbar>
      <Router>
        <Navbar expand="lg" className={openSideNav ? 'opensidenavbar sidenavbar' : 'closesidenavbar sidenavbar'}>
          <ArrowLeftRight className="arrow" color="white" size="20" onClick={() => toggle()} />
          <Container>
            <div id="basic-navbar-nav">
              <Nav fill variant="tabs" activeKey={active} onSelect={(selectedKey) => { setActive(selectedKey); onlinkclick(selectedKey) }} className="me-auto flex-column">
                {/* <Nav.Link title="Change Initiation" as={Link} eventKey="changeinitiation" to="/changeinitiation"><Stack direction="horizontal" gap={2}><div className={menuLink}>Change Initiation</div><Pencil className="mx-auto"/></Stack> </Nav.Link> */}
                <Nav.Link className="dropdown-btn" title="ChangeInitiation" as={Link} eventKey="homescreen" to="/homescreen" onClick={() => (setOpen(!open), getInitiationSession())}
                  aria-controls="example-collapse-text"
                  aria-expanded={open} >
                  <Stack direction="horizontal" gap={2}><div className={menuLink}>Change Initiation</div></Stack></Nav.Link>
                <Collapse in={open}>
                  <div id="example-collapse-text" className="menu">
                    <Nav.Link as={Link} id="Raised" eventKey="Raised" to="/Raised"><Stack direction="horizontal" gap={2}><div className={menuLink}>Raised</div></Stack></Nav.Link>
                    <Nav.Link as={Link} id="approvalrequest" eventKey="approvalrequest" to="/approvalrequest"><Stack direction="horizontal" gap={2}><div className={menuLink}>Approval Request</div></Stack></Nav.Link>
                    <Nav.Link as={Link} id="approvedrequest" eventKey="approvedrequest" to="/approvedrequest"><Stack direction="horizontal" gap={2}><div className={menuLink}>Approved Request</div></Stack></Nav.Link>
                    <Nav.Link as={Link} id="pdainitiation" eventKey="pdainitiation" to="/pdainitiation"><Stack direction="horizontal" gap={2}><div className={menuLink}>PDA Initiation</div></Stack></Nav.Link>

                    {/* <Nav.Link as={Link} eventKey="email" to="/email"><Stack direction="horizontal" gap={2}><div className={menuLink}>Email</div></Stack></Nav.Link> */}
                  </div>
                </Collapse>
                <Nav.Link className="dropdown-btn" id="feasibilitydashboard" title="changefeasibility" as={Link} eventKey="feasibilitydashboard" to="/feasibilitydashboard" onClick={() => (setFeasibilityOpen(!FeasibilityOpen), getFeasibilitySession())}
                  aria-controls="example-collapse-text"
                  aria-expanded={FeasibilityOpen} >
                  <Stack direction="horizontal" gap={2}><div className={menuLink}>Change Feasibility</div></Stack></Nav.Link>
                <Collapse in={FeasibilityOpen}>
                  <div id="example-collapse-text" className="menu">
                    <Nav.Link as={Link} id="FeasibilityRaised" eventKey="FeasibilityRaised" to="/FeasibilityRaised" ><Stack direction="horizontal" gap={2}><div className={menuLink}>Raised</div></Stack></Nav.Link>
                    <Nav.Link as={Link} id="EDMCApproval" eventKey="EDMCApproval" to="/EDMCApproval"><Stack direction="horizontal" gap={2}><div className={menuLink}>EDMC Approval </div></Stack></Nav.Link>
                    <Nav.Link as={Link} id="DMCApproval" eventKey="DMCApproval" to="/DMCApproval"><Stack direction="horizontal" gap={2}><div className={menuLink}>DMC Approval</div></Stack></Nav.Link>
                    <Nav.Link as={Link} id="PDATeam" eventKey="PDATeam" to="/PDATeam"><Stack direction="horizontal" gap={2}><div className={menuLink}>PDA Team</div></Stack></Nav.Link>
                  </div>
                </Collapse>
                {/* <Nav.Link title="Change Feasibility" as={Link} eventKey="changefeasibility" to="/changefeasibility"><Stack direction="horizontal" gap={2}><div className={menuLink}>Change Feasibility</div><Reply className="mx-auto"/></Stack> </Nav.Link> */}

                <Nav.Link title="Change Analysis" as={Link} eventKey="changeanalysis" to="/changeanalysis"><Stack direction="horizontal" gap={2}><div className={menuLink}>Change Analysis</div></Stack></Nav.Link>

                {/* <Nav.Link title="Change Analysis" as={Link} eventKey="changeanalysis" to="/changeanalysis"><Stack direction="horizontal" gap={2}><div className={menuLink}>Change Analysis</div><Lock className="mx-auto"/></Stack></Nav.Link> */}
                {/* masters are commented */}
                {/* <button className="dropdown-btn" title="Masters" onClick={() => setMastersOpen(!MastersOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={MastersOpen} >
                    <Stack direction="horizontal" gap={2}><div className={menuLink}>Masters</div><House className="mx-auto"/></Stack></button>
                <Collapse in={MastersOpen}>
                  <div id="example-collapse-text" className="menu">
                   
                    <Nav.Link as={Link} eventKey="projectmaster" to="/projectmaster"><Stack direction="horizontal" gap={2}><div className={menuLink}>Project Master</div><Xbox className="mx-auto"/></Stack></Nav.Link>
                    <Nav.Link as={Link} eventKey="sectionmaster" to="/sectionmaster"><Stack direction="horizontal" gap={2}><div className={menuLink}>Section Master</div><Stk className="mx-auto"/></Stack></Nav.Link>
                    <Nav.Link as={Link} eventKey="financemaster" to="/financemaster"><Stack direction="horizontal" gap={2}><div className={menuLink}>Finance Master</div><Person className="mx-auto"/></Stack></Nav.Link>
                    
                  </div>
                </Collapse> */}

                <Nav.Link onClick={() => handleLogout(instance)}><Stack direction="horizontal" gap={2}><div className={menuLink}>Logout</div></Stack></Nav.Link>
                <Nav.Link title="Change Password" as={Link} eventKey="changepassword" to="/changepassword"><Stack direction="horizontal" gap={2}><div id="changepasswordtab" className={menuLink}>Change Password</div></Stack></Nav.Link>


                {/* <Nav.Link as={Link} onClick={() => handleLogout(instance)} eventKey="logout" to="/logout" ><Stack direction="horizontal" gap={2}><div className={menuLink}>Logout</div><Power className="mx-auto"/></Stack></Nav.Link> */}
              </Nav>
            </div>
          </Container>
        </Navbar>
        <div className={openSideNav ? 'openmain main' : 'closemain main'}>
          <Routes>
            <Route exact path="/changeinitiation" element={<ChangeInitiation />}></Route>
            {/* <Route exact path="/changefeasibility" element={<FeasibilityBrowseScreenDetails/>}></Route>             */}
            <Route exact path="/feasibilitydashboard" element={<FeasibilityDashboard />}></Route>
            <Route exact path="/projectenrollment" element={<ProjectEnrollment />}></Route>
            <Route exact path="/sectionmasterenrollment" element={<SectionMasterEnrollment />}></Route>
            <Route exact path="/financeenrollment" element={<FinanceEnrollment />}></Route>
            <Route exact path="/projectmaster" element={<ProjectDetails />}></Route>
            <Route exact path="/sectionmaster" element={<SectionMasterDetails />}></Route>
            <Route exact path="/financemaster" element={<FinanceDetails />}></Route>
            <Route exact path="/Raised" element={<ChangeInitiationDetailsRaised />}></Route>
            <Route exact path="/approvalrequest" element={<ChangeInitiationDetailsApproval />}></Route>
            <Route exact path="/approvedrequest" element={<ChangeInitiationDetailsApproved />}></Route>
            <Route exact path="/pdainitiation" element={<ChangeInitiationDetailsPDAInitiated />}></Route>
            {/* <Route exact path="/email" element={<ContactUs/>}></Route>     */}
            <Route exact path="/createbypda" element={<PdaInitiation />}></Route>
            <Route exact path="/changeinitiationdetailscreen" element={<ChangeInitiationDetailScreen />}></Route>
            <Route exact path="/editChangeInitiation" element={<EditChangeInitiation />}></Route>
            <Route exact path="/feasibilityBrowseScreen" element={<FeasibilityBrowseScreenDetails />}></Route>
            <Route exact path="/FeasibilityRaised" element={<ChangeFeasibilityDetailsRaised />}></Route>
            {/* <Route exact path="/FeasibilityRaised" element={<ContactUs/>}></Route>   */}
            <Route exact path="/EDMCApproval" element={<ChangeFeasibilityDetailsEDMCApproval />}></Route>
            <Route exact path="/DMCApproval" element={<ChangeFeasibilityDetailsDMCApproval />}></Route>
            <Route exact path="/PDATeam" element={<ChangeFeasibilityDetailsPDATeam />}></Route>
            <Route exact path="/changeanalysis" element={<ChangeAnalysisTable />}></Route>
            <Route exact path="/feedback" element={<Feedback />}></Route>
            <Route exact path="/ChangeAnalysisForm" element={<ChangeAnalysisForm />}></Route>
            <Route exact path="/changepassword" element={<ChangePassword />}></Route>
            <Route exact path="/ChangeAnalysisNewForm" element={<ChangeAnalysisNewForm />}></Route>
            <Route exact path="/ChangeAnalysisForm/form2" element={<Uploader />}></Route>
            <Route exact path="/ChangeAnalysisnewForm2" element={<Uploader />}></Route>
            <Route exact path="/ChangeAnalysisUploader2" element={<ChangeAnalysisUploader2 />}></Route>
            <Route exact path="/NewSubmit" element={<NewSubmit />}></Route>
            <Route exact path="/ChangeFeasibilityDashboard1" element={<ChangeFeasibilityDashboard1 />}></Route>
            <Route exact path="/changefeasibility" element={<Dashboard />}></Route>
            <Route exact path="/Raised" element={<FeasibilityRaised />}></Route>
            <Route exact path="/FeasibilityUpdate" element={<FeasibilityUpdate />}></Route>
            <Route exact path="/FeasibilityUpdateEDMC" element={<FeasibilityUpdate />}></Route>
            <Route exact path="/FeasibilityUpdateDMC" element={<FeasibilityUpdate />}></Route>
            <Route exact path="/homescreen" element={<HomeScreen />}></Route>
            <Route exact path="/feasibilityraisedDetails" element={<FeasibilityRaisedDetails />}></Route>
            {/* <Route exact path="/EDMCApproal" element={<FeasibilityRaised/>}></Route>
            <Route exact path="/DMCApproval" element={<FeasibilityRaised/>}></Route> */}
            <Route exact path="/feedback/logbookId/:logbookId" element={<Feedback />}></Route>
          </Routes>
        </div>
      </Router>


    </div>
  );
};
