import React from 'react';
import { Container, Button, Navbar, Nav, Collapse,  Stack ,Row,Col} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
export default function ChangeFeasibilityDashboard1() {
  const navigate = useNavigate();
  return (
   
      <div class = "row" >
            <div class="col-6 mb-2">
            <h2>Change Feasibility Dashboard</h2>
            </div>
            <div class="col">
            <button class="btn-primary"
           size="sm"
           
           style={{ float: "right" }}
           
           
           onClick={() => {
            navigate("/changefeasibility");
           }}
         >
           Add New
         </button>
         </div>
         
    </div>
  );
};