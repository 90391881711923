import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "RequestNumber"; //REACT_APP_REQUEST_API_URL;
//const axios = require("axios");
// const {ScanCommand,PutItemCommand,GetItemCommand,DeleteItemCommand}=require('@aws-sdk/client-dynamodb');
// const {unmarshall, marshall} = require("@aws-sdk/util-dynamodb");

export async function getAllRequestNumber(emailID) {  
  
  let url = `${API_URL}/emailID/${emailID}`;
  return fetch(url).then((response) => response.json());
}
export async function getChangeRequestDetails(ChangeRequestNumber) {
  let url = `${API_URL}/ChangeRequestNumber/${ChangeRequestNumber}`; 
  return fetch(url).then((response) => response.json());
 
}


export async function getProjects(){
  return fetch(API_URL).getProjects.then((response) => response.json());
   }

export async function getChangeInitiation(ProjectNumber, ChangeRequestNumber) {
  let url = `${API_URL}/ProjectNumber/${ProjectNumber}/ChangeRequestNumber/${ChangeRequestNumber}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function getChangeInitationdata( ChangeRequestNumber,ProjectNumber) {
  let url = `${API_URL}/ChangeRequestNumber/${ChangeRequestNumber}/ProjectNumber/${ProjectNumber}`;
  console.log(url);
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}
export async function getSelectedProjectRevisionNo(ProjNum) {
  let url = `${API_URL}/ProjNum/${ProjNum}`;
  console.log("getSelectedProjectRevisionNo-Service")
  console.log(url)
  return fetch(url).then((response) => response.json());
 
}

export async function getSelectedProjectTimeandCost(ProjNum,RevisionNum) {
  let url = `${API_URL}/ProjNum/${ProjNum}/RevisionNum/${RevisionNum}`;
  console.log("getSelectedProjectRevisionNo-Service")
  console.log(url)
  return fetch(url).then((response) => response.json());
 
}


export async function deleteChangeInitiation(ProjectNumber, ChangeRequestNumber) {

  let url = `${API_URL}/ProjectNumber/${ProjectNumber}/ChangeRequestNumber/${ChangeRequestNumber}`;
  console.log("url");
  console.log(url);
  const resp = await axios
    .delete(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}


export const EnrollNewChangeInitiation = async (ChangeInitiation) => {
  console.log(JSON.stringify("Inside ChangeInitiation"));
  console.log(JSON.stringify(ChangeInitiation));

  const resp = await axios
    .post(API_URL, JSON.stringify(ChangeInitiation), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};


