import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Feasibility";
// "http://localhost:5009/Feasibility";//process.env.REACT_APP_FEASIBILITY_API_URL;
//const axios = require("axios");
 
export async function getAll() {
  console.log("Under Get all");
  return fetch(API_URL).then((response) => response.json());
}
export async function getUsersChangeInitationdata(UserId) {
  let url = `${API_URL}/UserId/${UserId}`; 
  console.log(url + "getUsersChangeInitationdata");
  return fetch(url).then((response) => response.json()); 
}

export async function getUsersChangeFeasibilitydata(UserName) {
  let url = `${API_URL}/UserName/${UserName}`;  
  return fetch(url).then((response) => response.json()); 
}

export async function getUsersChangeAnalysisdata(Name) {
  let url = `${API_URL}/Name/${Name}`; 
  return fetch(url).then((response) => response.json()); 
}
