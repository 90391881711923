/* 
Program Name : Login.jsx
Written By: Snehal Kurhade
Written on: 24 MAY 2022 
System Name: Change Management App
Purpose: Login Page
Type of Program: React Frontend
*/

import { Container, Button, Row, Col, Image ,Stack} from "react-bootstrap";
import React from "react";
import home from "../images/Godrej-logo.png"
import product from "../images/Godrej-logo.png"
// import { Google,Facebook } from 'react-bootstrap-icons';
import logo from "../images/Godrej_logo.png"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export default function Login({validUser}) {
    const { instance} = useMsal();
    function handleLogin(instance) {
             instance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        }); 
    }    
    return (<Container className="login-container">        
        <Row className="d-flex">
        <Col className="justify-content-center">
            <Container className="Login">
            {/* <Row>
                    <Col>
            <Image className="loginLogo" src={product} />          
            </Col>
            </Row> */}
               <Row><br></br></Row>   <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> 
                <br></br>
                <Row >
                <Col></Col>
                    <Col>
                        <Button variant="success" onClick={() => handleLogin(instance)}><Stack className="justify-content-center" direction="horizontal" gap={2}><Image className="SignInBtn" src={logo} /> Sign in with Godrej</Stack></Button>
                    </Col>
                    <Col></Col>
                </Row>
                <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row> <Row><br></br></Row>  <Row><br></br></Row> 
                <hr></hr>
                <Row>
                    <Col>
                    <div className="justify-content-center d-flex">Copyright @ 2022 | Godrej Infotech</div>
                    </Col>
                </Row>
                
            
            </Container>
        </Col>
        </Row>
        
    </Container>
    )
}