/* 
Program Name : FeasibilityRaised.jsx
Written By: Mayur Solanki
Written on: 26 MAR 2022 
System Name: Change Management App
Purpose:  Screen to Update Change Analysis
Type of Program: React Frontend
*/
import React, { Component, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "../App_Arun.css";
import { useNavigate } from "react-router-dom";
import { getInitation2 } from "../services/ca-service";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "changeanalysis" ; //process.env.REACT_APP_ANALYSIS_API_URL;
const API_CAHISTORY_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "changeanalysishistory";
var AnalysisProvidedBy = sessionStorage.getItem('tofeedbackAnalysisProvidedBy');
        

        var DMCAcceptanceBy = sessionStorage.getItem('tofeedbackDMCAcceptanceBy');
        var DMCAcceptanceByEmail = sessionStorage.getItem('tofeedbackDMCAcceptanceByEmail');
        

        var QAAcceptanceBy = sessionStorage.getItem('tofeedbackQAAcceptanceBy');
        var QAAcceptanceByEmail = sessionStorage.getItem('tofeedbackQAAcceptanceByEmail');
        

        var PDAAcceptanceBy = sessionStorage.getItem('tofeedbackPDAAcceptanceBy');
        var PDAAcceptanceByEmail = sessionStorage.getItem('tofeedbackPDAAcceptanceByEmail');
        

        var AnalysisAcceptedBy = sessionStorage.getItem('tofeedbackAnalysisAcceptedBy');
        var AnalysisAcceptedByEmail = sessionStorage.getItem('tofeedbackAnalysisAcceptedByEmail');
        

        var Prevrejected=  sessionStorage.getItem('keysPrevrejected');
        const element = document.getElementById('DMCAcceptance');

       var ChangeInitiationNumber = sessionStorage.getItem('keysesvarcrn');
        
        var ChangeInitiationOwner= sessionStorage.getItem('keysesvarcio');
        
        var WorkType = sessionStorage.getItem('keysesvarwt');
        
        var ProjectName= sessionStorage.getItem('keysesvarpn');
        
        var ProjectNumber = sessionStorage.getItem('keysesvarpnum');
        
        var ProjectOwner = sessionStorage.getItem('keysesvarpo');
        
        var DMCMemberOwner = sessionStorage.getItem('keysesvardmo');
        
        var crd = sessionStorage.getItem('keysesvarcrd');
        
        var ChangeAnalysisNumber = sessionStorage.getItem('keycanum');
        
        var ChangeAnalysisDate = sessionStorage.getItem('keycadate');
        
        var UploadedFileLocation = sessionStorage.getItem('keys3Location');
        
        var UploadedFileNames = sessionStorage.getItem('keysFileNames');
        
        var CorrectiveAction = sessionStorage.getItem('keycorrectiveaction');
        
        var ProcessChangeImpact = sessionStorage.getItem('keyProcessChangeImpact');
        
        var PreventiveAction = sessionStorage.getItem('keypreventiveaction');
        
        var ProcessUpdationDate = sessionStorage.getItem('keyProcessUpdationDate');
        
        var Justification = sessionStorage.getItem('keyJustification');
        
        var TrackingPeriod = sessionStorage.getItem('keyTrackingPeriod')
        
        var TrackingEffectivenessEDMCMember = sessionStorage.getItem('keyTrackingEffectivenessEDMCMember')
        
        var TrackingDMCMember = sessionStorage.getItem('keyTrackingDMCMember')
        
        var ImpactTime = sessionStorage.getItem("keyImpactTime")
        var ImpactCost=  sessionStorage.getItem("keyImpactCost")       
        var ImpactQuality=  sessionStorage.getItem("keyImpactQuality")      
        var ImpactOthers=  sessionStorage.getItem("keyImpactOthers")       
        var ImpactSafety=  sessionStorage.getItem("keyImpactSafety")        

        var ImpactContract=  sessionStorage.getItem("keyImpactContract")
        

        var ImpactCompliance=  sessionStorage.getItem("keyImpactCompliance")
        

        var ImpactTimeInd = sessionStorage.getItem("keyImpactTimeInd")
        var ImpactCostInd=  sessionStorage.getItem("keyImpactCostInd");
        var ImpactQualityInd=  sessionStorage.getItem("keyImpactQualityInd")
        var ImpactOthersInd=  sessionStorage.getItem("keyImpactOthersInd")
        var ImpactSafetyInd=  sessionStorage.getItem("keyImpactSafetyInd")
        var ImpactContractInd=  sessionStorage.getItem("keyImpactContractInd")
        var ImpactComplianceInd=  sessionStorage.getItem("keyImpactComplianceInd")

        const today = new Date();

        const yyyy = today.getFullYear();

        let mm = today.getMonth() + 1; // Months start at 0!

        let dd = today.getDate();



        if (dd < 10) dd = '0' + dd;

        if (mm < 10) mm = '0' + mm;



        const todaysdate = dd + '/' + mm + '/' + yyyy;

        // Only IF USERname matches the person who has logged in the system
        let username =localStorage.getItem('UserName');
    let useremail = localStorage.getItem('UserId');
    let Modified=todaysdate;
    let ModifiedBy=username;

        var DMCAccpetancetoggle = sessionStorage.getItem('keydmcaccpetancefromtable');
        

        var QAAccpetancetoggle = sessionStorage.getItem('keyqaaccpetancefromtable');
        



        var PDAAccpetancetoggle = sessionStorage.getItem('keypdaaccpetancefromtable');
        


        var AnalysisAccpetancetoggle = sessionStorage.getItem('keyanalysisaccpetedfromtable');
        
        var flag = 0
        var flag2 ;
        var flag3;
        var flag4;
        var flag5;
        var RejectionStage="";
        var IsRejected="";
        var IsDMCDisabled=false;
        var IsQADisabled=false;
        var IsPDADisabled=false;
        var IsBHDisabled=false;


export default class FeedBack extends Component {

  

    constructor(props) {

            if(!window.location.hash) {
                window.location = window.location + '#loaded';
                window.location.reload();

                if(sessionStorage.getItem("FromFeedback")=="FromFeedback"){
                    sessionStorage.setItem("FromFeedback", "na");
                    window.location = "/changeanalysis";
                }
                
            }

            



        var DMCAccpetancetoggle = sessionStorage.getItem('keydmcaccpetancefromtable');
        

        var QAAccpetancetoggle = sessionStorage.getItem('keyqaaccpetancefromtable');
        



        var PDAAccpetancetoggle = sessionStorage.getItem('keypdaaccpetancefromtable');
        


        var AnalysisAccpetancetoggle = sessionStorage.getItem('keyanalysisaccpetedfromtable');
        



        var AnalysisProvidedBy = sessionStorage.getItem('tofeedbackAnalysisProvidedBy');
        

        var DMCAcceptanceBy = sessionStorage.getItem('tofeedbackDMCAcceptanceBy');
        

        var QAAcceptanceBy = sessionStorage.getItem('tofeedbackQAAcceptanceBy');
        

        var PDAAcceptanceBy = sessionStorage.getItem('tofeedbackPDAAcceptanceBy');
        

        var AnalysisAcceptedBy = sessionStorage.getItem('tofeedbackAnalysisAcceptedBy');
        

        var DMCAcceptanceByEmail = sessionStorage.getItem('tofeedbackDMCAcceptanceByEmail');
        var QAAcceptanceByEmail = sessionStorage.getItem('tofeedbackQAAcceptanceByEmail');
        var PDAAcceptanceByEmail = sessionStorage.getItem('tofeedbackPDAAcceptanceByEmail');
        var AnalysisAcceptedByEmail = sessionStorage.getItem('tofeedbackAnalysisAcceptedByEmail');

       var ChangeInitiationNumber = sessionStorage.getItem('keysesvarcrn');
        
        var ChangeInitiationOwner= sessionStorage.getItem('keysesvarcio');
        
        var WorkType = sessionStorage.getItem('keysesvarwt');
        
        var ProjectName= sessionStorage.getItem('keysesvarpn');
        
        var ProjectNumber = sessionStorage.getItem('keysesvarpnum');
        
        var ProjectOwner = sessionStorage.getItem('keysesvarpo');
        
        var DMCMemberOwner = sessionStorage.getItem('keysesvardmo');
        
        var crd = sessionStorage.getItem('keysesvarcrd');
        
        var ChangeAnalysisNumber = sessionStorage.getItem('keycanum');
        
        var ChangeAnalysisDate = sessionStorage.getItem('keycadate');
        
        var UploadedFileLocation = sessionStorage.getItem('keys3Location');
        
        var UploadedFileNames = sessionStorage.getItem('keysFileNames');
        
        var CorrectiveAction = sessionStorage.getItem('keycorrectiveaction');
        
        var ProcessChangeImpact = sessionStorage.getItem('keyProcessChangeImpact');
        var PreventiveAction = sessionStorage.getItem('keypreventiveaction');
        
        var ProcessUpdationDate = sessionStorage.getItem('keyProcessUpdationDate');
        
        var Justification = sessionStorage.getItem('keyJustification');
        
        var TrackingPeriod = sessionStorage.getItem('keyTrackingPeriod')
        
        var TrackingEffectivenessEDMCMember = sessionStorage.getItem('keyTrackingEffectivenessEDMCMember')
        
        var TrackingDMCMember = sessionStorage.getItem('keyTrackingDMCMember')
        



        var ImpactTime = sessionStorage.getItem("keyImpactTime")
        var ImpactCost=  sessionStorage.getItem("keyImpactCost")
        

        var ImpactQuality=  sessionStorage.getItem("keyImpactQuality")
        

        var ImpactOthers=  sessionStorage.getItem("keyImpactOthers")
        

        var ImpactSafety=  sessionStorage.getItem("keyImpactSafety")
        

        var ImpactContract=  sessionStorage.getItem("keyImpactContract")
        

        var ImpactCompliance=  sessionStorage.getItem("keyImpactCompliance")
        

        var ImpactTimeInd = sessionStorage.getItem("keyImpactTimeInd")
        var ImpactCostInd=  sessionStorage.getItem("keyImpactCostInd");
        var ImpactQualityInd=  sessionStorage.getItem("keyImpactQualityInd")
        var ImpactOthersInd=  sessionStorage.getItem("keyImpactOthersInd")
        var ImpactSafetyInd=  sessionStorage.getItem("keyImpactSafetyInd")
        var ImpactContractInd=  sessionStorage.getItem("keyImpactContractInd")
        var ImpactComplianceInd=  sessionStorage.getItem("keyImpactComplianceInd")

        var DMCAcceptanceOnfromTable = sessionStorage.getItem("keydmcaccpetanceonfromtable")
        

        var QAAcceptanceOnfromTable = sessionStorage.getItem("keyqaaccpetanceonfromtable")
        


        var PDAAcceptanceOnfromTable = sessionStorage.getItem("keypdaaccpetanceonfromtable")
        


        var AnalysisAcceptedOnfromTable = sessionStorage.getItem("keyanalysisaccpetedonfromtable")
        

var Prevrejected=  sessionStorage.getItem('keysPrevrejected');








        var AnalysisProvidedOn= sessionStorage.getItem('keycadate');
        

        var DMCAcceptanceOn = todaysdate;
        

        var QAAcceptanceOn = todaysdate;
       
        var PDAAcceptanceOn = todaysdate;
        

        var AnalysisAcceptedOn = todaysdate;
        


       
        const fetchEmployees = async () => {
            
           
            const result = await getInitation2(ChangeAnalysisNumber);
           
            if(result[0].DMCAcceptance == "Yes"){
                document.getElementById("DMCAcceptance").checked = true;
                document.getElementById("DMCAcceptance").disabled = true;
                IsDMCDisabled=true;

            }else if(result[0].DMCAcceptance == ""){
                flag2 = 0;
                
                document.getElementById("DMCAcceptance").checked = false
                //document.getElementById("DMCAcceptance").disabled = false
            }
            else if(result[0].DMCAcceptance == "" && result[0].DMCAcceptanceBy == username){
                //document.getElementById("DMCAcceptance").checked = false
                document.getElementById("DMCAcceptance").disabled = false;
                IsDMCDisabled=false;
            }

            else{
                flag2 = 1;
                
                this.state.feedback.DMCAcceptance1 = ""
                document.getElementById("DMCAcceptance").checked = false
                document.getElementById("DMCAcceptance").disabled = true
                document.getElementById("QAAcceptance").disabled = true
                document.getElementById("PDAAcceptance").disabled = true
                document.getElementById("AnalysisAccepted").disabled = true
                IsDMCDisabled=true;
                IsQADisabled=true;
                IsPDADisabled=true;
                IsBHDisabled=true;

            }


            if(result[0].QAAcceptance == "Yes"){
                document.getElementById("QAAcceptance").checked = true
                document.getElementById("QAAcceptance").disabled = true
                IsQADisabled=true;
            }else if(result[0].QAAcceptance == ""){
                flag3 = 0;
                
                document.getElementById("QAAcceptance").checked = false
                //document.getElementById("QAAcceptance").disabled = false
            }
            else if(result[0].QAAcceptance == "" && result[0].QAAcceptanceBy == username){
                //document.getElementById("QAAcceptance").checked = false
                document.getElementById("QAAcceptance").disabled = false
                IsQADisabled=false


            }

            else{
                flag3 = 1;
                
                this.state.feedback.QAAcceptance1 = ""
                document.getElementById("QAAcceptance").checked = false
                document.getElementById("QAAcceptance").disabled = true
                document.getElementById("DMCAcceptance").disabled = true
                document.getElementById("PDAAcceptance").disabled = true
                document.getElementById("AnalysisAccepted").disabled = true
                IsDMCDisabled=true;
                IsQADisabled=true;
                IsPDADisabled=true;
                IsBHDisabled=true;

            }

            if(result[0].PDAAcceptance == "Yes"){
                document.getElementById("PDAAcceptance").checked = true
                document.getElementById("PDAAcceptance").disabled = true
                IsPDADisabled=true;
            }else if(result[0].PDAAcceptance == ""){
                flag4 = 0;
                
                document.getElementById("PDAAcceptance").checked = false
                //document.getElementById("PDAAcceptance").disabled = false
            }
            else if(result[0].PDAAcceptance == "" && result[0].PDAAcceptanceBy == username){
                //document.getElementById("PDAAcceptance").checked = false
                document.getElementById("PDAAcceptance").disabled = false
                IsPDADisabled=false;


            }

            else{
                flag4 = 1;
                
                this.state.feedback.PDAAcceptance1 = ""
                document.getElementById("PDAAcceptance").checked = false
                document.getElementById("PDAAcceptance").disabled = true
                document.getElementById("DMCAcceptance").disabled = true
                document.getElementById("QAAcceptance").disabled = true
                document.getElementById("AnalysisAccepted").disabled = true
                IsDMCDisabled=true;
                IsQADisabled=true;
                IsPDADisabled=true;
                IsBHDisabled=true;

            }

            if(result[0].AnalysisAccepted == "Yes"){
                document.getElementById("AnalysisAccepted").checked = true
                document.getElementById("AnalysisAccepted").disabled = true
                IsBHDisabled=true;
            }else if(result[0].AnalysisAccepted == ""){
                flag5 = 0;
                
                document.getElementById("AnalysisAccepted").checked = false
                //document.getElementById("AnalysisAccepted").disabled = false
            }
            else if(result[0].AnalysisAccepted == "" && result[0].AnalysisAcceptedBy == username){
                //document.getElementById("AnalysisAccepted").checked = false
                document.getElementById("AnalysisAccepted").disabled = false
                IsBHDisabled=false
            }

            else{
                flag5 = 1;
                
                this.state.feedback.AnalysisAccepted1 = ""
                document.getElementById("AnalysisAccepted").checked = false
                document.getElementById("AnalysisAccepted").disabled = true
                document.getElementById("DMCAcceptance").disabled = true
                document.getElementById("QAAcceptance").disabled = true
                document.getElementById("PDAAcceptance").disabled = true
                IsDMCDisabled=true;
                IsQADisabled=true;
                IsPDADisabled=true;
                IsBHDisabled=true;
            }
            IsDMCDisabled=document.getElementById("DMCAcceptance").disabled;
            IsQADisabled=document.getElementById("QAAcceptance").disabled;
            IsPDADisabled=document.getElementById("PDAAcceptance").disabled;
            IsBHDisabled=document.getElementById("AnalysisAccepted").disabled;

        //    alert(IsDMCDisabled+","+IsQADisabled+"," +IsPDADisabled+","+IsBHDisabled);
        //     alert(AnalysisProvidedBy +","+username+","+this.state.feedback.DMCacceptanceby+","+this.state.feedback.QAacceptanceby+","+this.state.feedback.PDAAcceptanceBy+","+this.state.feedback.AnalysisAcceptedBy);
            if((Prevrejected.toUpperCase()=="YES" && AnalysisProvidedBy.toUpperCase()==username.toUpperCase())
            ||(IsDMCDisabled != true && this.state.feedback.DMCacceptanceby.toUpperCase() == username.toUpperCase())
            ||(IsQADisabled != true && this.state.feedback.QAacceptanceby.toUpperCase() == username.toUpperCase())
            ||(IsPDADisabled != true && this.state.feedback.PDAAcceptanceBy.toUpperCase() == username.toUpperCase())
            ||(IsBHDisabled != true && this.state.feedback.AnalysisAcceptedBy.toUpperCase() == username.toUpperCase())
            ){
                document.getElementById("btnSubmit").disabled = false;                
            }
            else{
                document.getElementById("btnSubmit").disabled = true;
            }   
            
           

            //setEmployee(result);
          };

          fetchEmployees();


        super(props);
        


        this.state = {
            feedback: {
                technique: '',
                technicianName: '',

                analysedby: AnalysisProvidedBy,
                DMCacceptanceby: DMCAcceptanceBy,
                DMCAcceptancevalue: "No",
                QAacceptanceby: QAAcceptanceBy,
                PDAAcceptanceBy: PDAAcceptanceBy,
                AnalysisAcceptedBy: AnalysisAcceptedBy,
                AnalysisProvidedOn: AnalysisProvidedOn,
                DMCAcceptanceOn: DMCAcceptanceOnfromTable,
                QAAcceptanceOn: QAAcceptanceOnfromTable,
                PDAAcceptanceOn: PDAAcceptanceOnfromTable,
                AnalysisAcceptedOn: AnalysisAcceptedOnfromTable,
                DMCAcceptance1 : DMCAccpetancetoggle,
                QAAcceptance1: QAAccpetancetoggle,
                PDAAcceptance1: PDAAccpetancetoggle,
                AnalysisAccepted1: AnalysisAccpetancetoggle



            },

            techniqueOptions: [
                { value: 'Demo', label: '' },
                { value: 'DWDI', label: 'DWDI' },
                { value: 'SWSI', label: 'SWSI' },
                { value: 'AMIG', label: 'AMIG' }
            ],
            technicianNameOptions: [
                { value: 'Demo', label: '' }
            ],
            mailCCOptions: [

                { value: 'arunkbr@godrej.com', label: 'arunkbr@godrej.com' },

            ],
            // disposition: { readOnly: true }
            switches: {
                DMCAcceptance: true,

                QAAcceptance: true,
                PDAAcceptance: true,
                AnalysisAccepted: true,
                chainPorosityInput: true,
                clusterPorosityInput: true,
                linearIndicationInput: true,
                tungstenInclusionInput: true,
                mergeInput: true,
                undercutInput: true,
                underfillInput: true,
                lackFusionInput: true,
                interpassFusionInput: true,
                lackPenetrationInput: true,
                suckBackInput: true,
                oxidationInput: true,
                concavityInput: true,
                crackInput: true,
                positiveRecallInput: true,
                processMarkInput: true,
                LightleakInput: true,
                waterMarkInput: true,
                scrathmarkInput: true,
                wrongTechniqueInput: true,
                crimpmarkInput: true,
                highDensityInput: true,
                lowDensityInput: true
            }


        }
        this.handleChange = this.handleChange.bind(this);

    }


   
    changeflag = async (e) => {  
        const e1 = e.target;
       if(e1.checked){      
        flag = 1;
       }
       else{
        flag = 0;
       }        
    } 



    handleChange= async (e) => {



        const e1 = e.target;

        








        this.setState({
            switches: {
                ...this.state.switches,
                [e1.id]: !e1.checked,

            }

        })

        
        if(e1.id == "DMCAcceptance" && username == this.state.feedback.DMCacceptanceby ){
            document.getElementById(e1.id).disabled = false;
         



        
        if(this.state.switches.DMCAcceptance == true){
            this.state.feedback.DMCAcceptanceOn = todaysdate;
            e1.value = "Yes"
            this.state.switches.QAAcceptance = false;
            this.state.switches.PDAAcceptance = false;
            this.state.switches.AnalysisAccepted = false;

            this.state.feedback.DMCAcceptance1="Yes"
            this.state.feedback.QAAcceptance1 =""
            this.state.feedback.PDAAcceptance1 = ""
            this.state.feedback.AnalysisAccepted1=""


            //mail to QA
        }
        else{
            this.state.feedback.DMCAcceptanceOn = todaysdate;
            e1.value = "No"
            this.state.feedback.DMCAcceptance1="No"
            this.state.feedback.QAAcceptance1 =""
            this.state.feedback.PDAAcceptance1 = ""
            this.state.feedback.AnalysisAccepted1=""
            //mail to initiator
        }



        }else if(e1.id == "QAAcceptance" && username == this.state.feedback.QAacceptanceby ){


            document.getElementById(e1.id).disabled = false;
            this.state.switches.DMCAcceptance = false;
            this.state.switches.PDAAcceptance = false;
            this.state.switches.AnalysisAccepted = false;
            // e1.DMCAcceptance = true;
            //document.getElementById("DMCAcceptance").disabled = true;
            // document.getElementById("PDAAcceptance").disabled = true;
            // document.getElementById("AnalysisAccepted").disabled = true;
        
        if(this.state.switches.QAAcceptance == true){
            this.state.feedback.QAAcceptanceOn = todaysdate;
            e1.value = "Yes"


            this.state.feedback.QAAcceptance1="Yes"
            this.state.feedback.DMCAcceptance1="Yes"
            this.state.feedback.PDAAcceptance1 =""
            this.state.feedback.AnalysisAccepted1=""
            //mail to QA
        }
        else{
            this.state.feedback.QAAcceptanceOn = todaysdate;
            e1.value = "No"
            this.state.feedback.QAAcceptance1="No"
            this.state.feedback.DMCAcceptance1="No"
            this.state.feedback.PDAAcceptance1 =""
            this.state.feedback.AnalysisAccepted1=""

            //mail to initiator
        }

        

        }
        else if(e1.id == "PDAAcceptance" && username == this.state.feedback.PDAAcceptanceBy){


            // e1.DMCAcceptance = true;
            // e1.QAAcceptance = true;

           // document.getElementById("DMCAcceptance").disabled = true;


            document.getElementById(e1.id).disabled = false;



        
        if(this.state.switches.PDAAcceptance == true){
            this.state.feedback.PDAAcceptanceOn = todaysdate;
            e1.value = "Yes"
            this.state.feedback.PDAAcceptance1="Yes";
            this.state.feedback.QAAcceptance1="Yes";
            this.state.feedback.DMCAcceptance1="Yes";
            this.state.feedback.AnalysisAccepted1="";
            //mail to QA
        }
        else{
            this.state.feedback.PDAAcceptanceOn = todaysdate;
            e1.value = "No"
            this.state.feedback.PDAAcceptance1="No";
            this.state.feedback.QAAcceptance1="No";
            this.state.feedback.DMCAcceptance1="No";
            this.state.feedback.AnalysisAccepted1="";
            //mail to initiator
        }

        

        }
        else if(e1.id == "AnalysisAccepted" && username == this.state.feedback.AnalysisAcceptedBy){

            // e1.DMCAcceptance = true;
            // e1.QAAcceptance = true;
            // e1.PDAAcceptance = true;
           // document.getElementById("DMCAcceptance").disabled = true;


            document.getElementById(e1.id).disabled = false;



        
        if(this.state.switches.AnalysisAccepted == true){
            this.state.feedback.AnalysisAcceptedOn = todaysdate;
            e1.value = "Yes"
            //mail to QA
            this.state.feedback.AnalysisAccepted1= "Yes";
            this.state.feedback.DMCAcceptance1="Yes";
            this.state.feedback.QAAcceptance1 = "Yes";
            this.state.feedback.PDAAcceptance1="Yes";
            // e1.DMCAcceptance = true;
            // e1.QAAcceptance= true;
            // e1.PDAAcceptance = true;
        }
        else{
            this.state.feedback.AnalysisAcceptedOn = todaysdate;
            e1.value = "No"
            this.state.feedback.DMCAcceptance1="No";
            this.state.feedback.QAAcceptance1 = "No";
            this.state.feedback.PDAAcceptance1="No";
            this.state.feedback.AnalysisAccepted1="No";

            //mail to initiator
        }



        }

        else{
            document.getElementById(e1.id).disabled = true;
        }


        
        sessionStorage.setItem("keydmcaccept", this.state.feedback.DMCAcceptance1)
        
        sessionStorage.setItem("keyqaaccept", this.state.feedback.QAAcceptance1)
        
        sessionStorage.setItem("keypdaaccept", this.state.feedback.PDAAcceptance1)
        
        sessionStorage.setItem("keyanalysisaccept", this.state.feedback.AnalysisAccepted1)

        sessionStorage.setItem("keydmcaccpetedon1", this.state.feedback.DMCAcceptanceOn)
        sessionStorage.setItem("keypdaaccptedon1", this.state.feedback.PDAAcceptanceOn)
        sessionStorage.setItem("keyqaaccpetedon1", this.state.feedback.QAAcceptanceOn)
        sessionStorage.setItem("keyanalysisacceptedon1", this.state.feedback.AnalysisAcceptedOn)





    }


    



    handleSubmit(e) {
        
         e.preventDefault();



         var AnalysisProvidedBy = sessionStorage.getItem('tofeedbackAnalysisProvidedBy');
        

        var DMCAcceptanceBy = sessionStorage.getItem('tofeedbackDMCAcceptanceBy');
        

        var QAAcceptanceBy = sessionStorage.getItem('tofeedbackQAAcceptanceBy');
        

        var PDAAcceptanceBy = sessionStorage.getItem('tofeedbackPDAAcceptanceBy');
        

        var AnalysisAcceptedBy = sessionStorage.getItem('tofeedbackAnalysisAcceptedBy');
        


        var DMCAcceptanceByEmail = sessionStorage.getItem('tofeedbackDMCAcceptanceByEmail');
        var QAAcceptanceByEmail = sessionStorage.getItem('tofeedbackQAAcceptanceByEmail');
        var PDAAcceptanceByEmail = sessionStorage.getItem('tofeedbackPDAAcceptanceByEmail');
        var AnalysisAcceptedByEmail = sessionStorage.getItem('tofeedbackAnalysisAcceptedByEmail');

       var ChangeInitiationNumber = sessionStorage.getItem('keysesvarcrn');
        
        var ChangeInitiationOwner= sessionStorage.getItem('keysesvarcio');
        
        var WorkType = sessionStorage.getItem('keysesvarwt');
        
        var ProjectName= sessionStorage.getItem('keysesvarpn');
        
        var ProjectNumber = sessionStorage.getItem('keysesvarpnum');
        
        var ProjectOwner = sessionStorage.getItem('keysesvarpo');
        
        var DMCMemberOwner = sessionStorage.getItem('keysesvardmo');
        
        var crd = sessionStorage.getItem('keysesvarcrd');
        
        var ChangeAnalysisNumber = sessionStorage.getItem('keycanum');
        
        var ChangeAnalysisDate = sessionStorage.getItem('keycadate');
        
        var UploadedNewFileLocation = sessionStorage.getItem('keys3Location');
        
        var UploadedFileNames = sessionStorage.getItem('keysFileNames');
        
        var UploadPrevFileLocation = sessionStorage.getItem('toUploadFilePrevLocation')
        
        let RejectionCount = sessionStorage.getItem('toReject')
        
        var CorrectiveAction = sessionStorage.getItem('keycorrectiveaction');
        
        var ProcessChangeImpact = sessionStorage.getItem('keyProcessChangeImpact');
        var PreventiveAction = sessionStorage.getItem('keypreventiveaction');
        
        var ProcessUpdationDate = sessionStorage.getItem('keyProcessUpdationDate');
        
        var Justification = sessionStorage.getItem('keyJustification');
        
        var TrackingPeriod = sessionStorage.getItem('keyTrackingPeriod')
        
        var TrackingEffectivenessEDMCMember = sessionStorage.getItem('keyTrackingEffectivenessEDMCMember')
        
        var TrackingDMCMember = sessionStorage.getItem('keyTrackingDMCMember')
        
        var ImpactTime = sessionStorage.getItem("keyImpactTime")
        



        var ImpactCost=  sessionStorage.getItem("keyImpactCost")
        

        var ImpactQuality=  sessionStorage.getItem("keyImpactQuality")
        

        var ImpactOthers=  sessionStorage.getItem("keyImpactOthers")
        

        var ImpactSafety=  sessionStorage.getItem("keyImpactSafety")
        

        var ImpactContract=  sessionStorage.getItem("keyImpactContract")
        

        var ImpactCompliance=  sessionStorage.getItem("keyImpactCompliance")
        

        var ImpactTimeInd = sessionStorage.getItem("keyImpactTimeInd")
        var ImpactCostInd=  sessionStorage.getItem("keyImpactCostInd");
        var ImpactQualityInd=  sessionStorage.getItem("keyImpactQualityInd")
        var ImpactOthersInd=  sessionStorage.getItem("keyImpactOthersInd")
        var ImpactSafetyInd=  sessionStorage.getItem("keyImpactSafetyInd")
        var ImpactContractInd=  sessionStorage.getItem("keyImpactContractInd")
        var ImpactComplianceInd=  sessionStorage.getItem("keyImpactComplianceInd")
            

        var DMCAcceptance = sessionStorage.getItem('keydmcaccept');
        

        var QAAcceptance = sessionStorage.getItem('keyqaaccept');
        

        var PDAAcceptance = sessionStorage.getItem('keypdaaccept');
        

        var AnalysisAccepted = sessionStorage.getItem('keyanalysisaccept');
        
var AcceptedStage="";

        if (AnalysisAccepted=="Yes"){
            AcceptedStage="BH";
        } else if (PDAAcceptance=="Yes"){
            AcceptedStage="PDA";
        } else if (QAAcceptance=="Yes"){
            AcceptedStage="QA";
        } else if (DMCAcceptance=="Yes"){
            AcceptedStage="DMC";
        } 
      

let iyear = parseInt(RejectionCount, 10);

        if(DMCAcceptance || QAAcceptance || PDAAcceptance || AnalysisAccepted == "No") {
            // iyear =  iyear + 1;
            
            RejectionCount=RejectionCount+1;
        }
        // if(DMCAcceptance || QAAcceptance || PDAAcceptance || AnalysisAccepted == "Yes"){
        //     iyear = 0;
        
        // }

        RejectionCount = iyear;


        const today = new Date();

        const yyyy = today.getFullYear();

        let mm = today.getMonth() + 1; // Months start at 0!

        let dd = today.getDate();

        let hh = today.getHours();        
        let sec = today.getSeconds();
        let min = today.getMinutes();




        if (dd < 10) dd = '0' + dd;

        if (mm < 10) mm = '0' + mm;



        const todaysdate = dd + '/' + mm + '/' + yyyy;
        const CurrentTime = dd + '/' + mm + '/' + yyyy+ ' ' + hh + ':' + min + ':' + sec;



        var AnalysisProvidedOn= sessionStorage.getItem('keycadate');
        

        var DMCAcceptanceOn = sessionStorage.getItem('keydmcaccpetedon1');
        

        var QAAcceptanceOn = sessionStorage.getItem('keyqaaccpetedon1');;
        

        var PDAAcceptanceOn = sessionStorage.getItem('keypdaaccptedon1');;
        

        var AnalysisAcceptedOn = sessionStorage.getItem('keyanalysisacceptedon1');;
        

        if(UploadedNewFileLocation !== ""){
        UploadedFileLocation =  UploadedNewFileLocation ;
        }else{
            UploadedFileLocation = UploadPrevFileLocation;
        }

      
      if(flag == 0 && DMCAcceptance == "No"){
        DMCAcceptance = ""
      }

      if(flag == 0 && flag2 == 0){
        DMCAcceptance = "No"
        RejectionStage="DMC";
        IsRejected="Yes";
        AnalysisAccepted = ""
        AnalysisAcceptedOn = ""
        DMCAcceptanceOn = ""
        PDAAcceptance = ""
        PDAAcceptanceOn = ""
        QAAcceptance = ""
        QAAcceptanceOn = ""
    }
    if(flag == 0 && flag2 == 1){
        DMCAcceptance = ""
        AnalysisAccepted = ""
        AnalysisAcceptedOn = ""
        DMCAcceptanceOn = ""
        PDAAcceptance = ""
        PDAAcceptanceOn = ""
        QAAcceptance = ""
        QAAcceptanceOn = ""
    }






      
      if(flag == 0 && QAAcceptance == "No"){
        DMCAcceptance=""
        QAAcceptance = ""
      }

      if(flag == 0 && flag3 == 0){
        DMCAcceptance = "No"
        AnalysisAccepted = ""
        AnalysisAcceptedOn = ""
        DMCAcceptanceOn = ""
        PDAAcceptance = ""
        PDAAcceptanceOn = ""
        QAAcceptance = "No"
        QAAcceptanceOn = ""
        RejectionStage="QA";
        IsRejected="Yes";
    }
    if(flag == 0 && flag3 == 1){
        DMCAcceptance = ""
        AnalysisAccepted = ""
        AnalysisAcceptedOn = ""
        DMCAcceptanceOn = ""
        PDAAcceptance = ""
        PDAAcceptanceOn = ""
        QAAcceptance = ""
        QAAcceptanceOn = ""
    }





      
      if(flag == 0 && PDAAcceptance == "No"){
        DMCAcceptance=""
        QAAcceptance=""
        PDAAcceptance = ""
      }

      if(flag == 0 && flag4 == 0){
        DMCAcceptance = "No"
        AnalysisAccepted = ""
        AnalysisAcceptedOn = ""
        DMCAcceptanceOn = ""
        PDAAcceptance = "No"
        PDAAcceptanceOn = ""
        RejectionStage="PDA";
        IsRejected="Yes";
        QAAcceptance = "No"
        QAAcceptanceOn = ""
    }
    if(flag == 0 && flag4 == 1){
        DMCAcceptance = ""
        AnalysisAccepted = ""
        AnalysisAcceptedOn = ""
        DMCAcceptanceOn = ""
        PDAAcceptance = ""
        PDAAcceptanceOn = ""
        QAAcceptance = ""
        QAAcceptanceOn = ""
    }





      
      if(flag == 0 && AnalysisAccepted == "No"){
        DMCAcceptance =""
        PDAAcceptance= ""
        QAAcceptance = ""
        AnalysisAccepted = ""
      }

      if(flag == 0 && flag5 == 0){
        DMCAcceptance = "No"
        AnalysisAccepted = "No"
        AnalysisAcceptedOn = ""
        RejectionStage="BH";
        IsRejected="Yes";
        DMCAcceptanceOn = ""
        PDAAcceptance = "No"
        PDAAcceptanceOn = ""
        QAAcceptance = "No"
        QAAcceptanceOn = ""
    }
    if(flag == 0 && flag5 == 1){
        DMCAcceptance = ""
        AnalysisAccepted = ""
        AnalysisAcceptedOn = ""
        DMCAcceptanceOn = ""
        PDAAcceptance = ""
        PDAAcceptanceOn = ""
        QAAcceptance = ""
        QAAcceptanceOn = ""
    }


var InsertedBy = username;
var InsertedOn = todaysdate;
var AcceptanceStage="";
let ans =true;


if(IsRejected.toUpperCase()=="YES"){
     ans = window.confirm("Do you want to Reject Data?");
}
 if (ans) {

ProjectNumber=ProjectNumber +'/'+CurrentTime;

    let item1 = {ChangeInitiationNumber,ChangeInitiationOwner,WorkType,ProjectName,ProjectNumber,ProjectOwner, DMCMemberOwner, ChangeAnalysisDate, ChangeAnalysisNumber, ImpactTime, ImpactCost, ImpactQuality,ImpactOthers, ImpactSafety, ImpactContract, ImpactCompliance, ImpactTimeInd, ImpactCostInd, ImpactQualityInd,ImpactOthersInd, ImpactSafetyInd, ImpactContractInd, ImpactComplianceInd, UploadedFileLocation, ProcessChangeImpact, CorrectiveAction, PreventiveAction,

        ProcessUpdationDate, Justification, TrackingPeriod, TrackingEffectivenessEDMCMember, TrackingDMCMember ,
      AnalysisProvidedBy, AnalysisProvidedOn,  DMCAcceptanceBy, DMCAcceptanceByEmail,QAAcceptanceByEmail,PDAAcceptanceByEmail,AnalysisAcceptedByEmail, DMCAcceptanceOn, DMCAcceptance,  QAAcceptanceBy, QAAcceptanceOn, QAAcceptance, PDAAcceptanceBy, PDAAcceptanceOn, PDAAcceptance,
 AnalysisAcceptedBy, AnalysisAcceptedOn, RejectionStage,Modified,ModifiedBy,
 IsRejected, RejectionCount , AnalysisAccepted, InsertedBy, InsertedOn}

    if(DMCAcceptance == "No" || QAAcceptance == "No" || PDAAcceptance == "No" || AnalysisAccepted == "No")
    {    

         RejectionCount = RejectionCount + 1;
        
        //post in hoistory
      
        fetch(API_CAHISTORY_URL, {
            method: 'POST',
            headers:{
              'Accept':'application/json',
              'Content-Type':'application/json'
            },
            body:JSON.stringify(item1)
          }).then((result) => {
            result.json().then((resp) => {
              console.warn(resp);            
   
            })
          })
    }

    
    ProjectNumber=ProjectNumber.split('/')[0];
    


        let item = {ChangeInitiationNumber,ChangeInitiationOwner,WorkType,ProjectName,ProjectNumber,ProjectOwner, DMCMemberOwner, ChangeAnalysisDate, ChangeAnalysisNumber,ImpactTimeInd, ImpactCostInd, ImpactQualityInd,ImpactOthersInd, ImpactSafetyInd, ImpactContractInd, ImpactComplianceInd, ImpactTime, ImpactCost, ImpactQuality,ImpactOthers, ImpactSafety, ImpactContract, ImpactCompliance, UploadedFileLocation, UploadedFileNames, ProcessChangeImpact, CorrectiveAction, PreventiveAction,
                            ProcessUpdationDate, Justification, TrackingPeriod, TrackingEffectivenessEDMCMember, TrackingDMCMember ,
                          AnalysisProvidedBy, AnalysisProvidedOn,  DMCAcceptanceBy, DMCAcceptanceByEmail,QAAcceptanceByEmail,PDAAcceptanceByEmail,AnalysisAcceptedByEmail, DMCAcceptanceOn, DMCAcceptance,  QAAcceptanceBy, QAAcceptanceOn, QAAcceptance, PDAAcceptanceBy, PDAAcceptanceOn, PDAAcceptance,
                     AnalysisAcceptedBy, AnalysisAcceptedOn, RejectionCount , RejectionStage, IsRejected,AcceptedStage, AnalysisAccepted}
                   
                     fetch(API_URL+`/ProjectNumber/${ProjectNumber}/ChangeAnalysisNumber/${ChangeAnalysisNumber}`, {
                        method: 'PUT',
                        headers:{
                          'Accept':'application/json',
                          'Content-Type':'application/json'
                        },
                        body:JSON.stringify(item)
                       }).then((resp) => {
sessionStorage.setItem("FromPath", "FromFeedback");
sessionStorage.setItem("tmpChangeAnalysisNumber", ChangeAnalysisNumber);
                        // setTimeout(function () {

                        //     if(IsRejected.toUpperCase()=="YES"){
                                
                        //         window.confirm("Entry Rejected Successfully");
                        //           }else{
                        //            window.confirm("Entry Updated Successfully");
                        //           }
                                  
                        //           window.location = "/changeanalysis"; 
                        // }, 3000); //replace 2000 w
                        
                       })

                       if(IsRejected.toUpperCase()=="YES"){
                                
                        window.confirm("Entry Rejected Successfully");
                          }else{
                           window.confirm("Entry Updated Successfully");
                          }
                          
                          window.location = "/changeanalysis"; 
                    
                    //.then((result) => {
                    //     result.json().then((resp) => {
                    //       console.warn(resp)
                    //       let ans1 ="";
                    //       if(IsRejected.toUpperCase()=="YES"){
                    //     ans1 = window.confirm("Entry Rejected Successfully");
                    //       }else{
                    //        ans1 = window.confirm("Entry Updated Successfully");
                    //       }
                    //       console.log(ans1)
                    //       sessionStorage.setItem("FromFeedback", "FromFeedback");
                    //       if(ans1){
                           
                    //             window.location = "/feedback";
                    //       } else{ window.location = "/feedback";} 
                           
                                      
                    //     })
                    //   })
                    }
    }
    render() {
        return (

            <Container >
                
             
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Analysis Provided By:
(To be filled by Project Head / EDMC Member)</Form.Label>
<Form.Control name="analysedby" value={this.state.feedback.analysedby} disabled  />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Date</Form.Label>

                                <Form.Control type="text" id="date" disabled value={this.state.feedback.AnalysisProvidedOn}
                                name="date"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />

                    <Form.Group className="mb-3" id="stage1">

                        <Row>
                            <Col>
                            <Form.Label className="FeedbackLabel" >Analysis Found to be acceptable
(To be filled by DMC Member of team seeking change)</Form.Label>
</Col>
<Col>
                                <Form.Check
                                    disabled = {( this.state.feedback.DMCacceptanceby == username) ? false :true }
                                    type="switch"
                                    id="DMCAcceptance"                                    
                                    onClick={this.changeflag}
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control name="DMCAcceptanceby" value={this.state.feedback.DMCacceptanceby} disabled />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                            <Form.Label>Date</Form.Label>
                            </Col>
                            <Col>
                                </Col>
                            <Col>
                                <Form.Control disabled type="text" name="okinput" value={this.state.feedback.DMCAcceptanceOn == "" ? todaysdate : this.state.feedback.DMCAcceptanceOn} id = "dmcacceptancedate" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <br/>

                    <Form.Group className="mb-3" id="stage2">

                        <Row>
                            <Col>
                            <Form.Label className="FeedbackLabel" >Analysis Found to be acceptable
To be filled by QA Lead (If Applicable)</Form.Label>
</Col>
<Col>
<Form.Check

                                   // disabled = {this.state.feedback.DMCAcceptance1 == "" || this.state.feedback.QAAcceptance1 == "Yes" || this.state.switches.DMCAcceptance == true  }
                                   disabled = {(this.state.feedback.QAacceptanceby !== username ||  DMCAccpetancetoggle =="" || QAAccpetancetoggle == "Yes" || DMCAccpetancetoggle=="No" ) ? true :false }
                                   type="switch"
                                    id="QAAcceptance"
                                    onClick={this.changeflag}
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control disabled value={this.state.feedback.QAacceptanceby}  type="text" name="QAacceptance" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                            <Form.Label>Date</Form.Label>
                            </Col>
                            <Col>
                                </Col>
                            <Col>
                                <Form.Control disabled type="text" id="qaaccpetancedateid"  name="okinput" value={this.state.feedback.QAAcceptanceOn == "" ? todaysdate : this.state.feedback.QAAcceptanceOn} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <br/>

                    <Form.Group className="mb-3" id="stage3">

                        <Row>
                            <Col>
                            <Form.Label className="FeedbackLabel" >Analysis Found to be acceptable by P&DA
                            (To be filled by P&DA Head)</Form.Label>
</Col>
<Col> <Form.Check
                                     disabled = {(this.state.feedback.PDAAcceptanceBy !== username ||  DMCAccpetancetoggle =="" || QAAccpetancetoggle =="" || PDAAccpetancetoggle == "Yes" ) ? true : false }
                                    type="switch"
                                    id="PDAAcceptance"
                                    onClick={this.changeflag}
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control disabled value={this.state.feedback.PDAAcceptanceBy}  type="text" name="PDAacceptance" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                            <Form.Label>Date</Form.Label>
                            </Col>
                            <Col>
                                </Col>
                            <Col>
                                <Form.Control disabled  type="text" name="okinput" id="pdaaccpetancedateon" value={this.state.feedback.PDAAcceptanceOn == "" ? todaysdate : this.state.feedback.PDAAcceptanceOn} />
                            </Col>
                        </Row>
                    </Form.Group>
                    <br />

                    <Form.Group className="mb-3" id="stage4">

                        <Row>
                            <Col>
                            <Form.Label className="FeedbackLabel" >Analysis Accepted
(To be filled by BH)</Form.Label>
</Col>
<Col>
<Form.Check
                                 disabled = {(this.state.feedback.AnalysisAcceptedBy !== username || DMCAccpetancetoggle =="" || PDAAccpetancetoggle=="" || AnalysisAccpetancetoggle=="Yes" || PDAAccpetancetoggle == "No" ) ? true : false }
                                    type="switch"
                                    id="AnalysisAccepted"
                                    onClick={this.changeflag}
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control disabled value={this.state.feedback.AnalysisAcceptedBy}  type="text" name="AnalysisAccepted" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                            <Form.Label>Date</Form.Label>
                            </Col>
                            <Col>
                                </Col>
                            <Col>
                                <Form.Control disabled type="text" name="okinput" id="analysisaccepteddate" value={this.state.feedback.AnalysisAcceptedOn == "" ? todaysdate : this.state.feedback.AnalysisAcceptedOn}  />
                            </Col>
                        </Row>
                    </Form.Group>
                    <br/>

                    {/* <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Pore with Tail"
                                    type="switch"
                                    id="QAacceptance"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.QAacceptance} type="text" name="QAacceptance" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Isolated Porosity"
                                    type="switch"
                                    id="PDAacceptance"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.PDAacceptance} type="text" name="PDAacceptance" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Aligned Porosity"
                                    type="switch"
                                    id="AnalysisAccepted"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.alignedPorosityInput} type="text" name="alignedPorosityInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Chain Porosity"
                                    type="switch"
                                    id="chainPorosityInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.chainPorosityInput} type="text" name="chainPorosityInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Cluster Porosity"
                                    type="switch"
                                    id="clusterPorosityInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.clusterPorosityInput} type="text" name="clusterPorosityInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Linear Indication"
                                    type="switch"
                                    id="linearIndicationInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.linearIndicationInput} type="text" name="linearIndicationInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Tungsten Inclusion"
                                    type="switch"
                                    id="tungstenInclusionInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.tungstenInclusionInput} type="text" name="tungstenInclusionInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Merge"
                                    type="switch"
                                    id="mergeInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.mergeInput} type="text" name="mergeInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Undercut"
                                    type="switch"
                                    id="undercutInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.undercutInput} type="text" name="undercutInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="underfill"
                                    type="switch"
                                    id="underfillInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.underfillInput} type="text" name="underfillInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Lack of Fusion"
                                    type="switch"
                                    id="lackFusionInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.lackFusionInput} type="text" name="lackFusionInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Interpass Fusion"
                                    type="switch"
                                    id="interpassFusionInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.interpassFusionInput} type="text" name="interpassFusionInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Lack of Penetration"
                                    type="switch"
                                    id="lackPenetrationInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.lackPenetrationInput} type="text" name="lackPenetrationInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Suck Back"
                                    type="switch"
                                    id="suckBackInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.suckBackInput} type="text" name="suckBackInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Oxidation"
                                    type="switch"
                                    id="oxidationInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.oxidationInput} type="text" name="oxidationInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Concavity"
                                    type="switch"
                                    id="concavityInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.concavityInput} type="text" name="concavityInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Crack"
                                    type="switch"
                                    id="crackInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.crackInput} type="text" name="crackInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Positive Recall"
                                    type="switch"
                                    id="positiveRecallInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.positiveRecallInput} type="text" name="positiveRecallInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="FeedbackLabel" >Process Defect</Form.Label>
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Process Mark"
                                    type="switch"
                                    id="processMarkInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.processMarkInput} type="text" name="processMarkInput" />
                            </Col>
                        </Row>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Lightleak"
                                    type="switch"
                                    id="LightleakInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.LightleakInput} type="text" name="LightleakInput" />
                            </Col>
                        </Row>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Water Mark"
                                    type="switch"
                                    id="waterMarkInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.waterMarkInput} type="text" name="waterMarkInput" />
                            </Col>
                        </Row>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Scrathmark"
                                    type="switch"
                                    id="scrathmarkInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.scrathmarkInput} type="text" name="scrathmarkInput" />
                            </Col>
                        </Row>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="WrongTechnique"
                                    type="switch"
                                    id="wrongTechniqueInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.wrongTechniqueInput} type="text" name="wrongTechniqueInput" />
                            </Col>
                        </Row>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Crimpmark"
                                    type="switch"
                                    id="crimpmarkInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.crimpmarkInput} type="text" name="crimpmarkInput" />
                            </Col>
                        </Row>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="HighDensity"
                                    type="switch"
                                    id="highDensityInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.highDensityInput} type="text" name="highDensityInput" />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col>
                                <Form.Check
                                    label="LowDensity"
                                    type="switch"
                                    id="lowDensityInput"
                                    onChange={this.handleChange}
                                /></Col>
                            <Col>
                                <Form.Control readOnly={this.state.switches.lowDensityInput} type="text" name="lowDensityInput" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Type of Film</Form.Label>
                                <Form.Control type="text" name="filmtypeInput" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>No of Films</Form.Label>
                                <Form.Control type="text" name="filmnoInput" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Form.Label className="FeedbackLabel" >RT Parameters</Form.Label>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Current</Form.Label>
                                <Form.Control type="text" name="currentInput" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Voltage</Form.Label>
                                <Form.Control type="text" name="voltageInput" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Exposure Time (min)</Form.Label>
                                <Form.Control type="number" name="exposureTimeInput" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Focal Size</Form.Label>
                                <Form.Control type="text" name="focalSizeInput" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>IQI</Form.Label>
                                <Form.Control type="text" name="iqiInput" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>SFD</Form.Label>
                                <Form.Control type="text" name="sfdInput" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Weld Category</Form.Label>
                                <Form.Control type="number" name="weldCategoryInput" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Film Size</Form.Label>
                                <Form.Control type="text" name="filmSizeInput" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Sensitivity</Form.Label>
                                <Form.Control type="text" name="sensitivityInput" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Reamrks</Form.Label>
                                <Form.Control type="text" name="remarksInput" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Technique</Form.Label>
                                <Form.Select name="technique" value={this.state.feedback.technique} >
                                    {this.state.techniqueOptions.map((item, index) => {
                                        return <option key={index} value={item.value}>{item.label}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Name of Technician</Form.Label>
                                <Form.Select name="technicianName" value={this.state.feedback.technicianName}  >
                                    {this.state.technicianNameOptions.map((item, index) => {
                                        return <option key={index} value={item.value}>{item.label}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="attachment">
                                <Form.Label>Attachment</Form.Label>
                                <Form.Control multiple type="file" name="Attachment" placeholder="Upload Attachment" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Send Mail to CC</Form.Label>
                                <Form.Control name="mail" value={this.state.feedback.mailCCOptions}  >
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row> */}
                     <Container className="FormButtons">
                        <Button variant="success" size="lg" id="btnSubmit" value="Reload Page" type="button"  onClick={ this.handleSubmit}>Submit</Button>
                    </Container>
                    <Row>
                        <div class="float-start m-1">
                <Button class="btn-primary"
                    style={{ float: "left" }}
                    size="sm"
                    onClick={() => {
                        window.location = "/ChangeAnalysisForm/form2"}}>
                    Back
                </Button>

            </div>
                       
                        </Row>
                </Form>

            </Container >

        )
    }
}