import axios from "axios";
const API_URL = process.env.REACT_APP_CHANGEINITIATION_API_URL + "SectionMaster";  //process.env.REACT_APP_SECTIONMASTER_API_URL;
//const axios = require("axios");

export async function getAll() {  
  console.log(API_URL);
  return fetch(API_URL).then((response) => response.json());
}
export async function getAllSectionMaster() {  
  console.log(API_URL);
  return fetch(API_URL).then((response) => response.json());
}
export async function getSectionEmail(sectionTeam) {
  let url = `${API_URL}/sectionTeam/${sectionTeam}`;
  return fetch(url).then((response) => response.json());
}



export async function getSectionMaster(SMTeamName, SMEmailID) {
  let url = `${API_URL}/SMTeamName/${SMTeamName}/SMEmailID/${SMEmailID}`;
  const resp = await axios
    .get(url)
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
  console.log(resp);
  return resp;
}

export async function deleteSection(SmTeam, SMEmailID) {
  //console.log("deleteSection");
  //console.log(Team, SectionMasterId);
  let url = `${API_URL}/SmTeam/${SmTeam}/SMEmailID/${SMEmailID}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.message;
    });

  //console.log(resp);
  return resp;
}

export const EnrollNewSectionMaster = async (SectionMaster) => {
  console.log(JSON.stringify(SectionMaster));

  const resp = await axios
    .post(API_URL, JSON.stringify(SectionMaster), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  console.log(resp);

  return resp;
};


